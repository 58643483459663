import React, { useEffect } from 'react'
import { Link, Navigate, useLocation, useRoutes, useNavigate } from 'react-router-dom'
import { Box, Grid, Typography, useMediaQuery, Hidden } from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useForm, FormProvider } from 'react-hook-form'
import { useIntl } from 'hooks/useIntl'
import { Logo } from 'components/Logo'
import { useSignUpMutation, SignUpUserInput } from 'hooks/useApollo'
import BarStepper from 'components/BarStepper'
import { useStoreon } from 'storeon/react'
import { useAccountStore } from 'hooks/useAccountStore'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import { materialTheme } from '@nuggetai/ui-kit.themes.base'
import { signUpRoutes } from 'routes/signUp'
import { CreateAccount } from './containers/CreateAccount'
import { SignUpLayout } from './components/SignUpLayout'

export const SignUp = () => {
	const intl = useIntl()
	const navigate = useNavigate()
	const mdDown = useMediaQuery(materialTheme.breakpoints.down('md'))
	const profileMethods = useForm<SignUpUserInput & { isTermsAccepted: boolean }>({
		mode: 'all'
	})
	const location = useLocation()
	const routes = useRoutes(signUpRoutes)
	const { dispatch, token } = useStoreon('token')
	const {
		setFirstAccess: activateFirstAccess,
		signUpStep,
		nextStep,
		resetStep
	} = useAccountStore(state => state)

	const [signUp, { loading }] = useSignUpMutation({
		onCompleted: finalData => {
			if (finalData?.signUp?.token) {
				// @ts-ignore
				dispatch('user/set', finalData.signUp)
				activateFirstAccess(true)
				nextStep()
				navigate('/auth/signup/select-plans')
			}
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							profileMethods.setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	useEffect(() => {
		return () => {
			resetStep()
		}
	}, [resetStep])

	const onSubmit = () => {
		signUp({
			variables: {
				userInput: profileMethods.getValues()
			}
		})
	}

	const stepper = (step: number) => (
		<Hidden smDown>
			<Grid item container justifyContent="center">
				<BarStepper step={step} length={2} />
			</Grid>
		</Hidden>
	)

	const createAccount = location.pathname.includes('create-account')
	const plans = location.pathname.includes('select-plans')

	if (token) {
		if (signUpStep === 0) {
			return <Navigate to="/" />
		}
	}

	return (
		<SignUpLayout>
			<Grid container spacing={4} alignItems="stretch">
				<Grid item container justifyContent="center">
					<Box marginBottom={!mdDown ? (createAccount ? 3 : plans ? 0 : 13) : 1}>
						<Logo width={100} />
					</Box>
				</Grid>
				<Grid item container>
					{createAccount ? (
						<Grid item container spacing={3}>
							{stepper(signUpStep)}
							<FormProvider {...profileMethods}>{routes}</FormProvider>
							<Grid item xs={12}>
								<LoadingButton
									fullWidth
									variant="contained"
									disabled={!profileMethods.formState.isValid}
									onClick={onSubmit}
									pending={loading}
									style={{ fontSize: pxToRem(16) }}
								>
									{intl.formatMessage({ id: 'next' })}
								</LoadingButton>
							</Grid>
						</Grid>
					) : plans ? (
						<Grid item container spacing={3}>
							{stepper(signUpStep)}
							<Grid item>{routes}</Grid>
						</Grid>
					) : (
						<CreateAccount />
					)}
				</Grid>
			</Grid>
			{!plans && (
				<Grid container justifyContent="center">
					<Typography
						variant="paragraph3"
						color="secondary"
						align="center"
						style={{
							marginTop: 30
						}}
					>
						{intl.formatMessage({ id: 'auth.haveAccount' })}{' '}
						<Link to="/auth/login">{intl.formatMessage({ id: 'auth.signIn' })}</Link>
					</Typography>
				</Grid>
			)}
		</SignUpLayout>
	)
}

export default SignUp
