import React from 'react'
import { PartialRouteObject } from 'react-router'
import { Navigate } from 'react-router-dom'
import {
	PersonalInformation,
	Security,
	Company,
	Billing
} from 'screens/AccountSettings/containers'

export const accountSettingsRoutes: (PartialRouteObject & { name?: string })[] = [
	{
		name: 'account',
		path: 'account',
		element: <PersonalInformation />
	},
	{
		name: 'security',
		path: 'security',
		element: <Security />
	},
	{
		name: 'company',
		path: 'company',
		element: <Company />
	},
	{
		name: 'billing',
		path: 'billing',
		element: <Billing />
	},
	{
		path: '*',
		element: <Navigate to="/settings/account" />
	}
]
