import React, { useState } from 'react'
import { Grid, Typography, TextField, Collapse } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import Modal, { IModalProps } from 'components/Modal'
import useToggle from 'hooks/useToggle'
import RecordTable, { RecordTableProps } from 'components/RecordTable'
import ButtonLink from 'components/ButtonLink'
import { StyledSlider } from './CalculateCostModal.styles'

export interface CalculateCostModalProps
	extends Pick<IModalProps, 'open' | 'onClose' | 'title'> {}

type Data = { range: string; amount: number; fee: number }

export const CalculateCostModal: React.FC<CalculateCostModalProps> = ({
	open,
	onClose,
	title
}) => {
	const intl = useIntl()
	const [value, setValue] = useState(0)
	const [{ open: showTable }, { handleToggle }] = useToggle()

	const data: Data[] = [
		{ range: '0 - 20', amount: 0, fee: 600 },
		{ range: '21 - 50', amount: 30, fee: 0 },
		{ range: '50+', amount: 20, fee: 0 }
	]

	const columns: RecordTableProps['columns'] = [
		{
			id: '1',
			title: intl.formatMessage({ id: 'payment.pricing.table.numEmployee' }),
			content: (data: Data) => <Typography variant="paragraph1">{data.range}</Typography>
		},
		{
			id: '2',
			title: intl.formatMessage({ id: 'payment.pricing.table.perEmployee' }),
			content: (data: Data) => (
				<Typography variant="paragraph1">${data.amount.toFixed(2)}</Typography>
			)
		},
		{
			id: '3',
			title: intl.formatMessage({ id: 'payment.pricing.table.flatFee' }),
			content: (data: Data) => (
				<Typography variant="paragraph1">${data.fee.toFixed(2)}</Typography>
			)
		}
	]

	const handleSlide = (
		_: React.SyntheticEvent<Element, Event>,
		value: number | number[]
	): void => {
		setValue(value as number)
	}

	const handleChange: React.ChangeEventHandler<HTMLInputElement> = e => {
		const numValue = Math.floor(Number(e.target.value.replace(/^[0]+/g, '')))
		if (!numValue) {
			return setValue(0)
		}
		if (numValue < 0 || numValue > 500) return

		setValue(numValue)
	}

	const calculatePrice = (quantity: number) => {
		if (!quantity) return 600

		if (quantity <= 20) {
			return 600
		}

		if (quantity > 20 && quantity <= 50) {
			return 600 + 30 * (quantity - 20)
		}

		if (quantity > 50) {
			return 1500 + 20 * (quantity - 50)
		}
	}

	return (
		<Modal open={open} onClose={onClose} title={title} footer={false} showCloseButton>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<TextField
						fullWidth
						autoFocus
						type="number"
						label={intl.formatMessage({ id: 'payment.calculate.label' })}
						InputProps={{
							sx: {
								width: '90px',
								'.MuiInputBase-input': {
									padding: '5px 10px'
								}
							},
							inputProps: {
								min: 0,
								max: 500,
								pattern: '[0-9]*'
							},
							inputMode: 'numeric'
						}}
						InputLabelProps={{
							style: { marginBottom: '15px' }
						}}
						value={String(value)}
						onChange={handleChange}
					/>
				</Grid>
				<Grid item xs={12}>
					<StyledSlider
						value={value}
						onChange={handleSlide}
						min={0}
						max={500}
						step={1}
						size={22}
						valueLabelDisplay="auto"
					/>
				</Grid>
				<Grid item xs={12}>
					<Typography>
						{intl.formatMessage({ id: 'payment.estimated.monthly' })}{' '}
						<strong>
							${calculatePrice(value)} / {intl.formatMessage({ id: 'payment.monthly' })}
						</strong>
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<Typography>
						{intl.formatMessage({ id: 'payment.calculate.cost.desc' })}{' '}
						{value <= 20
							? intl.formatMessage(
									{ id: 'payment.calculate.cost.desc.0' },
									{ count: value }
							  )
							: value > 20 && value <= 50
							? intl.formatMessage(
									{ id: 'payment.calculate.cost.desc.1' },
									{ count: value, diff: value - 20 }
							  )
							: intl.formatMessage(
									{ id: 'payment.calculate.cost.desc.2' },
									{ count: value, diff: value - 50 }
							  )}
					</Typography>
				</Grid>
				<Grid item xs={12}>
					<ButtonLink onClick={handleToggle}>
						{intl.formatMessage({
							id: showTable ? 'payment.hide.pricingTable' : 'payment.show.pricingTable'
						})}
					</ButtonLink>
				</Grid>
				<Grid item xs={12}>
					<Collapse in={showTable}>
						<div style={{ marginBottom: 20 }}>
							<RecordTable columns={columns} data={data} />
						</div>
					</Collapse>
				</Grid>
			</Grid>
		</Modal>
	)
}

export default CalculateCostModal
