import removeAccents from 'remove-accents'

export function createStringRequirements({
	minLength = 8,
	maxLength,
	includeNumber = true,
	includeLowercase = true,
	includeSpecial = true,
	includeUppercase = true
}: StringRequirementsArgs = {}): RegExp {
	const min = minLength && minLength > 0 ? minLength : 1
	const max = maxLength && maxLength > min ? maxLength : ''
	return new RegExp(
		`^${patternFormatter('?=.*?[A-Z]', includeUppercase)}${patternFormatter(
			'?=.*?[a-z]',
			includeLowercase
		)}${patternFormatter('?=.*?[0-9]', includeNumber)}${patternFormatter(
			'?=.*?[#?!@$%^&*-]',
			includeSpecial
		)}.{${min},${max}}`
	)
}

export const ipRegex = /^(([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])\.){3}([0-9]|[1-9][0-9]|1[0-9]{2}|2[0-4][0-9]|25[0-5])$/

export const emailRegex = new RegExp(
	/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
)

export const linkRegex = /(http|https):\/\/(www.)?/

// ^(?![0-9])(?!.*--)(?!.*_)[^\W][\w-]{0,29}$ - alphanumeric
// ^(?![0-9])(?!\.\w)(?!.*\.\.)(?!.*\.$)(?:.*(\s?))(?!.*\.\.)(?!.*\w.)(?!.*(\w+\.))[^\W][\w.]{0,29}$
export const teamNameRegex = new RegExp(/^(?![0-9])(?!.*\.\.)(?!.*\.$)[^\W][\w.]{0,29}$/i)

export const alphanum = /^(?!.*--)(?!.*-$)[^\W][\w-]{0,29}$/

export function formatToSlug(str: string): string {
	return removeAccents(str)
		.toLowerCase()
		.trim()
		.replace(multipleWhiteSpaceRegex, ' ')
		.replace(whiteSpaceRegex, '-')
		.replace(specialCharsRegex, '')
		.replace(multipleDashRegex, '-')
		.replace(/(^-)|(-$)/g, '')
}

export const hexColorRegex = new RegExp(/^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/im)
export const rgbColorRegex = new RegExp(
	/rgba?\((?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5]), ?)(?:([0-9]{1,2}|1[0-9]{1,2}|2[0-4][0-9]|25[0-5])),?\s*([01]\.?\d*?)?\)/
)
export const hslColorRegex = new RegExp(
	/(hsl\((?:[0-9]|[1-9][0-9]|1[0-9][0-9]|2[0-9][0-9]|3[0-5][0-9]|360),(?:\s|)(?:[0-9]|[1-9][0-9]|100)%),(?:\s|)(?:[0-9]|[1-9][0-9]|100)%\)/im
)

export function isSlugValid(str: string): boolean {
	return alphanum.test(str)
}

export const multipleWhiteSpaceRegex = /\s{2,}/g

export const multipleDashRegex = /-{2,}/g

export const specialCharsRegex = /[^a-zA-Z0-9\-_]/g

export const whiteSpaceRegex = /\s/g

function patternFormatter(pattern: string, args: IncludeRequirement): string {
	return args === false
		? ''
		: args === true
		? `(${pattern})`
		: `(${pattern}{${(() => {
				const min = args?.min && args?.min > 0 ? args.min : 1
				const max = args?.max && args?.max > min ? args.max : ''
				return `${min},${max}`
		  })()}})`
}

type IncludeRequirement =
	| {
			/**
			 * This number must be greater than 0
			 */
			min?: number
			/**
			 * If you specify the max without the min, the min default will be 1
			 */
			max?: number
	  }
	| boolean

interface StringRequirementsArgs {
	/**
	 * The minimum length of the string
	 * @default 8
	 */
	minLength?: number
	/**
	 * The maximum length of the string
	 */
	maxLength?: number
	/**
	 * To include a number from 0 to 9
	 * @default true
	 */
	includeNumber?: IncludeRequirement
	/**
	 * To include a uppercase letter from A to Z
	 * @default true
	 */
	includeUppercase?: IncludeRequirement
	/**
	 * To include a lowercase letter from a to z
	 * @default true
	 */
	includeLowercase?: IncludeRequirement
	/**
	 * To include a special character, the valid ones are: #?!@$%^&*-
	 * @default true
	 */
	includeSpecial?: IncludeRequirement
}
