import { Grid } from '@material-ui/core'
import styled from 'styled-components'
import { Swiper } from 'swiper/react'

export const TalentSectionContainer = styled(Grid)`
	border-radius: 10px;
	height: 100%;
	min-height: 1000px;
	border: ${props => `1px solid ${props.theme.border}`};
	background-color: ${props => props.theme.background.light};
`

export const StyledSwiper = styled(Swiper)`
	width: 86vw;
	.swiper-wrapper {
		height: auto !important;
	}
`
