import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTypedController } from '@hookform/strictly-typed'
import useIntl from 'hooks/useIntl'

export interface SendEmailReminderForm {
	emails: string[]
	checked: boolean
}

export type IuseSendEmailReminder = ReturnType<typeof useSendEmailReminder>

export const useSendEmailReminder = (email?: string) => {
	const intl = useIntl()
	const validationSchema = yup.object({
		emails: yup
			.array()
			.transform(function (value, originalValue) {
				if (this.isType(value) && value !== null) {
					return value
				}
				return originalValue ? originalValue.split(/[\s,]+/) : []
			})
			.of(yup.string().email(({ value }) => value)),
		checked: yup.boolean()
	})

	const {
		watch,
		control,
		errors,
		handleSubmit: onSubmit,
		setValue,
		getValues
	} = useForm<SendEmailReminderForm>({
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			emails: email ? [email] : [],
			checked: false
		}
	})

	const handleChange = (_: React.SyntheticEvent<Element, Event>, value: string[]) => {
		setValue('emails', value)
	}

	const handleCheck = () => {
		setValue('checked', !getValues().checked)
	}

	const renderErrMessage = () => {
		const invalidEmails: Array<string | undefined> = []

		if (!errors) return []

		errors?.emails?.forEach(error => {
			invalidEmails.push(error?.message)
		})

		return intl.formatMessage(
			{ id: 'sendEmailReminder.error.invalid' },
			{ emails: invalidEmails.join(', ') }
		)
	}

	const Controller = useTypedController<SendEmailReminderForm>({ control })

	const models = { errors, watch }
	const operations = { onSubmit, handleChange, handleCheck, renderErrMessage }
	const components = { Controller }

	return [models, operations, components] as [
		typeof models,
		typeof operations,
		typeof components
	]
}
