import React, { createContext, useCallback, useContext, useState } from 'react'
import {
	GetAssessmentQuery,
	useGetApplicantsLazyQuery,
	useGetAssessmentLazyQuery,
	GetApplicantsQuery,
	EnumSortApplicantGetManyInput
} from 'hooks/useApollo'
import debounce from 'lodash.debounce'
import { useLocation, useNavigate } from 'react-router'
import { Option, useSearchFilter } from 'hooks/useFilter'
import useIntl from 'hooks/useIntl'
import { removeUndefinedKeys } from 'utils/object'

export interface TeamContextProps {
	debounceSearch: string
	filters: ReturnType<typeof useSearchFilter>
	page: number
	demo: boolean
	teamId: string
	handleSearch: React.ChangeEventHandler<HTMLTextAreaElement | HTMLInputElement>
	handlePageChange: (e: React.ChangeEvent<unknown> | undefined, value: number) => void
	assessment: GetAssessmentQuery['assessment'] | undefined
	applicants: GetApplicantsQuery['applicants']
	getAssessment: ReturnType<typeof useGetAssessmentLazyQuery>[0]
	getApplicants: ReturnType<typeof useGetApplicantsLazyQuery>[0]
	assessmentLoading: boolean
	applicantsLoading: boolean
	refetchAssessment(): Promise<void>
	refetchApplicants(
		type?: 'Internal' | 'External' | null
	): Promise<TeamContextProps['applicants'] | undefined>
	order: 'ASC' | 'DESC'
	sort: EnumSortApplicantGetManyInput
	handleToggleOrder: () => void
	handleChangeSort: (event: React.ChangeEvent) => void
}

// @ts-ignore
export const TeamsContext = createContext<TeamContextProps>()

export function useTeamContext<T>(selector: (store: TeamContextProps) => T) {
	const context = useContext(TeamsContext)
	if (context === undefined) {
		throw new Error('useTeamContext must be used within a TeamsProvider')
	}
	return selector(context)
}

export const TeamsProvider: React.FC<{ demo?: boolean }> = ({ children, demo }) => {
	const intl = useIntl()
	const navigate = useNavigate()
	const location = useLocation()
	const [debounceSearch, setDebounceSearch] = useState('')
	const [sort, setSort] = useState<string>('CREATED_AT')
	const [order, setOrder] = useState<'ASC' | 'DESC'>('DESC')
	const teamId = location.pathname.split('/')[2]

	const debouncedSearch = useCallback(
		debounce(nextValue => setDebounceSearch(nextValue), 300),
		[]
	)

	const [
		getAssessment,
		{ data: assessmentData, loading: assessmentLoading, refetch: refetchAssessment }
	] = useGetAssessmentLazyQuery({
		onError: () => {
			console.log('Disabled navigate back to home page, to view the Team view page')
			// navigate('/')
		}
	})
	const [
		getApplicants,
		{ data: applicantsData, loading: applicantsLoading, refetch: refetchApplicants }
	] = useGetApplicantsLazyQuery({
		onError: () => {
			console.log('Disabled navigate back to home page, to view the Team view page')
			// navigate('/')
		},
		nextFetchPolicy: 'cache-first'
	})

	const [page, setPage] = useState(applicantsData?.applicants?.pageInfo.currentPage || 1)

	const handlePageChange = (_: React.ChangeEvent<unknown> | undefined, value: number) => {
		setPage(value)
	}

	const filters = useSearchFilter(
		{
			pipelineId: {
				label: intl.formatMessage({ id: 'talentGroup.filters.talentGroup' }),
				options: (assessmentData?.assessment.pipelines.map(pipeline => ({
					label: pipeline?.name || '',
					value: pipeline?.id || ''
				})) || []) as Option[]
			},
			isBookmarked: {
				label: intl.formatMessage({ id: 'talentGroup.filters.Bookmark' }),
				options: [
					{
						label: 'Bookmark',
						value: true
					},
					{
						label: 'Unbookmark',
						value: false
					}
				]
			},
			isShortlisted: {
				label: intl.formatMessage({ id: 'talentGroup.filters.Shortlist' }),
				options: [
					{
						label: 'Shortlist',
						value: true
					},
					{
						label: 'Unshortlist',
						value: false
					}
				]
			}
		},
		{
			isToGraphql: true
		},
		[assessmentData?.assessment.pipelines]
	)

	const { checkoutFilter } = filters

	const handleSearch: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	> = e => {
		const { value: nextValue } = e.target
		// setSearch(nextValue)
		debouncedSearch(nextValue)
	}

	const handleToggleOrder = () => {
		if (order === 'ASC') {
			setOrder('DESC')
			return
		}
		setOrder('ASC')
	}

	const handleChangeSort = event => {
		const value = event.target.value as string
		setSort(value)
	}

	return (
		<TeamsContext.Provider
			value={{
				debounceSearch,
				filters,
				teamId,
				page,
				demo: !!demo,
				handlePageChange,
				handleSearch,
				order,
				sort: sort.concat(`_${order}`).toUpperCase() as EnumSortApplicantGetManyInput,
				handleToggleOrder,
				handleChangeSort,
				assessment: assessmentData?.assessment,
				getAssessment,
				applicants: applicantsData?.applicants,
				applicantsLoading,
				getApplicants,
				assessmentLoading,
				refetchAssessment: async () => {
					if (refetchAssessment) {
						await refetchAssessment()
					}
				},
				refetchApplicants: async (type?: 'Internal' | 'External' | null) => {
					if (refetchApplicants) {
						const data = await refetchApplicants(
							removeUndefinedKeys({
								type,
								page: type ? 1 : page,
								sort: sort
									.concat(`_${order}`)
									.toUpperCase() as EnumSortApplicantGetManyInput,
								filter:
									// @ts-ignore
									debounceSearch || checkoutFilter.length > 0
										? {
												AND: [
													// @ts-ignore
													...checkoutFilter,
													debounceSearch ? { name: debounceSearch } : null
												].filter(Boolean)
										  }
										: null
							})
						)
						return data?.data?.applicants
					}
				}
			}}
		>
			{children}
		</TeamsContext.Provider>
	)
}

export default TeamsProvider
