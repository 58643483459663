import React, { useEffect, useState } from 'react'
import { Button, Grid, Hidden, Typography, useMediaQuery, Theme } from '@material-ui/core'
import { Clear as ClearIcon } from '@material-ui/icons'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import useIntl from 'hooks/useIntl'
import SearchInput from 'components/SearchInput'
import { FilterItem } from 'components/FilterItem'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import useToggle from 'hooks/useToggle'
import FilterModal from 'components/FilterModal'
import FilterOptions from 'components/FilterOptions'

export interface TalentSearchProps {
	/**
	 * Defines the unique id of the team
	 */
	teamId: string
}

const teamSelector = (store: TeamContextProps) => ({
	handleSearch: store.handleSearch,
	refetchApplicants: store.refetchApplicants,
	debounceSearch: store.debounceSearch,
	handleChangeSort: store.handleChangeSort,
	handleToggleOrder: store.handleToggleOrder,
	sort: store.sort,
	filters: store.filters
})

export const TalentFilters: React.FC<TalentSearchProps> = ({ teamId }) => {
	const intl = useIntl()
	const [isFirst, setIsFirst] = useState(true)
	const [{ open }, { handleOpen, handleClose }] = useToggle()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const sortItems = [
		{
			label: intl.formatMessage({ id: 'teams.talent.filter.score' }),
			value: 'SCORE'
		},
		{
			label: intl.formatMessage({ id: 'teams.talent.filter.createdat' }),
			value: 'CREATED_AT'
		},
		{
			label: intl.formatMessage({ id: 'teams.talent.filter.updatedat' }),
			value: 'UPDATED_AT'
		},
		{
			label: intl.formatMessage({ id: 'teams.talent.filter.name' }),
			value: 'NAME'
		},
		{
			label: intl.formatMessage({ id: 'teams.talent.filter.progress' }),
			value: 'PROGRESS'
		}
	]

	const {
		handleSearch,
		refetchApplicants,
		debounceSearch,
		handleChangeSort,
		handleToggleOrder,
		sort,
		filters: { onClick, clearFilters, checkoutFilter, items }
	} = useTeamContext(teamSelector)

	// will only run for mdUp screens (filtering is triggered manually in mdDown screens)
	useEffect(() => {
		if (!isFirst) {
			if (!mdDown) refetchApplicants()
		} else {
			setIsFirst(false)
		}
		// eslint-disable-next-line
	}, [JSON.stringify(checkoutFilter), debounceSearch])

	// will only run for mdDown screens (moved filtering in filter modal)
	useEffect(() => {
		if (!isFirst) {
			if (mdDown) refetchApplicants()
		}
		// eslint-disable-next-line
	}, [debounceSearch])

	return (
		<>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h4" fontWeight={500}>
						{intl.formatMessage({ id: 'teams.talent' })}
					</Typography>
				</Grid>
				<Hidden smUp>
					<Grid item xs={12}>
						<SearchInput
							fullWidth
							placeholder={intl.formatMessage({ id: 'teams.searchInputPlaceholder' })}
							onChange={handleSearch}
						/>
					</Grid>
				</Hidden>
				<Grid item xs={12}>
					<Grid container justifyContent="space-between" spacing={3}>
						<Grid item container spacing={3}>
							<Hidden smDown>
								<Grid item sx={{ width: 326 }}>
									<SearchInput
										placeholder={intl.formatMessage({ id: 'teams.searchInputPlaceholder' })}
										onChange={handleSearch}
									/>
								</Grid>
							</Hidden>
							<Grid item>
								<FilterOptions
									filters={sortItems}
									field={sort}
									handleChangeSort={handleChangeSort}
									handleToggleOrder={handleToggleOrder}
								/>
							</Grid>
						</Grid>
						<Grid item xs={12}>
							<Grid
								container
								item
								justifyContent="space-between"
								sx={{ fontSize: pxToRem(14) }}
							>
								<Grid container item md={11}>
									<Hidden mdDown>
										{items.map(({ key, label, count }) => (
											<FilterItem
												key={key}
												onClick={onClick(key)}
												label={label}
												count={count}
											/>
										))}
									</Hidden>
									<Hidden mdUp>
										<Button sx={{ mt: 1 }} name="talentFilters" onClick={handleOpen}>
											<Typography>{intl.formatMessage({ id: 'filters' })}</Typography>
										</Button>
									</Hidden>
								</Grid>
								<Grid container item md={1} justifyContent="flex-end">
									{/* @ts-ignore */}
									{checkoutFilter.length > 0 && (
										<Hidden mdDown>
											<Button
												name="clearTalentFitlers"
												onClick={clearFilters}
												color="secondary"
												size="small"
												startIcon={<ClearIcon />}
												sx={{ p: 0 }}
											>
												{intl.formatMessage({ id: 'clearAll' })}
											</Button>
										</Hidden>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
			<FilterModal open={open} onClose={handleClose} onOk={() => refetchApplicants()} />
		</>
	)
}

export default TalentFilters
