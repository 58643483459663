import styled from 'styled-components'

export const ChipsContainer = styled.div`
	display: flex;
	align-items: center;
	flex-wrap: wrap;
	& > * {
		margin-right: 9px;
		margin-bottom: 9px !important;
		&:last-child {
			margin-right: 0;
		}
	}
`

export default ChipsContainer
