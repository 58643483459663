/* eslint-disable indent */
import React, { useState } from 'react'
import {
	Grid,
	TextField,
	MenuItem,
	useMediaQuery,
	Theme,
	Autocomplete,
	ListSubheader
} from '@material-ui/core'
import { Link } from 'react-router-dom'
import { useTheme } from 'styled-components'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import Modal, { IModalProps } from 'components/Modal'
import { useIntl } from 'hooks/useIntl'
import ColorPickerField from 'components/ColorPickerField'
import types from 'data/groupTypes.json'
import * as yup from 'yup'
import { CreatePipelineInput } from 'hooks/useApollo'
import { useStoreon } from 'storeon/react'
import EmailUpload from 'components/EmailUpload'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import ButtonLink from 'components/ButtonLink'
import { CreateTalentGroupForm, useCreateTalentGroupForm } from './useCreateTalentGroupForm'

export interface CreateTalentGroupModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the team
	 */
	id: string
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Defines the link to be copied
	 */
	link: string
	/**
	 * Callback fired when the finish button is clicked
	 */
	onCreate: (
		id: string,
		input: CreatePipelineInput,
		emailsToInvite?: string[],
		uploadedUsers?: any[]
	) => Promise<void>
	/**
	 * Defines if is inside a demo page
	 */
	demo?: boolean
}

export const CreateTalentGroupModal: React.FC<CreateTalentGroupModalProps> = ({
	id,
	link,
	onClose,
	open,
	onCreate,
	loading,
	demo
}) => {
	const theme = useTheme()
	const [uploadedUsers, setUploadedUsers] = useState<any[]>()
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const { user } = useStoreon('user')

	const [{ isOnBenchmarkingPlan, isOnHiringPlan, isOnTrial }] = useUserSubscriptions()

	const [
		{ errors, hex, rgba, anchorEl, formState, watch, setValue },
		{
			onSubmit,
			handleOpenAnchor,
			handleCloseAnchor,
			handleCopy,
			handleColorChange,
			handleColorChangeComplete
		},
		{ Controller }
	] = useCreateTalentGroupForm()

	const { isDirty, isValid } = formState
	const name = watch('name')

	const handleSubmit = async ({ teammateEmails, ...payload }: CreateTalentGroupForm) => {
		await onCreate(id, payload as CreatePipelineInput, teammateEmails, uploadedUsers)
		setUploadedUsers(undefined)
	}

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={onClose}
			size={mdDown ? 'normal' : 'big'}
			onOk={onSubmit(handleSubmit)}
			closeOnOk={false}
			okLoading={loading}
			onOkDisabled={!isDirty || !isValid}
			title={intl.formatMessage({ id: 'talentGroup.createNew' })}
			footer={{ finishButton: true }}
			maxWidth="xs"
		>
			<form onSubmit={onSubmit(handleSubmit)}>
				<Grid container spacing={4}>
					<Grid container item spacing={3}>
						<Grid item xs={12}>
							<Controller
								name="name"
								render={props => (
									<TextField
										fullWidth
										autoFocus
										label={intl.formatMessage({ id: 'talentGroupName' })}
										error={!!errors?.name?.message}
										helperText={errors?.name?.message}
										required
										{...props}
									/>
								)}
							/>
						</Grid>
						<Grid item container spacing={4}>
							<Grid item xs={12} md={8}>
								<Controller
									name="type"
									render={props => (
										<TextField
											label={intl.formatMessage({ id: 'talentType' })}
											select
											error={!!errors?.type?.message}
											helperText={errors?.type?.message}
											required
											{...props}
										>
											{types.map(type => {
												const isBlocked = demo
													? false
													: isOnTrial
													? false
													: isOnBenchmarkingPlan && type.value === 'Internal'
													? false
													: !(isOnHiringPlan && type.value === 'External')
												return isBlocked ? (
													<ListSubheader>
														<Grid container alignItems="center" spacing={1}>
															<Grid item sx={{ width: '20px' }}>
																<LockOutlinedIcon
																	fontSize="small"
																	sx={{
																		fill: theme.background.summary,
																		ml: -0.8,
																		mt: 2
																	}}
																/>
															</Grid>
															<Grid item xs>
																{type.label}
															</Grid>
															<Grid item>
																<Link to="/settings/billing">
																	<ButtonLink>
																		{intl.formatMessage({ id: 'activatePlan' })}
																	</ButtonLink>
																</Link>
															</Grid>
														</Grid>
													</ListSubheader>
												) : (
													<MenuItem value={type.value}>
														<Grid container alignItems="center" spacing={1}>
															<Grid item sx={{ width: '20px' }} />
															<Grid item>{type.label}</Grid>
														</Grid>
													</MenuItem>
												)
											})}
										</TextField>
									)}
								/>
							</Grid>
							<Grid item xs={12} md={4}>
								<Controller
									name="color"
									render={props => (
										<ColorPickerField
											label={intl.formatMessage({ id: 'groupColor' })}
											anchorEl={anchorEl}
											onClick={handleOpenAnchor}
											onClose={handleCloseAnchor}
											hex={hex}
											rgba={rgba}
											onColorChange={handleColorChange}
											onColorChangeComplete={handleColorChangeComplete}
											TextFieldProps={{ ...props }}
										/>
									)}
								/>
							</Grid>
						</Grid>
					</Grid>
					{!demo && name && (
						<EmailUpload
							uploadedUsers={uploadedUsers}
							handleClearUploadedUsers={() => setUploadedUsers(undefined)}
							handleUploadUsers={users => {
								setUploadedUsers(users)
								setValue('teammateEmails', [])
							}}
							disabledLink
							user={user}
							handleCopy={() => handleCopy(link)}
							controllerChildren={
								<Controller
									name="teammateEmails"
									render={({ value, onChange, ...props }) => (
										<Autocomplete
											freeSolo
											multiple
											options={[]}
											value={value}
											disabled={!!uploadedUsers}
											renderInput={params => (
												<TextField
													{...params}
													{...props}
													placeholder={
														value?.length
															? undefined
															: intl.formatMessage({ id: 'multiEmailFieldPlaceholder' })
													}
													helperText={intl.formatMessage({
														id: 'talentGroup.helperText'
													})}
												/>
											)}
											onChange={async (_, newValue) => {
												const valid = await yup
													.string()
													.email()
													.isValid(newValue[newValue.length - 1])

												if (!valid) {
													return
												}

												onChange(newValue)
											}}
										/>
									)}
								/>
							}
						/>
					)}
				</Grid>
			</form>
		</Modal>
	)
}

export default CreateTalentGroupModal
