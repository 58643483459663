import React from 'react'
import { Typography, Grid, Button, Popper } from '@material-ui/core'
import { useTheme } from 'styled-components'

import { Bubble } from './SpeechBubble.style'

export interface SpeechBubbleProps {
	id: string
	text: string
	btnLabel?: string
	onClick?: () => void
	anchorEl: HTMLElement | null
}

export const SpeechBubble: React.FC<SpeechBubbleProps> = ({
	id,
	text,
	btnLabel,
	onClick,
	anchorEl
}) => {
	const theme = useTheme()
	return (
		<Popper id={id} placement="top" open={Boolean(anchorEl)} anchorEl={anchorEl}>
			{/* {({ TransitionProps, placement }) => (
				<Grow
					{...TransitionProps}
					style={{
						transformOrigin: placement === 'top' ? 'center top' : 'center bottom'
					}}
				> */}
			<div style={{ marginBottom: 18 }}>
				<Bubble>
					<Grid container spacing={2}>
						<Grid item xs={12}>
							<Typography sx={{ color: theme.tag.white, fontSize: 12 }}>{text}</Typography>
						</Grid>
						{onClick && (
							<Grid item xs={12} container justifyContent="flex-end">
								<Button variant="contained" color="primary" size="small" onClick={onClick}>
									{btnLabel}
								</Button>
							</Grid>
						)}
					</Grid>
				</Bubble>
			</div>
			{/* </Grow>
			)} */}
		</Popper>
	)
}

export default SpeechBubble
