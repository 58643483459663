import React from 'react'
import { Grid, Typography, Chip } from '@material-ui/core'
import Badge from 'components/Badge'
import Accordion from 'components/Accordion'
import useIntl from 'hooks/useIntl'
import { ApplicantsScoreSkillsFieldsFragment, IndustryBenchmark } from 'hooks/useApollo'
import { RecordTable, RecordTableProps } from 'components/RecordTable'
import ChipsContainer from './components/ChipsContainer'
import SkillsBenchmark from './components/SkillsBenchmark'

export interface ProfileTabProps {
	/**
	 * Defines the badges achieved by the talent
	 */
	badges: { label: string; color: string }[]
	/**
	 * Defines the role interest of the talent
	 */
	interests: string[]
	/**
	 * Defines the self reported skills of the talent
	 */
	skills: string[]
	/**
	 * Defines the people skills of the talent
	 */
	peopleSkills: ApplicantsScoreSkillsFieldsFragment[]
	/**
	 * Defines the custom fields of the talent
	 */
	customFields?: { [key: string]: string }
	/**
	 * Defines the role function of the talent
	 */
	roleFunction?: string
	/**
	 * Defines the role level of the talent
	 */
	roleLevel?: string
	/**
	 * Defines the experience level of the talent
	 */
	experienceLevel?: string
	/**
	 * Defines the education level of the talent
	 */
	education?: string
	benchmarks: Record<string, IndustryBenchmark>
	isRoleBased?: boolean
	role?: string
	user: { firstName: string; lastName: string; photoUrl: string }
}

export const ProfileTab: React.FC<ProfileTabProps> = ({
	badges,
	interests,
	skills,
	peopleSkills,
	roleFunction,
	experienceLevel,
	education,
	roleLevel,
	customFields,
	isRoleBased,
	benchmarks,
	role,
	user
}) => {
	const intl = useIntl()
	const fields = customFields && Object.keys(customFields)

	const columns: RecordTableProps['columns'] = [
		{
			id: '1',
			title: intl.formatMessage({ id: 'fieldName' }),
			content: (key: any) => <Typography>{key}</Typography>
		},
		{
			id: '2',
			title: intl.formatMessage({ id: 'value' }),
			content: (key: any) => <Typography>{customFields?.[key]}</Typography>
		}
	]

	return (
		<Grid sx={{ pb: 4 }} container spacing={4}>
			{/* Badges */}
			{badges.length > 0 && (
				<>
					<Grid item xs={12}>
						<Typography>{intl.formatMessage({ id: 'badges' })}</Typography>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={2}>
							{badges.map(({ label, color }, index) => (
								<Grid item key={index}>
									<Badge label={label} color={color} />
								</Grid>
							))}
						</Grid>
					</Grid>
				</>
			)}
			<Grid item xs={12}>
				{/* People Skills */}
				{peopleSkills.length > 0 && (
					<Accordion label={intl.formatMessage({ id: 'softSkillScore' })}>
						<SkillsBenchmark
							isRoleBased={isRoleBased}
							benchmarks={benchmarks}
							skills={peopleSkills}
							role={role}
							user={user}
						/>
					</Accordion>
				)}
				{/* Role Interests */}
				{interests.length > 0 && (
					<Accordion
						label={intl.formatMessage({ id: 'roleInterests' })}
						defaultExpanded={false}
					>
						<ChipsContainer>
							{interests.map(skill => (
								<Chip key={skill} label={skill} />
							))}
						</ChipsContainer>
					</Accordion>
				)}
				{/* Self-Reported Skills */}
				{skills.length > 0 && (
					<Accordion
						label={intl.formatMessage({ id: 'selfReportedSkills' })}
						css={{ fontWeight: 500 }}
						defaultExpanded={false}
					>
						<ChipsContainer>
							{skills.map(skill => (
								<Chip key={skill} label={skill} />
							))}
						</ChipsContainer>
					</Accordion>
				)}
				{/* Role Functions */}
				{roleFunction && (
					<Accordion
						label={intl.formatMessage({ id: 'roleFunction' })}
						defaultExpanded={false}
					>
						<ChipsContainer>
							<Chip label={roleFunction} />
						</ChipsContainer>
					</Accordion>
				)}
				{/* Role Level */}
				{roleLevel && (
					<Accordion
						label={intl.formatMessage({ id: 'roleLevel' })}
						defaultExpanded={false}
					>
						<ChipsContainer>
							<Chip label={roleLevel} />
						</ChipsContainer>
					</Accordion>
				)}
				{/* Education */}
				{education && (
					<Accordion
						label={intl.formatMessage({ id: 'education' })}
						defaultExpanded={false}
					>
						<ChipsContainer>
							<Chip label={education} />
						</ChipsContainer>
					</Accordion>
				)}
				{/* Experience Level */}
				{experienceLevel && (
					<Accordion
						label={intl.formatMessage({ id: 'experienceLevel' })}
						defaultExpanded={false}
					>
						<ChipsContainer>
							<Chip label={experienceLevel} />
						</ChipsContainer>
					</Accordion>
				)}

				{/* More */}
				{fields && fields.length > 0 && (
					<Accordion
						label={intl.formatMessage({ id: 'customFields' })}
						defaultExpanded={false}
					>
						<RecordTable data={fields} columns={columns} />
					</Accordion>
				)}
			</Grid>
		</Grid>
	)
}

export default ProfileTab
