import React from 'react'
import { Grid, Button, useMediaQuery, Theme, Hidden, IconButton } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import { useTheme } from 'styled-components'

import useIntl from 'hooks/useIntl'
import { StepNavigation, StepNavigationProps } from 'components/StepNavigation'
import LightBulbIcon from 'assets/icons/LightBulbIcon'

export interface WizardFooterProps extends StepNavigationProps {
	onOpenHelpGuide?: () => void
}

export const WizardFooter: React.FC<WizardFooterProps> = ({
	children,
	onOpenHelpGuide,
	...props
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	return (
		<div style={{ position: 'relative' }}>
			<Grid
				container
				alignItems="center"
				justifyContent={upSm ? 'center' : 'flex-end'}
				sx={{
					backgroundColor: theme.background.light,
					padding: '22px 48px',
					position: 'fixed',
					borderTop: `1px solid ${theme.grey.fourth}`,
					bottom: 0,
					left: 0
				}}
			>
				{onOpenHelpGuide && (
					<Grid item sx={{ position: 'absolute', left: 22 }}>
						<Hidden smDown>
							<Button
								variant="contained"
								onClick={onOpenHelpGuide}
								startIcon={<LightBulbIcon style={{ width: 10, height: 14 }} />}
								sx={{
									backgroundColor: theme.tag.blue,
									'&:hover': { backgroundColor: alpha(theme.tag.blue, 0.9) }
								}}
							>
								{intl.formatMessage({ id: 'onboarding.help.guide.btn.label' })}
							</Button>
						</Hidden>
						<Hidden smUp>
							<IconButton
								onClick={onOpenHelpGuide}
								sx={{
									backgroundColor: theme.tag.blue,
									'&:hover': { backgroundColor: alpha(theme.tag.blue, 0.9) }
								}}
							>
								<LightBulbIcon style={{ width: 12, height: 12 }} />
							</IconButton>
						</Hidden>
					</Grid>
				)}
				<Grid item>
					<StepNavigation {...props} />
				</Grid>
			</Grid>
		</div>
	)
}

export default WizardFooter
