import React, { useReducer, createContext, useContext } from 'react'

import { TalentActions } from './talentContextActions'
import { talentReducer, defaultState, TalentState } from './talentContextReducer'

type Dispatch = React.Dispatch<TalentActions>

const TalentStateContext = createContext<TalentState | undefined>(undefined)
const TalentDispatchContext = createContext<Dispatch | undefined>(undefined)

const TalentProvider: React.FC = ({ children }) => {
	const [state, dispatch] = useReducer(talentReducer, defaultState)
	return (
		<TalentStateContext.Provider value={state}>
			<TalentDispatchContext.Provider value={dispatch}>
				{children}
			</TalentDispatchContext.Provider>
		</TalentStateContext.Provider>
	)
}

const useTalentState = (): TalentState => {
	const context = useContext(TalentStateContext)
	if (context === undefined) {
		throw new Error('useTalentState must be used within a TalentProvider')
	}
	return context
}

const useTalentDispatch = (): Dispatch => {
	const context = useContext(TalentDispatchContext)
	if (context === undefined) {
		throw new Error('useTalentDispatch must be used within a TalentProvider')
	}
	return context
}

const useTalentContext = (): [TalentState, Dispatch] => [
	useTalentState(),
	useTalentDispatch()
]

export { TalentProvider, useTalentContext }
export default TalentProvider
