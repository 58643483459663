import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTheme } from 'styled-components'

export interface TestimonialsProps {
	avatar?: string
	name: string
	title?: string
	subName?: string
	testimonial: string
}

export const Testimonials: React.FC<TestimonialsProps> = ({
	avatar = '',
	name,
	title,
	subName,
	testimonial
}) => {
	const theme = useTheme()

	return (
		<Grid
			container
			alignItems="center"
			sx={{
				backgroundColor: theme.tag.white,
				borderRadius: 5,
				margin: '30px',
				zIndex: '50'
			}}
		>
			<Grid
				item
				container
				direction="column"
				alignItems="center"
				spacing={4}
				sx={{ padding: '30px' }}
			>
				<Grid item>
					<img src={avatar} width="80px" alt={subName ? `${name}-${subName}` : name} />
				</Grid>
				<Grid item container direction="column" spacing={1}>
					<Grid item>
						<Typography
							align="center"
							variant="paragraph1"
							sx={{ lineHeight: '24px', color: theme.tag.black }}
						>
							“{testimonial}”
						</Typography>
					</Grid>
					<Grid item>
						<Typography
							align="center"
							fontWeight={500}
							sx={{ mt: 3, fontSize: 12, color: theme.tag.black }}
						>
							{title ? `${name} - ${title}` : name}
						</Typography>
					</Grid>
					{subName && (
						<Grid item>
							<Typography
								align="center"
								sx={{ color: theme.text.description, fontSize: 12 }}
							>
								{subName}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default Testimonials
