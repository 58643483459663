import { useMemo } from 'react'
import { useTheme } from 'styled-components'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import { useTalentContext } from '../../Talents/context'
import { ChartData, ChartSkill, ScoreMapping } from '../components/TeamDynamicChart'

const teamSelector = (store: TeamContextProps) => store.assessment

export const useChartData = () => {
	const theme = useTheme()
	const assessment = useTeamContext(teamSelector)
	const [{ selectedTalent, pinnedUsers }] = useTalentContext()

	const chartSkills: ChartSkill[] = [
		{
			id: 'talent',
			name: 'Talent',
			color: theme.primary.main
		},
		...(assessment?.pipelines.map(pipeline => ({
			id: pipeline?.id as string,
			name: pipeline?.name as string,
			color: pipeline?.color as string
		})) || [])
	]

	const scoreMappingByPipeline = (assessment?.pipelines || []).reduce(
		(mapping, pipeline) => {
			mapping[pipeline?.id as string] = {}

			pipeline?.skills.forEach(skill => {
				mapping[pipeline?.id as string][skill.skill?.id as string] = skill.score
			})

			return mapping
		},
		{} as Record<string, Record<string, number>>
	)

	const pipelines = assessment?.pipelines || []
	const skills = assessment?.pipelines?.[0]?.skills || []

	const chartData: ChartData[] = useMemo(
		() =>
			skills
				.map(currentSkill => {
					const scoreMapping = pipelines.reduce(
						(currentMapping, pipeline) => {
							const pipelineId = pipeline?.id as string
							const currentSkillId = currentSkill.skill?.id as string

							const score = scoreMappingByPipeline[pipelineId][currentSkillId]
							currentMapping[pipelineId] = score

							return currentMapping
						},
						{ emptyPlaceholder: 0 } as ScoreMapping
					)

					const talentSeries = { [selectedTalent.id]: selectedTalent, ...pinnedUsers }
					const talentSeriesKeys = Object.keys(talentSeries)

					const [totalSkillScoreFromAllTalents, scoreLength] = talentSeriesKeys.reduce(
						([currentTotal, currentScoreLength], key) => {
							const skill = talentSeries[key]?.score?.skills.find(
								skill => skill.skillId === currentSkill.skill?.id
							)

							if (!skill) {
								return [currentTotal, currentScoreLength]
							}

							return [currentTotal + skill.score, currentScoreLength + 1]
						},
						[0, 0]
					)

					scoreMapping.talent = totalSkillScoreFromAllTalents / scoreLength

					return {
						skill: currentSkill.skill?.name as string,
						scoreMapping,
						emptyPlaceholder: 1
					}
				})
				.sort((a, b) => (a.skill < b.skill ? -1 : 1)),
		[skills, pipelines, pinnedUsers, selectedTalent, scoreMappingByPipeline]
	)

	return {
		chartSkills,
		chartData,
		pipelines
	}
}
