import React from 'react'
import { Grid, Theme, useMediaQuery } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import Tabs from 'components/Tabs'
import ChallengeProgress, {
	ChallengeProgressProps
} from './components/ChallengeProgress/ChallengeProgress'
import ProfileHeader, { ProfileHeaderProps } from './components/ProfileHeader'
import ProfileTab, { ProfileTabProps } from './tabs/ProfileTab'
import ChallengeTab, { ChallengeTabProps } from './tabs/ChallengeTab'
import NotesTab, { NotesTabProps } from './tabs/NotesTab'

export interface TalentProfileProps {
	/**
	 * Props applied to ProfileHeader component
	 */
	profileHeaderProps: ProfileHeaderProps
	/**
	 * Props applied to ChallengeProgress component
	 */
	challengeProgressProps: ChallengeProgressProps
	/**
	 * Props applied to ProfileTab component
	 */
	profileTabProps: ProfileTabProps
	/**
	 * Props applied to ChallengeTab component
	 */
	challengeTabProps: ChallengeTabProps
	/**
	 * Props applied to NotesTab component
	 */
	notesTabProps: NotesTabProps
	/**
	 * Define if is a talent profile inside the demo page
	 */
	demo?: boolean
}

export const TalentProfile: React.FC<TalentProfileProps> = ({
	challengeProgressProps,
	profileHeaderProps,
	profileTabProps,
	challengeTabProps,
	notesTabProps,
	demo
}) => {
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	return (
		<Grid container spacing={4}>
			<Grid item xs={12}>
				<ProfileHeader {...profileHeaderProps} />
			</Grid>
			<Grid item xs={12}>
				<ChallengeProgress demo={demo} {...challengeProgressProps} />
			</Grid>
			<Grid container item>
				<Grid item xs>
					<Tabs
						tabs={[
							{
								label: intl.formatMessage({ id: 'profile' }),
								component: <ProfileTab {...profileTabProps} />
							},
							{
								label: intl.formatMessage({ id: 'challengeSubmission' }),
								component: <ChallengeTab {...challengeTabProps} />
							},
							{
								label: intl.formatMessage({ id: 'notes' }),
								component: <NotesTab {...notesTabProps} />
							}
						]}
						variant={mdDown ? 'fullWidth' : 'scrollable'}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TalentProfile
