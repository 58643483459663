import { ToggleButtonGroup, ToggleButton } from '@material-ui/core'
import { alpha, withStyles } from '@material-ui/core/styles'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'

export const ButtonGroupContainer = withStyles({
	root: {
		// @ts-ignore
		display: null
	},
	groupedHorizontal: {
		marginBottom: 10,
		'&:not(:last-child)': {
			borderTopRightRadius: null,
			borderBottomRightRadius: null,
			marginRight: 20
		},
		'&:not(:first-child)': {
			borderTopLeftRadius: null,
			borderBottomLeftRadius: null,
			marginLeft: null,
			borderLeft: null
		},
		'&.Mui-selected + &.Mui-selected': {
			borderLeft: null,
			marginLeft: null
		}
	}
})(ToggleButtonGroup)

// @ts-ignore
export const ButtonSelectable = withStyles(theme => ({
	root: {
		padding: '8px 25px',
		fontSize: pxToRem(15),
		fontWeight: 400,
		textTransform: null,
		borderColor: theme.palette.grey['600'],
		color: theme.palette.text.primary,
		borderWidth: 1.5,
		letterSpacing: null,
		lineHeight: null,
		'&.Mui-selected': {
			borderWidth: 2,
			color: theme.palette.primary.main,
			backgroundColor: alpha(theme.palette.primary.main, 0.35),
			borderColor: alpha(theme.palette.primary.main, 0.5),
			fontWeight: 500,
			'&:hover': {
				backgroundColor: alpha(theme.palette.primary.main, 0.25)
			}
		}
	}
}))(ToggleButton)
