import React, { useState, useMemo, useEffect } from 'react'
import { IconButton, Grid, Typography, Menu, Divider, Switch } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import ArrowUpwardIcon from '@material-ui/icons/ArrowUpward'
import MoreHorizIcon from '@material-ui/icons/MoreHoriz'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'
import PopupMenuItem from 'components/PopupMenuItem'
import { useToggleAnchor } from 'hooks/useToggleAnchor'
import StatusButton from 'components/StatusButton'
import _statuses from 'data/teamStatus.json'
import { EnumAssessmentStatus } from 'hooks/useApollo'

import { ProjectCardContainer } from './TeamCard.styles'

export interface TeamCardProps {
	/**
	 * Defines the unique id of the team
	 */
	id: string
	/**
	 * Defines the name of the team
	 */
	name: string
	/**
	 * Defines the masking status of the team
	 * @default false
	 */
	masking: boolean
	/**
	 * Defines the status of the team
	 * @default 'inactive'
	 */
	status?: 'inactive' | 'active'
	/**
	 * Defines the total number of talents in the team
	 */
	count: number
	/**
	 * Defines the numbers of newly added talent in the team since last login
	 */
	newCounts: number
	/**
	 * Callback fired when the ellipsis button is clicked
	 */
	onMenuOpen: () => void
	/**
	 * Callback fired when the edit button is clicked
	 */
	onEdit: () => void
	/**
	 * Callback fired when the mask talent switch button is clicked
	 */
	onMask: (id: string, masking: boolean) => void
	/**
	 * Callback fired when the delete button is clicked
	 */
	onDelete: () => void
	/**
	 * Callback fired when the card is clicked
	 */
	onClick?: () => void
	/**
	 * Callback fired when the status is changed
	 */
	onStatusChange: (id: string, status: EnumAssessmentStatus) => void
}

export const TeamCard: React.FC<TeamCardProps> = ({
	id,
	status = 'inactive',
	name,
	count,
	newCounts,
	masking,
	onMask,
	onEdit,
	onDelete,
	onMenuOpen,
	onClick,
	onStatusChange
}) => {
	const theme = useTheme()
	const intl = useIntl()
	const [masked, setMasked] = useState(masking)
	const statuses = useMemo(
		() =>
			_statuses.map(status => ({
				label: status.label,
				color: (status[theme.mode] ?? status[status.default]) as string
			})),
		[theme.mode]
	)
	const [active, setActive] = useState(() =>
		statuses.findIndex(el => el.label.toLowerCase() === status.toLowerCase())
	)
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()

	useEffect(() => {
		setActive(statuses.findIndex(el => el.label.toLowerCase() === status.toLowerCase()))
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [status, JSON.stringify(statuses)])

	const handleChangeStatus = (status: string) => {
		onMenuOpen()
		const index = statuses.findIndex(el => el.label === status)
		setActive(index)
		onStatusChange(id, status.toLowerCase() as EnumAssessmentStatus)
	}

	const handleNextStatusChange = () => {
		if (active === statuses.length - 1) {
			setActive(0)
			return
		}
		setActive(x => x + 1)
	}

	const handleEdit = () => {
		onEdit()
		handleCloseAnchor()
	}

	const handleDelete = () => {
		onDelete()
		handleCloseAnchor()
	}

	const handleMasking = () => {
		const inverse = !masked
		setMasked(inverse)
		onMask(id, inverse)
	}

	return (
		<ProjectCardContainer
			onClick={() => {
				if (!anchorEl && onClick) {
					onClick()
				}
			}}
		>
			<Grid container spacing={1}>
				<Grid item container justifyContent="flex-end">
					<Grid item>
						<IconButton
							name="card menu"
							aria-label="card menu"
							aria-controls="team-card-menu"
							aria-haspopup="true"
							size="small"
							onClick={e => {
								e.preventDefault()
								e.stopPropagation()
								handleOpenAnchor(e)
								onMenuOpen()
							}}
						>
							<MoreHorizIcon color="secondary" fontSize="small" />
						</IconButton>
						<Menu
							id={`team-${id}-menu`}
							anchorEl={anchorEl}
							open={Boolean(anchorEl)}
							onClose={e => {
								// @ts-ignore
								if (e?.preventDefault) {
									// @ts-ignore
									e.preventDefault()
								}
								// @ts-ignore
								if (e?.stopPropagation) {
									// @ts-ignore
									e.stopPropagation()
								}
								handleCloseAnchor()
							}}
							MenuListProps={{
								disablePadding: true
							}}
							PaperProps={{
								elevation: 9,
								style: {
									maxWidth: 210,
									width: '100%'
								}
							}}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: 'top',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<PopupMenuItem
								primary={
									// eslint-disable-next-line react/jsx-wrap-multilines
									<Grid container justifyContent="space-between" alignItems="center">
										{intl.formatMessage({ id: 'teamCardMenu.edit.primary' })}
										<EditIcon sx={{ fontSize: 16, mr: 0.5 }} />
									</Grid>
								}
								secondary={intl.formatMessage({ id: 'teamCardMenu.edit.secondary' })}
								onClick={e => {
									e.stopPropagation()
									handleEdit()
								}}
							/>
							<Divider />
							<PopupMenuItem
								style={{ padding: '0px 10px' }}
								primary={
									// eslint-disable-next-line react/jsx-wrap-multilines
									<Grid container justifyContent="space-between" alignItems="center">
										{intl.formatMessage({ id: 'teamCardMenu.mask.primary' })}
										<Switch
											color="primary"
											onClick={e => e.stopPropagation()}
											checked={masked}
											onChange={handleMasking}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</Grid>
								}
								secondary={intl.formatMessage({ id: 'teamCardMenu.mask.secondary' })}
								onClick={e => {
									e.preventDefault()
									e.stopPropagation()
									handleMasking()
								}}
							/>
							<Divider />
							<PopupMenuItem
								danger
								primary={intl.formatMessage({ id: 'teamCardMenu.delete.primary' })}
								secondary={intl.formatMessage({ id: 'teamCardMenu.delete.secondary' })}
								onClick={e => {
									e.stopPropagation()
									handleDelete()
								}}
							/>
						</Menu>
					</Grid>
				</Grid>
				<Grid item container justifyContent="space-between">
					<Grid item>
						<Typography variant="h5">{name}</Typography>
					</Grid>
					<Grid item>
						<StatusButton
							size="small"
							statuses={statuses}
							currentStatus={active}
							onNextStatus={handleNextStatusChange}
							onChangeStatus={handleChangeStatus}
						/>
					</Grid>
				</Grid>
				<Grid item container alignItems="center" justifyContent="space-between">
					<Grid item>
						<Grid container alignItems="center" spacing={1}>
							<Grid item>
								<Typography variant="h1" sx={{ fontWeight: 500 }}>
									{count}
								</Typography>
							</Grid>
							<Grid item>
								<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
									{intl.formatMessage({ id: 'talent' })}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					{!newCounts ? null : (
						<Grid item>
							{/* TODO: put the color in theme */}
							<Typography variant="paragraph1" sx={{ color: '##64C8BC' }}>
								<div
									style={{
										borderRadius: 50,
										backgroundColor: '##64C8BC',
										display: 'inline-flex',
										justifyContent: 'center',
										alignItems: 'center',
										marginRight: '8px',
										height: 18,
										width: 18
									}}
								>
									<ArrowUpwardIcon sx={{ fill: theme.tag.white, height: 12, width: 12 }} />
								</div>
								{intl.formatMessage({ id: 'newSinceLastLogin' }, { count: newCounts })}
							</Typography>
						</Grid>
					)}
				</Grid>
			</Grid>
		</ProjectCardContainer>
	)
}

export default TeamCard
