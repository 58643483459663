import React from 'react'
import Typography from '@material-ui/core/Typography'
import { AccordionContainer, AccordionContent, AccordionHeader } from './Accordion.style'

export interface AccordionProps {
	label: React.ReactNode
	bordered?: boolean
	children?: React.ReactNode
	/**
	 * @default true
	 */
	defaultExpanded?: boolean
}

export function Accordion({
	label,
	children,
	bordered = false,
	defaultExpanded = true
}: AccordionProps) {
	return (
		<AccordionContainer defaultExpanded={defaultExpanded} bordered={bordered}>
			<AccordionHeader bordered={bordered}>
				<Typography
					variant="paragraph1"
					color="textPrimary"
					style={{ lineHeight: 'normal', fontWeight: 500 }}
				>
					{label}
				</Typography>
			</AccordionHeader>
			<AccordionContent>{children}</AccordionContent>
		</AccordionContainer>
	)
}

export default Accordion
