import React from 'react'
import { useTheme } from 'styled-components'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CheckRoundedFilledIcon = ({
	active,
	...props
}: SvgIconProps & { active?: boolean }) => {
	const theme = useTheme()
	return (
		<SvgIcon {...props} width="16" height="16" viewBox="0 0 16 16" fill="none">
			<circle
				cx="8"
				cy="8"
				r="8"
				fill={active ? theme.tag.darkerBlue : theme.grey.fourth}
			/>
			<path
				d="M10.3305 5.83904C10.5602 5.60932 10.9326 5.60932 11.1623 5.83904C11.3921 6.06876 11.3921 6.44121 11.1623 6.67093L7.63293 10.2003C7.40321 10.4301 7.03076 10.4301 6.80104 10.2003L4.84026 8.23956C4.61054 8.00984 4.61054 7.63739 4.84026 7.40767C5.06998 7.17795 5.44243 7.17795 5.67215 7.40767L7.21699 8.9525L10.3305 5.83904Z"
				fill="white"
			/>
		</SvgIcon>
	)
}

export default CheckRoundedFilledIcon
