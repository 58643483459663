/* eslint-disable camelcase */
/* eslint-disable no-shadow */
import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import {
	DragDropContext,
	DragDropContextProps,
	Draggable,
	Droppable
} from 'react-beautiful-dnd'
import { useFormContext } from 'react-hook-form'

import { useIntl } from 'hooks/useIntl'
import { Alert } from 'components/Alert'
import { CreationContent } from 'components/CreationContent'
import { SkillItem } from 'screens/Onboarding/components/SkillItem'
import WizardFooter from 'components/Wizard/WizardFooter'

import { ContentWrapper } from '../../../../components/ContentWrapper'
import { useOnboardingStore } from '../../../../hooks/useOnboardingStore'
import { FreeFormType } from '../../FreeForm.types'
import { useFreeFormStore } from '../../hooks/useFreeFormStore'

export const FreeFormChallengePreview: React.FC = () => {
	const intl = useIntl()
	const { getValues, setValue, watch } = useFormContext<FreeFormType>()
	const { chapterMappingById } = useFreeFormStore(state => state)
	const { currentStep, prevStep, nextStep, toggleHelpGuide } = useOnboardingStore(
		state => state
	)

	const selectedChapters = watch('chapters')

	const handleDragEnd: DragDropContextProps['onDragEnd'] = ({ source, destination }) => {
		if (!destination) {
			return
		}

		if (source.droppableId === destination.droppableId) {
			const items = reorder(selectedChapters, source.index, destination.index)

			setValue(source.droppableId, items)
		}
	}

	const handleItemDelete = (id: string, droppableId: string) => {
		const { chapters } = getValues()

		setValue(
			droppableId,
			chapters.filter(currentId => currentId !== id)
		)
	}

	const handleBack = () => prevStep()

	const handleContinue = () => {
		nextStep()
	}

	const dragAndDropGroups = [
		{
			droppableId: 'chapters',
			items: selectedChapters
		}
	]

	const minutesEstimative = getMinutesEstimative(selectedChapters)

	const totalSelectedSkills = selectedChapters.length

	const canContinue = totalSelectedSkills >= 3
	const canDelete = totalSelectedSkills > 3

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent
					subHeader
					subTitle={intl.formatMessage({
						id: 'onboarding.select.free.form.title'
					})}
					title={intl.formatMessage({
						id: `onboarding.free.form.step.${currentStep}.title`
					})}
					desc={intl.formatMessage({
						id: `onboarding.free.form.step.${currentStep}.desc`
					})}
				>
					<Grid container direction="column" alignItems="stretch" spacing={6}>
						{canContinue ? null : (
							<Grid item>
								<Alert
									open
									color="danger"
									label={intl.formatMessage(
										{
											id: 'teams.onboarding.skillImportance.skillsRequired'
										},
										{ count: 3 }
									)}
								/>
							</Grid>
						)}
						<Grid item>
							<Typography variant="paragraph1" gutterBottom>
								{intl.formatMessage({
									id: 'teams.onboarding.skillImportance.totalChallengeTime.title'
								})}{' '}
								<strong>
									{minutesEstimative.low}-{minutesEstimative.high}{' '}
									{intl.formatMessage({ id: 'mins' })}
								</strong>
							</Typography>
						</Grid>
						<DragDropContext onDragEnd={handleDragEnd}>
							{dragAndDropGroups.map(({ droppableId, items }) => (
								<Grid key={droppableId} item>
									<Droppable droppableId={droppableId}>
										{provided => (
											<Grid
												container
												direction="column"
												justifyContent="flex-start"
												alignItems="stretch"
												spacing={2}
												ref={provided.innerRef}
												{...provided.droppableProps}
											>
												{items.map((chapterId, index) => {
													const { id, name, desc, timer, parts } = chapterMappingById[
														chapterId
													]

													return (
														<Draggable key={id} draggableId={id} index={index}>
															{provided => {
																const handleDelete = () => {
																	handleItemDelete(id, droppableId)
																}

																return (
																	<SkillItem
																		id={id}
																		ref={provided.innerRef}
																		parts={parts.length}
																		title={name || ''}
																		description={desc || ''}
																		durationInMinutes={timer || 0}
																		previewUrl="#"
																		dragHandleProps={provided.dragHandleProps}
																		onDelete={canDelete ? handleDelete : undefined}
																		{...provided.draggableProps}
																	/>
																)
															}}
														</Draggable>
													)
												})}
												{provided.placeholder}
											</Grid>
										)}
									</Droppable>
								</Grid>
							))}
						</DragDropContext>
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={!canContinue}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

export default FreeFormChallengePreview

const getMinutesEstimative = (chapterIds: string[]) => ({
	low: chapterIds.length * 5,
	high: chapterIds.length * 8
})

const reorder = <TItem extends unknown>(list: TItem[], startIndex, endIndex) => {
	const result = Array.from<TItem>(list)
	const [removed] = result.splice(startIndex, 1)
	result.splice(endIndex, 0, removed)

	return result
}
