import React, { useEffect, useMemo, useState } from 'react'
import { Divider, Grid, Pagination, Theme, useMediaQuery } from '@material-ui/core'
import Tabs from 'components/Tabs'
import useIntl from 'hooks/useIntl'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import TalentSearchList from './TalentSearchList'
import { setSelectedTalent, useTalentContext } from '../context'

export interface TalentSearchProps {}

const teamSelector = (store: TeamContextProps) => ({
	applicants: store.applicants,
	loading: store.applicantsLoading,
	page: store.page,
	getApplicants: store.getApplicants,
	teamId: store.teamId,
	handlePageChange: store.handlePageChange,
	refetchApplicants: store.refetchApplicants,
	sort: store.sort
})

export const TalentSearch: React.FC<TalentSearchProps> = () => {
	const intl = useIntl()
	const [isFirst, setIsFirst] = useState(true)
	const [pickFirst, setPickFirst] = useState(true)
	const [, dispatch] = useTalentContext()
	const {
		applicants,
		loading,
		page,
		getApplicants,
		teamId,
		handlePageChange,
		refetchApplicants,
		sort
	} = useTeamContext(teamSelector)
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	useEffect(() => {
		getApplicants({
			variables: {
				assessId: teamId,
				sort,
				filter: null,
				page: 1
			}
		})
		// eslint-disable-next-line
	}, [teamId])

	useEffect(() => {
		if (!isFirst) {
			refetchApplicants()
		} else {
			setIsFirst(false)
		}
		// eslint-disable-next-line
	}, [page, sort])

	const handleChangeTab = async (newTab: number) => {
		const newApplicants = await refetchApplicants(
			newTab === 1 ? 'Internal' : newTab === 2 ? 'External' : null
		)
		const talent = newApplicants?.items?.[0]
		if (talent && !smDown) {
			dispatch(
				setSelectedTalent({
					...(talent?.user as { firstName: string; lastName: string }),
					id: talent?.id,
					pipelineType: talent?.pipelineType,
					pipelineId: talent?.pipelinesId[0] as string,
					pipelinesId: talent?.pipelinesId,
					masked: !!talent?.masking,
					score: talent?.score || null
				})
			)
		}
		handlePageChange(undefined, 1)
	}

	const TalentList = useMemo(
		() => (
			<TalentSearchList
				pickFirst={pickFirst}
				smDown={smDown}
				onPickFirst={() => {
					setPickFirst(false)
				}}
				loading={loading}
				talents={applicants?.items || []}
			/>
		),
		[applicants, loading, pickFirst, smDown]
	)

	return (
		<Grid container item>
			<Grid container item>
				<Grid item xs={12} id="team-tours-talent-search" style={{ height: '100%' }}>
					<Tabs
						onChange={handleChangeTab}
						tabs={[
							{
								label: intl.formatMessage(
									{ id: 'teams.talent.all' },
									{ total: applicants?.totalTalents.count || 0 }
								),
								component: TalentList
							},
							{
								label: intl.formatMessage(
									{ id: 'teams.talent.employee' },
									{ total: applicants?.totalTalents.employees || 0 }
								),
								component: TalentList
							},
							{
								label: intl.formatMessage(
									{ id: 'teams.talent.candidate' },
									{ total: applicants?.totalTalents.candidates || 0 }
								),
								component: TalentList
							}
						]}
						variant="fullWidth"
						tabStyle={{ fontSize: pxToRem(13), height: 56 }}
						centered
					/>
				</Grid>
			</Grid>
			{(applicants?.items || []).length > 0 && (
				<Grid item xs={12}>
					<Divider />
				</Grid>
			)}
			{!applicants?.pageInfo.pageCount ? null : (
				<Grid sx={{ padding: '10px' }} container justifyContent="center">
					<Pagination
						page={page}
						onChange={handlePageChange}
						count={applicants.pageInfo.pageCount}
					/>
				</Grid>
			)}
		</Grid>
	)
}

export default TalentSearch
