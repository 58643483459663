import React from 'react'

import { VideoModal, VideoModalProps } from '../../VideoModal'

export type TourVideoModalProps = Omit<VideoModalProps, 'content'>

export const TourVideoModal: React.FC<TourVideoModalProps> = props => (
	<VideoModal
		{...props}
		content={
			<iframe
				allowFullScreen
				width="940"
				height="528"
				src="https://www.youtube.com/embed/QDYI2bklqS8"
				title="YouTube video player"
				frameBorder="0"
				allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			/>
		}
	/>
)
