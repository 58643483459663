import { GroupChapters, TalentState, PinnedUsers } from './talentContextReducer'

/** ActionTypes */
export enum TalentActionTypes {
	SET_SELECTED_TALENT = '@@theme/SET_SELECTED_TALENT',
	SET_GROUP_CHAPTERS = '@@theme/SET_GROUP_CHAPTERS',
	SET_PINNED_USERS = '@@theme/SET_PINNED_USERS',
	REMOVE_PINNED_USER = '@@theme/REMOVE_PINNED_USER',
	REMOVE_USERS_FROM_PIPELINE = '@@theme/REMOVE_USERS_FROM_PIPELINE',
	UNSELECT_TALENT = '@@theme/UNSELECT_TALENT'
}

export type TalentActions =
	| SetSelectedTalent
	| SetGroupChapters
	| SetPinnedUsers
	| RemovePinnedUser
	| UnselectTalent
	| RemoveUsersFromPipeline

/** Types */
interface SetSelectedTalent {
	type: TalentActionTypes.SET_SELECTED_TALENT
	payload: { talent: TalentState['selectedTalent'] }
}

interface UnselectTalent {
	type: TalentActionTypes.UNSELECT_TALENT
	payload: {}
}

interface SetGroupChapters {
	type: TalentActionTypes.SET_GROUP_CHAPTERS
	payload: { chapters: GroupChapters }
}

interface SetPinnedUsers {
	type: TalentActionTypes.SET_PINNED_USERS
	payload: { user: PinnedUsers }
}

interface RemovePinnedUser {
	type: TalentActionTypes.REMOVE_PINNED_USER
	payload: { userId: string }
}

interface RemoveUsersFromPipeline {
	type: TalentActionTypes.REMOVE_USERS_FROM_PIPELINE
	payload: { pipelineId: string }
}

/** Actions */
export const setSelectedTalent = (
	talent: TalentState['selectedTalent']
): SetSelectedTalent => ({
	type: TalentActionTypes.SET_SELECTED_TALENT,
	payload: { talent }
})

export const unselectTalent = (): UnselectTalent => ({
	type: TalentActionTypes.UNSELECT_TALENT,
	payload: {}
})

export const setGroupChapters = (chapters: GroupChapters): SetGroupChapters => ({
	type: TalentActionTypes.SET_GROUP_CHAPTERS,
	payload: { chapters }
})

export const setPinnedUsers = (user: PinnedUsers): SetPinnedUsers => ({
	type: TalentActionTypes.SET_PINNED_USERS,
	payload: { user }
})

export const removePinnedUsers = (userId: string): RemovePinnedUser => ({
	type: TalentActionTypes.REMOVE_PINNED_USER,
	payload: { userId }
})

export const removeUsersFromPipeline = (pipelineId: string): RemoveUsersFromPipeline => ({
	type: TalentActionTypes.REMOVE_USERS_FROM_PIPELINE,
	payload: { pipelineId }
})
