import React, { createContext } from 'react'
import { ToggleButtonItem } from './ToggleButtonItem'

export interface ToggleButtonProps {
	value: string | string[]
	onChange: (newValue: string | string[]) => void
}

export const ToggleButtonContext = createContext<ToggleButtonProps>({
	value: '',
	// eslint-disable-next-line @typescript-eslint/no-empty-function
	onChange: () => {}
})

export const ToggleButton: React.FC<ToggleButtonProps> & {
	Item: typeof ToggleButtonItem
} = ({ children, value, onChange }) => (
	<ToggleButtonContext.Provider
		value={{
			value,
			onChange
		}}
	>
		{children}
	</ToggleButtonContext.Provider>
)

ToggleButton.Item = ToggleButtonItem
