import React, { useContext, useEffect, useMemo, useState } from 'react'
import { isString } from 'utils/string'
import { FilterContext } from './FilterProvider'

export interface Option {
	label: string
	value: any
}

export interface FilterArgs {
	label: string
	/**
	 * @default 'string'
	 */
	kind?: 'string' | 'array'
	options: (string | Option)[]
}

export type FilterOptions = Omit<FilterArgs, 'options'> & {
	options: Option[]
}

export interface UseSearchFilterOptions {
	/**
	 * @default false
	 */
	isToGraphql: boolean
}

/**
 *
 * @param args
 * @param deps
 */
export const useSearchFilter = <T extends string>(
	args: Record<T, FilterArgs>,
	// @ts-ignore
	options?: UseSearchFilterOptions = { isToGraphql: false },
	deps?: React.DependencyList = []
) => {
	const [baseFilter, setBaseFilter] = useState(args)
	const { setFilters, filterValues, clearFilters, onClick } = useContext(FilterContext)
	const filter: Record<T, FilterOptions> = useMemo(
		() =>
			Object.fromEntries(
				// @ts-ignore
				Object.entries(baseFilter).map(([key, value]: [string, FilterArgs]) => [
					key,
					{
						...value,
						options: value.options.map(option =>
							isString(option) ? { label: option, value: option } : option
						)
					}
				])
			) as Record<T, FilterOptions>,
		// eslint-disable-next-line
		[baseFilter]
	)
	const items = useMemo(
		() =>
			// @ts-ignore
			Object.entries(filter).map(([key, value]: [string, FilterOptions]) => ({
				key,
				label: value.label,
				count: filterValues[key]?.length ?? 0
			})),
		// eslint-disable-next-line

		[filter, filterValues]
	)
	// @ts-ignore
	const checkoutFilter: Record<T, string[]> = useMemo(
		() =>
			options.isToGraphql
				? Object.entries(filterValues)
						// @ts-ignore
						.map(([key, value]: [T, string[]]) =>
							value.length > 0
								? baseFilter[key]?.kind === 'array'
									? { _operators: { [key]: { in: value } } }
									: { OR: value.map(val => ({ [key]: val })) }
								: null
						)
						.filter(Boolean)
				: filterValues,
		// eslint-disable-next-line
		[baseFilter, filterValues, options.isToGraphql]
	)
	useEffect(() => {
		setBaseFilter(args)
		// eslint-disable-next-line
	}, deps)
	useEffect(() => {
		setFilters(filter)
		// eslint-disable-next-line
	}, [JSON.stringify(filter)])
	return { checkoutFilter, items, onClick, clearFilters }
}

export default useSearchFilter
