import { ApolloClient, InMemoryCache, from } from '@apollo/client'
import { onError } from '@apollo/client/link/error'
// import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from '@apollo/client/link/context'
import { createUploadLink } from 'apollo-upload-client'
// import { WebSocketLink } from '@apollo/client/link/ws'
import iziToast from 'izitoast'
import { API_URL } from './config'

const authLink = setContext((_, { headers }) => {
	const persistedState = localStorage.getItem('storeon')
	let token
	if (persistedState) {
		token = JSON.parse(persistedState).token
	}
	return {
		headers: {
			...headers,
			authorization: token || undefined
		}
	}
})

const errorHandling = onError(({ graphQLErrors, networkError }) => {
	if (graphQLErrors) {
		graphQLErrors.forEach(({ message, extensions }) => {
			// message
			if (
				extensions?.code !== 'INPUT_VALIDATION' ||
				(extensions?.code === 'INPUT_VALIDATION' &&
					extensions?.exception?.details?.showToast)
			) {
				iziToast.error({
					message
				})
			}
			// console.log(
			// 	`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
			// )
		})
	}
	if (networkError) {
		iziToast.error({
			message: networkError.message
		})
		// console.log(`[Network error]: ${networkError}`)
	}
})

// const httpLink = new HttpLink({ uri: API_URL })

const uploadLink = createUploadLink({
	uri: API_URL
})

// const wsLink = new WebSocketLink({
// 	uri: API_URL.replace('http', 'ws'),
// 	options: {
// 		reconnect: true
// 	}
// })

// const splitLink = split(
// 	({ query }) => {
// 		const definition = getMainDefinition(query)
// 		return (
// 			definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
// 		)
// 	},
// 	wsLink,
// 	httpLink
// )

const link = from([errorHandling, authLink, uploadLink])

export const client = new ApolloClient({
	link,
	cache: new InMemoryCache()
})
