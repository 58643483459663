import create from 'zustand'

type State = {
	showHelpGuide: boolean
	currentStep: number
}

type Methods = {
	toggleHelpGuide: () => void
	prevStep: () => void
	nextStep: () => void
}

type Store = State & Methods

const initialState: State = {
	currentStep: 0,
	showHelpGuide: false
}

export const useOnboardingStore = create<Store>(set => ({
	...initialState,
	prevStep: () => set(state => ({ ...state, currentStep: state.currentStep - 1 })),
	nextStep: () => set(state => ({ ...state, currentStep: state.currentStep + 1 })),
	toggleHelpGuide: () => set(state => ({ ...state, showHelpGuide: !state.showHelpGuide }))
}))

export const resetOnboardingStore = () => {
	useOnboardingStore.setState(initialState)
}
