import styled from 'styled-components'
import { Grid } from '@material-ui/core'

export const DrawerHeader = styled(Grid).withConfig<{ open: boolean }>({
	shouldForwardProp: prop => !['open'].includes(prop)
})`
	width: 60px;
	height: 1000px;
	padding: 10px 0;
	border-top-right-radius: ${props => (!props.open ? '10px' : '0')};
	border-bottom-right-radius: ${props => (!props.open ? '10px' : '0')};
	color: ${props => props.theme.tag.white};
	background-color: ${props => props.theme.primary.main};
	box-shadow: ${props => props.theme.shadow.first};
	cursor: pointer;
`
export const DrawerContainer = styled(Grid).withConfig<{ open: boolean }>({
	shouldForwardProp: prop => !['open'].includes(prop)
})`
	position: absolute;
	width: auto;
	top: 0;
	right: 0;
`

export const DrawerContent = styled(Grid).withConfig<{ open: boolean }>({
	shouldForwardProp: prop => !['open'].includes(prop)
})`
	display: flex;
	align-items: flex-start;
	padding: 20px 50px;
	height: 100%;
	background-color: ${props => props.theme.background.light};
`
