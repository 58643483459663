import React from 'react'
import { useTheme } from 'styled-components'
import { Grid, Typography } from '@material-ui/core'
import { ProgressBarContainer, ProgressBarContent, Mark } from './LinearProgress.styles'

export interface LinearProgressProps {
	/**
	 * Defines the value in percentage (max 100)
	 */
	value: number
	/**
	 * A mark that will display on the progress bar
	 * If true, a blue line will appear
	 */
	mark?: number
	/**
	 * It will display the current progress percentage in the left or right side of the progress
	 */
	labeled?: 'left' | 'right'
	/**
	 * Overrides the default color of the progress
	 */
	color?: string
	/**
	 * If true, Replaces the background color of the component to transparent
	 * @default false
	 */
	noBg?: boolean
	/**
	 * If false, show % aside of value
	 * @default false
	 */
	noPercentage?: boolean
}

const getPercentage = (value: number): number => {
	if (value > 100) return 100
	if (value < 0) return 0
	return value
}

export const LinearProgress: React.FC<LinearProgressProps> = ({
	value,
	labeled,
	color,
	noBg = false,
	noPercentage = false,
	mark = 0
}) => {
	const theme = useTheme()
	const label = (
		<Grid item sx={{ width: '40px' }}>
			<Typography
				variant="paragraph2"
				sx={{ width: '40px', color: theme.text.description }}
			>
				{value}
				{!noPercentage && '%'}
			</Typography>
		</Grid>
	)
	return (
		<Grid container spacing={2} alignItems="center">
			{labeled === 'left' && label}
			<Grid item xs>
				<ProgressBarContainer noBg={noBg}>
					<ProgressBarContent value={getPercentage(value)} color={color} />
					{mark > 0 && <Mark value={mark} />}
				</ProgressBarContainer>
			</Grid>
			{labeled === 'right' && label}
		</Grid>
	)
}

export default LinearProgress
