import React, { useRef, useState } from 'react'
import { useLocation, Link } from 'react-router-dom'
import {
	AppBar,
	Avatar,
	// Badge,
	IconButton,
	Divider,
	Link as MuiLink,
	Menu,
	MenuItem,
	Hidden,
	Typography,
	Grid,
	SwipeableDrawer
} from '@material-ui/core'
import {
	Brightness3 as Brightness3Icon,
	Menu as MenuIcon,
	// NotificationsOutlined as NotificationsOutlinedIcon,
	ExpandMore as ExpandMoreIcon,
	HelpOutline as HelpIcon
} from '@material-ui/icons'
import TextToolTip from 'components/TextToolTip'
import { useTheme } from 'styled-components'
import { useStoreon } from 'storeon/react'
import useIntl from 'hooks/useIntl'
import { useToggleAnchor } from 'hooks/useToggleAnchor'
import DeleteMenuItem from 'components/DeleteMenuItem'
import Logo from 'components/Logo'
import { TourVideoModal } from 'components/VideoModal'
import useToggle from 'hooks/useToggle'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import * as S from './Header.style'

interface Props {
	firstName?: string
	lastName?: string | null
	photoUrl?: string | null
	permission?: string
	isDark?: boolean
	toggleTheme(): void
	signOut(): void
	openDrawer(): void
}

export const Header = ({
	firstName = 'Unnamed',
	lastName = '',
	photoUrl,
	permission,
	isDark,
	toggleTheme,
	signOut
}: Props) => {
	const { user } = useStoreon('user')
	const companyLogo = user?.org?.photoUrl
	const companyName = user?.org?.name

	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const [
		{ anchorEl: guideMenuAnchorEl },
		{
			handleOpenAnchor: handleGuideMenuOpenAnchor,
			handleCloseAnchor: handleGuideMenuCloseAnchor
		}
	] = useToggleAnchor()
	const [
		{ open: openDrawer },
		{ handleOpen: handleOpenDrawer, handleClose: handleCloseDrawer }
	] = useToggle()

	const intl = useIntl()
	const buttonRef = useRef<HTMLButtonElement>(null)
	const theme = useTheme()
	const { pathname } = useLocation()

	const [videoDemoModalOpen, setVideoDemoModalOpen] = useState(false)

	const handleVideoDemoToggle = () => {
		setVideoDemoModalOpen(prev => !prev)
	}

	const profileWidth =
		buttonRef?.current !== null ? window.getComputedStyle(buttonRef.current).width : '180px'
	return (
		<>
			{/* TODO: add this in theme */}
			<AppBar sx={{ borderBottomColor: theme.border }}>
				<S.Container>
					<S.Content>
						<Link to="/" style={{ lineHeight: 0.6 }}>
							{companyLogo ? (
								<img src={companyLogo} alt={companyName || ''} height={45} />
							) : (
								<Logo size="large" width={116} />
							)}
						</Link>
						<Hidden mdDown>
							<S.RightContent>
								<Grid container alignItems="center" spacing={2}>
									<Hidden mdDown>
										<Grid item>
											<S.Link
												{...(pathname === '/' && {
													style: {
														color: theme.primary.main
													}
												})}
												to="/"
											>
												{intl.formatMessage({ id: 'dashboard' })}
											</S.Link>
										</Grid>
									</Hidden>
									<Grid item>
										{user?.isTourDone && (
											<>
												<IconButton
													aria-label="guide"
													onClick={handleGuideMenuOpenAnchor}
													name="guide"
												>
													<HelpIcon />
												</IconButton>
												<Menu
													id="guide-menu"
													keepMounted
													anchorEl={guideMenuAnchorEl}
													open={Boolean(guideMenuAnchorEl)}
													onClose={handleGuideMenuCloseAnchor}
													MenuListProps={{
														disablePadding: true
													}}
													getContentAnchorEl={null}
													anchorOrigin={{
														vertical: 'bottom',
														horizontal: 'center'
													}}
													transformOrigin={{
														vertical: -14,
														horizontal: 'center'
													}}
												>
													{/* <MenuItem
													onClick={() => {
														handleGuideMenuCloseAnchor()
														handleVideoDemoToggle()
													}}
												>
													{intl.formatMessage({ id: 'tour.seeVideoDemo' })}
												</MenuItem> */}
													<Link to="/teams/demo">
														<MenuItem
															onClick={handleGuideMenuCloseAnchor}
															sx={{
																color:
																	pathname === '/teams/demo'
																		? 'primary.main'
																		: 'text.primary'
															}}
														>
															{intl.formatMessage({ id: 'tour.viewDemoData' })}
														</MenuItem>
													</Link>
													<MuiLink
														href="https://www.nugget.ai/science"
														rel="noopener noreferrer"
														target="_blank"
														color="inherit"
														underline="none"
													>
														<MenuItem onClick={handleGuideMenuCloseAnchor}>
															{intl.formatMessage({ id: 'tour.learnAboutOurScience' })}
														</MenuItem>
													</MuiLink>
												</Menu>
											</>
										)}
										<TextToolTip title={intl.formatMessage({ id: 'switchTheme' })}>
											<IconButton
												name="theme"
												aria-label="theme"
												color={isDark ? 'primary' : 'default'}
												onClick={toggleTheme}
											>
												<Brightness3Icon style={{ fontSize: pxToRem(20) }} fill="inherit" />
											</IconButton>
										</TextToolTip>
										{/* <IconButton>
										<Badge badgeContent={3}>
											<NotificationsOutlinedIcon />
										</Badge>
									</IconButton> */}
									</Grid>
								</Grid>
								<S.Profile
									ref={buttonRef}
									onClick={handleOpenAnchor}
									aria-controls="account-menu"
									aria-haspopup="true"
									aria-label="profile"
									name="profile"
								>
									<Avatar src={photoUrl ?? undefined} alt={`${firstName} ${lastName}`}>
										{`${firstName.charAt(0)}${lastName?.charAt(0)}`.toUpperCase()}
									</Avatar>
									<S.Name>
										<Typography
											variant="paragraph1"
											fontWeight={500}
											title={`${firstName} ${lastName}`}
											sx={{ marginRight: 1 }}
										>
											{firstName}
										</Typography>
										<p>{permission}</p>
										<ExpandMoreIcon
											sx={{ mt: 0.5, fontSize: 16, fill: theme.text.description }}
										/>
									</S.Name>
								</S.Profile>
								<Menu
									id="account-menu"
									anchorEl={anchorEl}
									keepMounted
									open={Boolean(anchorEl)}
									onClose={handleCloseAnchor}
									PaperProps={{
										style: {
											minWidth: profileWidth
										}
									}}
									MenuListProps={{
										disablePadding: true
									}}
									getContentAnchorEl={null}
									anchorOrigin={{
										vertical: 'bottom',
										horizontal: 'center'
									}}
									transformOrigin={{
										vertical: 'top',
										horizontal: 'center'
									}}
								>
									<Link to="/settings/account" style={{ color: theme.text.main }}>
										<MenuItem onClick={handleCloseAnchor}>
											{intl.formatMessage({ id: 'settings' })}
										</MenuItem>
									</Link>
									<Divider style={{ marginLeft: 5, marginRight: 5 }} />
									<DeleteMenuItem
										onClick={() => {
											signOut()
											handleCloseAnchor()
										}}
									>
										{intl.formatMessage({ id: 'header.logOut' })}
									</DeleteMenuItem>
								</Menu>
							</S.RightContent>
						</Hidden>
					</S.Content>
					<Hidden mdUp>
						<Grid container spacing={2} alignItems="center" justifyContent="flex-end">
							<Grid item>
								<IconButton
									color={isDark ? 'primary' : 'default'}
									onClick={toggleTheme}
									name="changeTheme"
									aria-label="changeTheme"
								>
									<Brightness3Icon fill="inherit" />
								</IconButton>
							</Grid>
							<Grid item>
								<IconButton
									name="menuMobile"
									aria-label="menuMobile"
									color="secondary"
									edge="start"
									onClick={handleOpenDrawer}
									sx={{ padding: 0 }}
								>
									<MenuIcon />
								</IconButton>
							</Grid>
						</Grid>
						<SwipeableDrawer
							open={openDrawer}
							onClose={handleCloseDrawer}
							onOpen={handleOpenDrawer}
							anchor="right"
						>
							<Grid container spacing={1}>
								<Grid container item sx={{ marginY: 3 }} spacing={1}>
									<Grid container item xs={12} justifyContent="center" alignItems="center">
										<Avatar src={photoUrl ?? undefined} sx={{ width: 59, height: 59 }}>
											{firstName.charAt(0).toUpperCase()}
										</Avatar>
									</Grid>
									<Grid item xs={12}>
										<S.Name>
											<Typography
												sx={{ paddingX: 2 }}
												variant="h5"
												title={`${firstName} ${lastName}`}
											>
												{firstName}
											</Typography>
											<p>{permission}</p>
										</S.Name>
									</Grid>
								</Grid>
								<Grid item xs={12}>
									<Divider />
								</Grid>
								<Grid item xs={12}>
									<S.Link
										{...(pathname === '/' && {
											style: {
												color: theme.primary.main
											}
										})}
										to="/"
									>
										<MenuItem onClick={handleCloseDrawer}>
											{intl.formatMessage({ id: 'dashboard' })}
										</MenuItem>
									</S.Link>
									{/* <Link to="/faq" style={{ color: theme.text.main }}>
									<MenuItem onClick={handleCloseDrawer}>
										{intl.formatMessage({ id: 'faq' })}
									</MenuItem>
								</Link> */}
									<Link to="/settings/account" style={{ color: theme.text.main }}>
										<MenuItem onClick={handleCloseDrawer}>
											{intl.formatMessage({ id: 'settings' })}
										</MenuItem>
									</Link>
									<DeleteMenuItem
										onClick={() => {
											signOut()
											handleCloseAnchor()
										}}
									>
										{intl.formatMessage({ id: 'header.logOut' })}
									</DeleteMenuItem>
								</Grid>
								{user?.isTourDone && (
									<>
										<Grid item xs={12}>
											<Divider />
										</Grid>
										<Grid item xs={12}>
											{/* <MenuItem
												onClick={() => {
													handleCloseDrawer()
													handleVideoDemoToggle()
												}}
											>
												{intl.formatMessage({ id: 'tour.seeVideoDemo' })}
											</MenuItem> */}
											<Link to="/teams/demo">
												<MenuItem
													onClick={handleCloseDrawer}
													sx={{
														color:
															pathname === '/teams/demo' ? 'primary.main' : 'text.primary'
													}}
												>
													{intl.formatMessage({ id: 'tour.viewDemoData' })}
												</MenuItem>
											</Link>
											<MuiLink
												href="https://www.nugget.ai/science"
												target="_blank"
												color="inherit"
												underline="none"
											>
												<MenuItem onClick={handleCloseDrawer}>
													{intl.formatMessage({ id: 'tour.learnAboutOurScience' })}
												</MenuItem>
											</MuiLink>
										</Grid>
									</>
								)}
							</Grid>
						</SwipeableDrawer>
					</Hidden>
				</S.Container>
			</AppBar>
			<TourVideoModal open={videoDemoModalOpen} onClose={handleVideoDemoToggle} />
		</>
	)
}

export default Header
