import React, { useMemo } from 'react'
import { isElement } from 'react-is'
import { Dot, RequirementContainer } from './Requirement.style'

interface Props {
	disabled?: boolean
	pattern: RegExp
	value: string
}

export type { Props as RequirementProps }

export const Requirement: React.FC<Props> = ({ disabled, children, pattern, value }) => {
	const isValid = useMemo(() => pattern.test(value || ''), [pattern, value])
	return (
		<RequirementContainer disabled={isValid || disabled}>
			<div style={{ width: 8, marginRight: 5 }}>
				<Dot disabled={isValid || disabled} />
			</div>
			{isElement(children) ? children : <span>{children}</span>}
		</RequirementContainer>
	)
}

export default Requirement
