import React from 'react'
import { PartialRouteObject } from 'react-router'
import AuthLayout from 'layouts/auth/AuthLayout'
import RouteHandler from 'containers/RouteHandler'
import VerifyEmailRouteHandler from 'containers/VerifyEmailRouteHandler'
import VerifyTokenRouteHandler from 'containers/VerifyTokenRouteHandler'
import { SignUp } from 'screens/SignUp'

export const mainRoutes: PartialRouteObject[] = [
	{
		path: 'auth/signup/*',
		element: <SignUp />
	},
	{
		path: 'auth/*',
		element: <AuthLayout />
	},
	{
		path: 'auth/verify-token/:token',
		element: <VerifyTokenRouteHandler />
	},
	{
		path: 'verify-user/:id/:token',
		element: <VerifyEmailRouteHandler />
	},
	{
		path: '*',
		element: <RouteHandler />
	}
]
