import React from 'react'
import { useMediaQuery, Theme } from '@material-ui/core'
import useId from '@material-ui/core/utils/useId'
import useToggleAnchor from 'hooks/useToggleAnchor'
import SpeechBubble from 'components/SpeechBubble'

export interface WithSpeechBubbleProps {
	show?: boolean
	text: string
	btnLabel?: string
	onClick?: () => void
}

export const WithSpeechBubble: React.FC<WithSpeechBubbleProps> = ({
	show,
	text,
	btnLabel,
	onClick,
	children
}) => {
	const id = useId()
	const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	return (
		<div
			onMouseOver={show && upSm ? handleOpenAnchor : undefined}
			onMouseLeave={show && upSm ? handleCloseAnchor : undefined}
			style={{ width: '100%' }}
		>
			<SpeechBubble
				id={id}
				anchorEl={anchorEl}
				text={text}
				btnLabel={btnLabel}
				onClick={onClick}
			/>
			{children}
		</div>
	)
}

export default WithSpeechBubble
