import React, { useEffect, useState } from 'react'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { Add as PlusIcon, Close as CloseIcon } from '@material-ui/icons'
import { Link, useNavigate } from 'react-router-dom'

import { FileIcon } from 'assets/icons/FileIcon'
// import { PlayIcon } from 'assets/icons/PlayIcon'
import { ScienceIcon } from 'assets/icons/ScienceIcon'
import { NaomiHappyIcon } from 'assets/icons/NyomiIcons/NaomiHappyIcon'
import { ReactComponent as RocketShip } from 'assets/illustrations/RocketShip.svg'
import { ReactComponent as Guide } from 'assets/illustrations/Guide.svg'
import { TourVideoModal } from 'components/VideoModal'
import { NyomiModal } from 'components/NyomiModal'
import { NyomiTalkBubble } from 'components/NyomiTalkBubble'
import { useAccountStore } from 'hooks/useAccountStore'
import { useUpdateUserTourStatusMutation } from 'hooks/useApollo'
import { useIntl } from 'hooks/useIntl'
import { Modal } from 'components/Modal'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import { useStoreon } from 'storeon/react'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import * as S from './FirstAccess.style'

export const FirstAccessDashboard = () => {
	const intl = useIntl()
	const { dispatch, user } = useStoreon('user')
	const { access, setFirstAccess } = useAccountStore(state => ({
		access: state.firstAccess,
		setFirstAccess: state.setFirstAccess
	}))
	const [tourClosingModalOpen, setTourClosingModalOpen] = useState(false)
	const [videoDemoModalOpen, setVideoDemoModalOpen] = useState(false)
	const [{ trialExpiredWithoutSubscription }] = useUserSubscriptions()
	const [updateUserTourStatus] = useUpdateUserTourStatusMutation({
		onCompleted: () => {
			setTourClosingModalOpen(true)

			if (user) {
				dispatch('user/setUser', { ...user, isTourDone: true })
			}
		},
		optimisticResponse: {
			updateUserTourStatus: true
		}
	})
	const [modalOpen, setModalOpen] = useState(access)
	const navigate = useNavigate()

	// eslint-disable-next-line arrow-body-style
	useEffect(() => {
		return () => {
			setFirstAccess(false)
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleTourCloseButtonClick = () => {
		updateUserTourStatus({
			variables: {
				status: true
			}
		})
	}

	const isTourDone = !!user?.isTourDone

	return (
		<>
			<Grid container spacing={8}>
				<Grid item xs={12} md={!isTourDone ? 6 : undefined}>
					<S.BoxContainerWelcome>
						<Grid
							container
							direction="column"
							justifyContent="center"
							alignItems="center"
							spacing={4}
						>
							<Grid item>
								<RocketShip width="80" height="80" />
							</Grid>
							<Grid item>
								<Typography variant="h3">
									{intl.formatMessage({ id: 'dashboard.welcome.box.title' })}
								</Typography>
							</Grid>
							<Grid item>
								<Typography textAlign="center">
									{intl.formatMessage({ id: 'dashboard.welcome.box.subText' })}
								</Typography>
							</Grid>
							<Grid item>
								<Button
									name="emailVerified"
									variant="contained"
									size="small"
									disabled={!user?.isEmailVerified || trialExpiredWithoutSubscription}
									startIcon={<PlusIcon style={{ fontSize: pxToRem(20) }} />}
									onClick={() => navigate('/onboarding/challenges-selection')}
								>
									{intl.formatMessage({ id: 'dashboard.welcome.box.buttonText' })}
								</Button>
							</Grid>
						</Grid>
					</S.BoxContainerWelcome>
				</Grid>
				{!isTourDone && (
					<Grid item xs={12} md={6}>
						<S.TourContainer>
							<S.TourCloseButton>
								<IconButton onClick={handleTourCloseButtonClick}>
									<CloseIcon />
								</IconButton>
							</S.TourCloseButton>
							<Grid
								container
								direction="column"
								justifyContent="center"
								alignItems="center"
								spacing={4}
							>
								<Grid item mt={2.5}>
									<NyomiTalkBubble
										inline
										message={intl.formatMessage({ id: 'tour.naomi' })}
									/>
								</Grid>
								<Grid item>
									<Typography variant="h3">
										{intl.formatMessage({ id: 'tour.title' })}
									</Typography>
								</Grid>
								<Grid item>
									<Typography textAlign="center">
										{intl.formatMessage({ id: 'tour.description' })}
									</Typography>
								</Grid>
								<Grid item container spacing={3} justifyContent="center">
									{/* <Grid item>
										<S.TourButton
											startIcon={<PlayIcon />}
											onClick={() => setVideoDemoModalOpen(true)}
										>
											{intl.formatMessage({ id: 'tour.seeVideoDemo' })}
										</S.TourButton>
									</Grid> */}
									<Grid item>
										<Link to="/teams/demo">
											<S.TourButton size="small" startIcon={<FileIcon />}>
												{intl.formatMessage({ id: 'tour.viewDemoData' })}
											</S.TourButton>
										</Link>
									</Grid>
									<Grid item>
										<S.TourButton
											size="small"
											startIcon={<ScienceIcon />}
											href="https://www.nugget.ai/science"
											// @ts-ignore
											target="_blank"
											rel="noopener noreferrer"
										>
											{intl.formatMessage({ id: 'tour.learnAboutOurScience' })}
										</S.TourButton>
									</Grid>
								</Grid>
							</Grid>
						</S.TourContainer>
					</Grid>
				)}
			</Grid>
			<TourVideoModal
				open={videoDemoModalOpen}
				onClose={() => setVideoDemoModalOpen(false)}
			/>
			<NyomiModal
				open={modalOpen}
				onClose={() => setModalOpen(false)}
				title={intl.formatMessage({ id: 'dashboard.welcome.firstTime.welcome' })}
				desc={intl.formatMessage({
					id: 'dashboard.welcome.firstTime.welcomeMessage'
				})}
				nyomiIcon={NaomiHappyIcon}
				btnLabel={intl.formatMessage({ id: 'dashboard.welcome.firstTime.seeDashboard' })}
			/>
			<Modal
				showCloseButton
				open={tourClosingModalOpen}
				onClose={() => setTourClosingModalOpen(false)}
				footer={false}
			>
				<Grid container direction="column" alignItems="center" spacing={3}>
					<Grid item>
						<Guide />
					</Grid>
					<Grid item>
						<Typography variant="h4" textAlign="center">
							{intl.formatMessage({ id: 'tour.closingMessage' })}
						</Typography>
					</Grid>
				</Grid>
			</Modal>
		</>
	)
}

export default FirstAccessDashboard
