import create from 'zustand'

import { IndustryBenchmark, Category, Chapter } from 'hooks/useApollo'

interface Benchmark {
	score: number
	top5: boolean
}

type State = {
	categories: Category[]
	chapters: Chapter[]
	chapterList: Chapter[]
	topChapters: Chapter[]
	moreChapters: Chapter[]
	selectedChapters: Chapter[]
	benchmarks: Record<string, Benchmark>
	chapterMappingById: Record<string, Chapter>
}

type Methods = {
	setCategories: (categories: Category[]) => void
	setChapters: (chapters: Chapter[], benchmarks: IndustryBenchmark[]) => void
	setSelectedChapters: (chapters: Chapter[]) => void
	showMoreChapters: () => void
	showLessChapters: () => void
}

type Store = State & Methods

const initialState: State = {
	categories: [],
	benchmarks: {},
	chapters: [],
	chapterList: [],
	topChapters: [],
	moreChapters: [],
	selectedChapters: [],
	chapterMappingById: {}
}

export const useRoleBasedChallengesStore = create<Store>(set => ({
	...initialState,
	setCategories: (categories: Category[]) => set(state => ({ ...state, categories })),
	setChapters: async (chapters: Chapter[], data: IndustryBenchmark[]) => {
		const topChapters: Chapter[] = []
		const moreChapters: Chapter[] = []

		const benchmarks = data.reduce<Record<string, Benchmark>>((res, item) => {
			// @ts-ignore
			res[item.id] = {
				score: item.score || 0,
				top5: item.top5 || false
			}
			return res
		}, {})

		chapters
			// @ts-ignore
			.sort((a, b) => (a.name < b.name ? -1 : 1))
			.forEach(chapter => {
				if (benchmarks[chapter.id]?.top5) {
					topChapters.push(chapter)
				} else {
					moreChapters.push(chapter)
				}
			})

		return set(state => {
			return {
				...state,
				chapters,
				topChapters,
				chapterList: topChapters,
				moreChapters,
				benchmarks
			}
		})
	},
	setSelectedChapters: (selectedChapters: Chapter[]) =>
		set(state => ({
			...state,
			selectedChapters,
			chapterMappingById: selectedChapters.reduce<Record<string, Chapter>>(
				(currentMap, chapter) => {
					currentMap[chapter.id] = chapter
					return currentMap
				},
				{}
			)
		})),
	showMoreChapters: () =>
		set(state => ({
			...state,
			chapterList: state.chapterList.concat(state.moreChapters)
		})),
	showLessChapters: () =>
		set(state => ({
			...state,
			chapterList: state.topChapters
		}))
}))

export const resetRoleBasedChallengesStore = () => {
	useRoleBasedChallengesStore.setState(initialState)
}
