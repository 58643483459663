import React, { useEffect, useMemo } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useTheme } from 'styled-components'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useIntl from 'hooks/useIntl'
import { Wizard, WizardProps } from 'components/Wizard'
import {
	useOnboardingStore,
	resetOnboardingStore
} from 'screens/Onboarding/hooks/useOnboardingStore'
import HelpGuideDrawer, { HelpGuideDrawerProps } from 'components/HelpGuideDrawer'

import RoleSelection from './containers/RoleSelection'
import { RoleBasedChallengesForm } from './RoleBasedChallenges.types'
import { resetRoleBasedChallengesStore } from './hooks/useRoleBasedChallengesStore'
import RoleSkillsSelection from './containers/RoleSkillsSelection'
import RoleBasedChallengePreview from './containers/RoleBasedChallengePreview/RoleBasedChallengePreview'
import RoleBasedTeamCreation from './containers/RoleBasedTeamCreation'

const formSchema = yup.object().shape({
	team: yup.object().shape({
		name: yup.string().required(),
		maskChallenge: yup.bool()
	}),
	talentGroup: yup.object().shape({
		name: yup.string().required(),
		type: yup.string().required(),
		color: yup.string().required(),
		teammateEmails: yup.array().of(yup.string().email())
	})
})

export const RoleBasedChallenges = () => {
	const intl = useIntl()
	const theme = useTheme()
	const { currentStep, showHelpGuide, toggleHelpGuide } = useOnboardingStore(state => state)

	useEffect(
		() => () => {
			resetOnboardingStore()
			resetRoleBasedChallengesStore()
		},
		[]
	)

	const formMethods = useForm<RoleBasedChallengesForm>({
		mode: 'all',
		shouldUnregister: false,
		resolver: yupResolver(formSchema),
		defaultValues: {
			challengePack: 'role-based',
			category: {
				id: '',
				name: ''
			},
			chapters: [],
			team: {
				name: '',
				maskChallenge: false
			},
			talentGroup: {
				name: '',
				type: '',
				color: theme.tag.blue,
				teammateEmails: []
			}
		}
	})

	const steps: WizardProps['stepperLabels'] = useMemo(
		() => [
			{
				label: intl.formatMessage({ id: 'onboarding.role.based.challenges.step.0' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.role.based.challenges.step.1' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.role.based.challenges.step.2' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.role.based.challenges.step.3' })
			}
		],
		[intl]
	)

	const guides: HelpGuideDrawerProps['guides'] = useMemo(
		() =>
			Array.from({ length: 4 }, (_, index) => ({
				title: intl.formatMessage({
					id: `onboarding.role.based.challenges.help.guide.title.step.${index}`
				}),
				desc: intl.formatMessage({
					id: `onboarding.role.based.challenges.help.guide.desc.step.${index}`
				})
			})),
		[intl]
	)

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return <RoleSelection />
			case 1:
				return <RoleSkillsSelection />
			case 2:
				return <RoleBasedChallengePreview />
			case 3:
				return <RoleBasedTeamCreation />
			default:
				return null
		}
	}

	return (
		<>
			<Wizard
				levelContentWithStepper
				currentStep={currentStep}
				previousPath="/"
				previousPageName={intl.formatMessage({ id: 'dashboard' }).toLowerCase()}
				stepperLabels={steps}
				showMobileStepIndicator={false}
			>
				<FormProvider {...formMethods}>{renderStep()}</FormProvider>
			</Wizard>
			<HelpGuideDrawer
				open={showHelpGuide}
				stepCount={currentStep}
				onClose={toggleHelpGuide}
				guides={guides}
			/>
		</>
	)
}

export default RoleBasedChallenges
