import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const EmailOutlinedIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="16" height="12" viewBox="0 0 16 12" fill="none">
		<path d="M14.4 11.9993H1.6C0.744413 12.0233 0.0291331 11.3944 0 10.5925V1.40678C0.0291305 0.604975 0.744546 -0.0237373 1.6 0.000688255H14.4C15.2555 -0.0237373 15.9709 0.604975 16 1.40678V10.5932C15.9704 11.3949 15.2553 12.0233 14.4 11.9993ZM1.6 1.50052V10.4913L14.4 10.4995V1.50877L1.6 1.50052ZM9.144 8.99967H3.2C3.25882 8.37879 3.57227 7.8035 4.0744 7.39485C4.63346 6.87495 5.38465 6.57685 6.172 6.56245C6.95935 6.57685 7.71054 6.87495 8.2696 7.39485C8.77159 7.80359 9.08502 8.37883 9.144 8.99967ZM12.8 8.24976H10.4V6.74993H12.8V8.24976ZM6.172 6.00001C5.74326 6.01398 5.32758 5.86045 5.02423 5.57609C4.72088 5.29174 4.5571 4.90208 4.572 4.50018C4.55734 4.09835 4.7212 3.70882 5.02449 3.42452C5.32779 3.14021 5.74333 2.98661 6.172 3.00035C6.60067 2.98661 7.01621 3.14021 7.31951 3.42452C7.6228 3.70882 7.78666 4.09835 7.772 4.50018C7.7869 4.90208 7.62312 5.29174 7.31977 5.57609C7.01642 5.86045 6.60074 6.01398 6.172 6.00001ZM12.8 5.2501H9.6V3.75027H12.8V5.2501Z" />
	</SvgIcon>
)

export default EmailOutlinedIcon
