import React from 'react'
import {
	TextField,
	TextFieldProps,
	Grid,
	Checkbox,
	FormControlLabel,
	InputAdornment,
	Typography
} from '@material-ui/core'
import { useTheme as useMaterialTheme } from '@material-ui/core/styles'
import { useTheme } from 'styled-components'
import { useFormContext } from 'react-hook-form'
import { useIntl } from 'hooks/useIntl'
import { createStringRequirements, emailRegex } from 'utils/regexs'
import { Requirement } from 'components/Requirement'
import { PasswordInput } from 'components/PasswordInput'
import { InputCheckIcon } from 'components/InputCheckIcon'

const passwordRegex = createStringRequirements()

const noErrorProps: TextFieldProps['InputProps'] = {
	endAdornment: (
		<InputAdornment position="end">
			<InputCheckIcon />
		</InputAdornment>
	)
}

export const CreateProfile = () => {
	const intl = useIntl()
	const theme = useTheme()
	const materialTheme = useMaterialTheme()
	const { register, errors, watch } = useFormContext()
	const allFields = watch()

	return (
		<Grid item container spacing={4}>
			<Grid item container justifyContent="center">
				<Typography variant="h3" fontWeight={700}>
					{intl.formatMessage({ id: 'auth.createProfile' })}
				</Typography>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					required
					error={!!errors.firstName}
					name="firstName"
					autoComplete="given-name"
					label={intl.formatMessage({ id: 'firstName' })}
					placeholder="ex. john"
					helperText={
						errors.firstName
							? errors.firstName.type === 'maxLength'
								? intl.formatMessage({ id: 'validation.maxLength' }, { length: 100 })
								: errors.firstName.type === 'minLength' &&
								  intl.formatMessage({ id: 'validation.minLength' }, { length: 2 })
							: ''
					}
					inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
					InputProps={allFields.firstName && !errors.firstName ? noErrorProps : {}}
				/>
			</Grid>
			<Grid item xs={12} md={6}>
				<TextField
					required
					error={!!errors.lastName}
					name="lastName"
					autoComplete="family-name"
					label={intl.formatMessage({ id: 'lastName' })}
					placeholder="ex. smith"
					helperText={
						errors.lastName
							? errors.lastName.type === 'maxLength'
								? intl.formatMessage({ id: 'validation.maxLength' }, { length: 100 })
								: errors.lastName.type === 'minLength' &&
								  intl.formatMessage({ id: 'validation.minLength' }, { length: 2 })
							: ''
					}
					inputRef={register({ required: true, minLength: 2, maxLength: 100 })}
					InputProps={allFields.lastName && !errors.lastName ? noErrorProps : {}}
				/>
			</Grid>
			<Grid item xs={12}>
				<TextField
					required
					error={!!errors.email}
					name="email"
					type="email"
					autoComplete="email"
					helperText={errors?.email?.type === 'exists' ? errors?.email?.message : undefined}
					label={intl.formatMessage({ id: 'email' })}
					placeholder="ex. john@gmail.com"
					InputProps={allFields.email && !errors.email ? noErrorProps : {}}
					inputRef={register({
						required: true,
						pattern: emailRegex
					})}
				/>
			</Grid>
			<Grid item container spacing={1}>
				<Grid item xs={12}>
					<PasswordInput
						required
						error={!!errors.password}
						label={intl.formatMessage({ id: 'password' })}
						inputRef={register({
							required: true,
							pattern: passwordRegex
						})}
						isCorrect={allFields.password ? !errors.password : false}
					/>
				</Grid>
				<Grid
					item
					xs={12}
					container
					spacing={1}
					alignItems="center"
					style={{
						marginTop: materialTheme.spacing(2),
						marginBottom: materialTheme.spacing(1)
					}}
				>
					<Grid item xs={6} sm={4}>
						<Requirement pattern={/[a-z]/} value={allFields.password}>
							{intl.formatMessage({ id: 'validation.lowercase' }, { length: 1 })}
						</Requirement>
					</Grid>
					<Grid item xs={6} sm={4}>
						<Requirement pattern={/[0-9]/} value={allFields.password}>
							{intl.formatMessage({ id: 'validation.number' }, { length: 1 })}
						</Requirement>
					</Grid>
					<Grid item xs={6} sm={4}>
						<Requirement pattern={/.{8,}/} value={allFields.password}>
							{intl.formatMessage({ id: 'validation.minLength' }, { length: 8 })}
						</Requirement>
					</Grid>
					<Grid item xs={6} sm={4}>
						<Requirement pattern={/[A-Z]/} value={allFields.password}>
							{intl.formatMessage({ id: 'validation.uppercase' }, { length: 1 })}
						</Requirement>
					</Grid>
					<Grid item xs={6} sm={4}>
						<Requirement pattern={/[#?!@$%^&*-]/} value={allFields.password}>
							{intl.formatMessage({ id: 'validation.special' }, { length: 1 })}
						</Requirement>
					</Grid>
				</Grid>
			</Grid>
			<Grid item>
				<FormControlLabel
					control={
						// eslint-disable-next-line react/jsx-wrap-multilines
						<Checkbox
							required
							inputRef={register({ required: true })}
							name="isTermsAccepted"
						/>
					}
					label={intl.formatMessage(
						{ id: 'auth.acceptTerms' },
						{
							terms: (
								<a
									href="https://nugget.ai/terms-and-conditions"
									target="_blank"
									rel="noopener noreferrer"
								>
									{intl.formatMessage({ id: 'auth.terms' })}
								</a>
							),
							privacy: (
								<a
									href="https://nugget.ai/privacy-policy"
									target="_blank"
									rel="noopener noreferrer"
								>
									{intl.formatMessage({ id: 'auth.privacy' })}
								</a>
							),
							red: str => <span style={{ color: theme.tag.red }}>{str}</span>
						}
					)}
				/>
			</Grid>
		</Grid>
	)
}

export default CreateProfile
