import React from 'react'
import { Typography } from '@material-ui/core'
import TextToolTip from 'components/TextToolTip'
import { Info as InfoIcon } from '@material-ui/icons'
import useIntl from 'hooks/useIntl'
import ButtonLink from 'components/ButtonLink'
import { CreationContentContainer } from './CreationContent.styles'

export interface CreationContentProps {
	title: string
	titleExt?: React.ReactNode
	subTitle?: string
	titleInfoMessage?: string
	desc?: string
	subHeader?: boolean
	titleAsLabel?: boolean
	onLearnMore?: () => void
	containerProps?: React.ComponentPropsWithoutRef<'div'>
}

export const CreationContent: React.FC<CreationContentProps> = ({
	title,
	titleExt,
	subTitle,
	titleInfoMessage,
	desc,
	subHeader = false,
	titleAsLabel = false,
	children,
	containerProps,
	onLearnMore
}) => {
	const intl = useIntl()
	return (
		<CreationContentContainer subHeader {...containerProps}>
			{subTitle && (
				<Typography
					color="secondary"
					style={{
						marginBottom: 12,
						fontSize: 12
					}}
				>
					{subTitle}
				</Typography>
			)}
			<Typography
				color="textPrimary"
				style={{
					marginBottom: !desc ? (titleAsLabel ? 15 : 30) : undefined
				}}
				gutterBottom
				variant={subHeader ? 'h4' : 'h3'}
				fontWeight={subHeader ? 500 : 400}
			>
				{title} {titleExt}
				{titleInfoMessage && (
					<TextToolTip arrow title={titleInfoMessage} placement="top">
						<InfoIcon
							style={{ color: '#616161', marginLeft: 8, verticalAlign: 'middle' }}
							fontSize="small"
						/>
					</TextToolTip>
				)}
			</Typography>
			{desc && (
				<Typography
					color="secondary"
					style={{
						marginTop: 16,
						marginBottom: titleAsLabel ? 10 : subHeader ? 30 : 50
					}}
					variant={subHeader ? 'h5' : 'h4'}
				>
					{desc}{' '}
					{onLearnMore && (
						<ButtonLink onClick={onLearnMore} sx={{ marginBottom: '1.5px' }}>
							{intl.formatMessage({ id: 'teams.onboarding.learnMore' })}
						</ButtonLink>
					)}
				</Typography>
			)}
			{children}
		</CreationContentContainer>
	)
}

export default CreationContent
