import React from 'react'
import { Grid, Button } from '@material-ui/core'
import { useFormContext, Controller } from 'react-hook-form'
import { useTheme } from 'styled-components'

import useIntl from 'hooks/useIntl'
import useToggle from 'hooks/useToggle'
import WizardFooter from 'components/Wizard/WizardFooter'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { CreationContent } from 'components/CreationContent'
import { Chapter, IndustryBenchmark, useGetCategoryByIdQuery } from 'hooks/useApollo'
import { OnboardingToggleButtonItem, ToggleButton } from 'components/ToggleButton'
import { SelectionContainer } from 'screens/Onboarding/components/SelectionContainer'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import IndustryBenchmarkIcon from 'screens/Onboarding/components/IndustryBenchmarkIcon'

import { RoleBasedChallengesForm } from '../../RoleBasedChallenges.types'
import { useRoleBasedChallengesStore } from '../../hooks/useRoleBasedChallengesStore'

export const RoleSkillsSelection = () => {
	const intl = useIntl()
	const theme = useTheme()
	const [{ open }, { handleToggle }] = useToggle()
	const { control, getValues, watch, setValue } = useFormContext<RoleBasedChallengesForm>()
	const {
		setChapters,
		setSelectedChapters,
		chapters,
		chapterList,
		showMoreChapters,
		showLessChapters,
		benchmarks,
		moreChapters
	} = useRoleBasedChallengesStore(state => state)
	const { prevStep, nextStep, currentStep, toggleHelpGuide } = useOnboardingStore(
		state => state
	)

	const selectedChapters = watch('chapters')
	const category = watch('category')

	useGetCategoryByIdQuery({
		variables: { id: category.id },
		fetchPolicy: 'cache-and-network',
		onCompleted: ({ categoryById }) => {
			const chapters =
				categoryById?.challenges.reduce<Chapter[]>((result, challenge) => {
					if (challenge && challenge.chapters) {
						challenge.chapters.forEach(chapter => {
							result.push(chapter as Chapter)
						})
					}
					return result
				}, []) || []
			setChapters(chapters, (categoryById?.benchmarks ?? []) as IndustryBenchmark[])
		}
	})

	const handleBack = () => {
		setValue('chapters', [])
		setChapters([], [])
		prevStep()
	}

	const handleToggleChapters = (callback: () => void) => {
		handleToggle()
		callback()
	}

	const handleContinue = () => {
		const selected = chapters.filter(chapter => selectedChapters.includes(chapter.id))
		setSelectedChapters(selected)
		nextStep()
	}

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent
					subHeader
					title={intl.formatMessage({
						id: `onboarding.role.based.challenges.step.${currentStep}.title`
					})}
					titleExt={
						<span style={{ color: theme.secondary.orange, fontWeight: 500 }}>
							{getValues().category.name}
						</span>
					}
					desc={intl.formatMessage({
						id: `onboarding.role.based.challenges.step.${currentStep}.description`
					})}
					subTitle={intl.formatMessage({
						id: 'onboarding.select.role.based.challenges.title'
					})}
				>
					<Grid container spacing={7}>
						<Grid item xs={12}>
							<SelectionContainer>
								<Controller
									control={control}
									name="chapters"
									render={({ onChange, value }) => (
										<ToggleButton value={value} onChange={value => onChange(value)}>
											{chapterList.map((chapter, index) => (
												<Grid item key={index} xs={12} md={6} lg={4}>
													<OnboardingToggleButtonItem
														icon={
															<IndustryBenchmarkIcon
																benchmarked={!!benchmarks[chapter.id]?.score}
															/>
														}
														value={chapter?.id || ''}
														title={chapter?.name || ''}
														description={chapter?.desc || ''}
													/>
												</Grid>
											))}
										</ToggleButton>
									)}
								/>
								{moreChapters.length > 0 && (
									<Grid item xs={12}>
										<Button
											onClick={
												open
													? () => handleToggleChapters(showLessChapters)
													: () => handleToggleChapters(showMoreChapters)
											}
											sx={{ color: theme.tag.blue }}
										>
											{intl.formatMessage({
												id: open
													? 'onboarding.show.less.skills'
													: 'onboarding.show.more.skills'
											})}
										</Button>
									</Grid>
								)}
							</SelectionContainer>
						</Grid>
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={selectedChapters.length < 3}
				onBack={handleBack}
				onContinue={handleContinue}
			/>
		</>
	)
}

export default RoleSkillsSelection
