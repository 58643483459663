import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import { useTheme } from 'styled-components'
import Modal, { IModalProps } from 'components/Modal'
import useIntl from 'hooks/useIntl'
import ChallengeCard from 'components/ChallengeCard'
import { GetAssessmentQuery } from 'hooks/useApollo'

export interface ChallengeDetailsModalProps {
	/**
	 * Props applied to the Modal component
	 */
	modalProps: Omit<IModalProps, 'title'>
	/**
	 * Props applied to the ChallengeCard component
	 */
	chapters: GetAssessmentQuery['assessment']['chapters']
	/**
	 * Function to get the preview link of the chapter
	 */
	getPreviewLink(chapterId: string): string
}

export const ChallengeDetailsModal: React.FC<ChallengeDetailsModalProps> = ({
	modalProps,
	chapters,
	getPreviewLink
}) => {
	const intl = useIntl()
	const theme = useTheme()
	// const totalDuration = chapters.reduce(
	// 	(total, chapter) => total + (chapter?.timer ?? 0),
	// 	0
	// )
	const durationEstimative = {
		low: chapters.length * 5,
		high: chapters.length * 8
	}

	return (
		<Modal
			{...modalProps}
			title={intl.formatMessage({ id: 'challengeDetails' })}
			size="big"
			showCloseButton
			footer={false}
		>
			<Grid container spacing={4}>
				<Grid item>
					<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
						{intl.formatMessage({ id: 'challengeDetails.desc' })}
					</Typography>
				</Grid>
				<Grid container item spacing={3}>
					<Grid item xs={12}>
						<Typography sx={{ color: theme.text.main }} variant="paragraph1">
							{intl.formatMessage({ id: 'totalDuration' })}{' '}
							<strong>
								{durationEstimative.low}-{durationEstimative.high}{' '}
								{intl.formatMessage({ id: 'mins' })}
							</strong>
						</Typography>
					</Grid>
					{chapters.map(chapter => (
						<Grid item xs={12} key={chapter?.id}>
							<ChallengeCard
								label={chapter?.name || ''}
								duration={chapter?.timer || 0}
								desc={chapter?.desc || ''}
								parts={chapter?.parts.length || 0}
								preview
								linkToPreview={getPreviewLink(chapter?.id as string)}
							/>
						</Grid>
					))}
				</Grid>
			</Grid>
		</Modal>
	)
}

export default ChallengeDetailsModal
