import React, { useMemo } from 'react'
import type { ToggleButtonGroupProps } from '@material-ui/core'
import { ButtonGroupContainer, ButtonSelectable } from './ButtonGroupSelect.style'

export interface ButtonGroupSelectProps extends ToggleButtonGroupProps {
	/**
	 * Allow multiples button inputs
	 * @default false
	 */
	multiple?: boolean
	options?: { label: string; value: string }[] | string[]
}

const checkOptionType = (options: ButtonGroupSelectProps['options']): options is string[] =>
	typeof options?.[0] === 'string'

export const ButtonGroupSelect = React.forwardRef(function ButtonGroupSelect(
	{ multiple = false, options: baseOptions, children, ...props }: ButtonGroupSelectProps,
	ref
) {
	const options: { label: string; value: string }[] = useMemo(
		() =>
			baseOptions
				? checkOptionType(baseOptions)
					? baseOptions.map(option => ({ label: option, value: option }))
					: baseOptions
				: [],
		[baseOptions]
	)

	return (
		<ButtonGroupContainer ref={ref} exclusive={!multiple} {...props}>
			{children ||
				options.map(option => (
					<ButtonSelectable key={option.value} value={option.value}>
						{option.label}
					</ButtonSelectable>
				))}
		</ButtonGroupContainer>
	)
})

export default ButtonGroupSelect
