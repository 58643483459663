import React from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { Grid, Button, Hidden, Typography, useMediaQuery, Theme } from '@material-ui/core'
import { ArrowBack as BackIcon } from '@material-ui/icons'
import { useStoreon } from 'storeon/react'

import useIntl from 'hooks/useIntl'
import { Logo } from 'components/Logo'
import roleBasedSvg from 'assets/illustrations/role-based-challenges.svg'
import guidedSvg from 'assets/illustrations/guided-challenges.svg'
import freeFormSvg from 'assets/illustrations/free-form.svg'

import { Container } from './Onboarding.style'
import ChallengeSelectionCard, {
	ChallengeSelectionCardProps
} from './components/ChallengeSelectionCard'

export const Onboarding = () => {
	const intl = useIntl()
	const { user } = useStoreon('user')
	const navigate = useNavigate()
	const companyLogo = user?.org?.photoUrl
	const companyName = user?.org?.name

	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	const selectionCards: ChallengeSelectionCardProps[] = [
		{
			title: intl.formatMessage({ id: 'onboarding.select.role.based.challenges.title' }),
			desc: intl.formatMessage({ id: 'onboarding.select.role.based.challenges.desc' }),
			src: roleBasedSvg,
			onClick: () => navigate('/onboarding/role-based-challenges')
		},
		{
			title: intl.formatMessage({ id: 'onboarding.select.guided.challenges.title' }),
			desc: intl.formatMessage({ id: 'onboarding.select.guided.challenges.desc' }),
			src: guidedSvg,
			onClick: () => navigate('/onboarding/guided-challenges')
		},
		{
			title: intl.formatMessage({ id: 'onboarding.select.free.form.title' }),
			desc: intl.formatMessage({ id: 'onboarding.select.free.form.desc' }),
			src: freeFormSvg,
			onClick: () => navigate('/onboarding/free-form')
		}
	]

	const backButton = (
		<Link to="/">
			<Button
				name="backTo"
				startIcon={<BackIcon />}
				disableFocusRipple={false}
				color="secondary"
				sx={{ padding: 0 }}
			>
				{intl.formatMessage(
					{ id: 'backTo' },
					{
						place: intl.formatMessage({
							id: 'dashboard'
						})
					}
				)}
			</Button>
		</Link>
	)

	return (
		<Grid container justifyContent="center">
			<Container container justifyContent="center" justifySelf="center">
				<Hidden mdDown>
					<Grid item xs={12} pb={3}>
						<Link to="/">
							{companyLogo ? (
								<img src={companyLogo} alt={companyName || ''} height={35} />
							) : (
								<Logo size="large" />
							)}
						</Link>
					</Grid>
				</Hidden>
				<Grid container justifyContent="center">
					<Grid container justifyContent="center">
						<Grid item container justifyContent="flex-start">
							{backButton}
						</Grid>
						<Grid item container xs={12} justifyContent="center" sx={{ mt: -3 }}>
							<Typography variant="h4">
								{intl.formatMessage({ id: 'onboarding.select.challenge.creation.title' })}
							</Typography>
						</Grid>
						<Grid
							item
							container
							xs={12}
							spacing={smUp ? 10 : 4}
							justifyContent="center"
							sx={{ mt: smUp ? 0 : undefined }}
						>
							{selectionCards.map((selectionCard, index) => (
								<Grid key={index} item xs={12} md={4}>
									<ChallengeSelectionCard {...selectionCard} />
								</Grid>
							))}
						</Grid>
					</Grid>
				</Grid>
			</Container>
		</Grid>
	)
}

export default Onboarding
