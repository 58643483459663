import React from 'react'
import { Grid, Skeleton, useMediaQuery, Theme, Divider } from '@material-ui/core'

export const ChallengeProgressSkeleton = () => {
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	return (
		<Grid container direction={smUp ? 'row' : 'column'} spacing={smUp ? 0 : 2}>
			<Grid item xs container spacing={2}>
				<Grid item xs={12}>
					<Skeleton animation="wave" width={150} />
				</Grid>
				<Grid item container justifyContent="space-between" xs={12}>
					<Grid item>
						<Skeleton animation="wave" width={100} />
					</Grid>
					<Grid item>
						<Skeleton animation="wave" variant="circular" width={24} height={24} />
					</Grid>
				</Grid>
				<Grid item xs={12}>
					<Skeleton animation="wave" width="100%" />
				</Grid>
			</Grid>
			<Grid item sx={{ mx: smUp ? 2 : 0, my: smUp ? 2 : 0 }}>
				<Divider orientation={smUp ? 'vertical' : 'horizontal'} />
			</Grid>
			<Grid item xs container>
				<Grid item xs={12}>
					<Skeleton animation="wave" width={120} />
				</Grid>
				<Grid item>
					<Skeleton
						variant="text"
						animation="wave"
						width={100}
						height={30}
						style={{ borderRadius: 10 }}
					/>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ChallengeProgressSkeleton
