import React from 'react'
import { Typography } from '@material-ui/core'
import Modal, { IModalProps } from 'components/Modal'
import useIntl from 'hooks/useIntl'

export interface DeleteModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the item to be deleted
	 */
	id: string
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Defines the title of the item to be deleted
	 */
	title: string
	/**
	 * Callback fired when the ok button is clicked
	 */
	onDelete: (id: string) => void
	/**
	 * Defines the mask of user
	 * @default false
	 */
	masked?: boolean
}

export const DeleteModal: React.FC<DeleteModalProps> = ({
	open,
	onClose,
	title,
	onDelete,
	id,
	loading = false,
	masked = false
}) => {
	const intl = useIntl()

	const handleDelete = () => {
		onDelete(id)
	}

	return (
		<Modal
			open={open}
			size="big"
			onClose={onClose}
			onOk={handleDelete}
			okLoading={loading}
			closeOnOk={false}
			maxWidth="xs"
			title={intl.formatMessage({ id: 'deleteFormName' }, { masked: masked ? 1 : title })}
		>
			<Typography variant="paragraph1">
				{intl.formatMessage({ id: 'deleteFormDesc' }, { masked: masked ? 1 : title })}
			</Typography>
		</Modal>
	)
}

export default DeleteModal
