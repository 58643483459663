import React from 'react'
import { Grid, Typography } from '@material-ui/core'
import BadgeIcon from 'assets/icons/BadgeIcon'

export interface BadgeProps {
	/**
	 * Defines the color of the component
	 */
	color: string
	/**
	 * Defines the label of the component
	 */
	label?: string
	/**
	 * The size of the component.
	 * @default 'medium'
	 */
	size?: 'small' | 'medium' | 'large'
}

const styles = {
	sizes: {
		small: { height: 40, width: 40 },
		medium: { height: 50, width: 50 },
		large: { height: 60, width: 60 }
	}
}

export const Badge: React.FC<BadgeProps> = ({ size = 'medium', label, color }) => {
	return (
		<Grid container direction="column" justifyContent="center" alignItems="center">
			<BadgeIcon style={{ ...styles.sizes[size] }} color={color} />
			<Typography sx={{ mt: 0.5 }} align="center" variant="paragraph3">
				{label}
			</Typography>
		</Grid>
	)
}

export default Badge
