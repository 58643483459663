import React from 'react'
import { useTheme } from 'styled-components'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { ListItemText, MenuItem, ListItemIcon } from '@material-ui/core'

export interface PopupMenuItemProps {
	onClick: (event: any) => void
	primary: React.ReactNode
	secondary?: React.ReactNode
	danger?: boolean
	style?: React.CSSProperties
	disabled?: boolean
	restricted?: boolean
}

export const PopupMenuItem = React.forwardRef<HTMLLIElement, PopupMenuItemProps>(
	({ onClick, primary, secondary, danger, style, disabled, restricted }, ref) => {
		const theme = useTheme()
		return (
			<MenuItem
				onClick={onClick}
				disabled={disabled || restricted}
				ref={ref}
				style={{
					whiteSpace: 'unset',
					padding: '10px',
					...style
				}}
			>
				{restricted && (
					<ListItemIcon sx={{ minWidth: '25px' }}>
						<LockOutlinedIcon
							fontSize="small"
							sx={{ mt: -0.5, fill: theme.background.summary, height: 16, width: 16 }}
						/>
					</ListItemIcon>
				)}
				<ListItemText
					primary={primary}
					primaryTypographyProps={{
						variant: 'paragraph1',
						color: 'textPrimary',
						gutterBottom: true,
						style: {
							lineHeight: 'normal',
							color: danger ? theme.tag.red : undefined
						}
					}}
					secondary={secondary}
					secondaryTypographyProps={{
						variant: 'paragraph3'
					}}
				/>
			</MenuItem>
		)
	}
)

export default PopupMenuItem
