/* eslint-disable jsx-a11y/alt-text */
/* eslint-disable import/no-duplicates */
import 'swiper/swiper.min.css'
import 'swiper/components/pagination/pagination.min.css'
import './slides.css'
import React from 'react'
import { SwiperOptions } from 'swiper'
import { Swiper, SwiperSlide } from 'swiper/react'
import SwiperCore, { Pagination, Autoplay } from 'swiper/core'
import { Grid } from '@material-ui/core'

import useIntl from 'hooks/useIntl'
import whiteDots from 'assets/illustrations/whiteDots.svg'
import testimonialAvatar from 'assets/images/testimonialAvatar.png'
import desktopAndMobileMockup from 'assets/images/desktopAndMobileMockup.png'
import { Testimonials } from '../Testimonials'

const swiperSlideStyles: React.CSSProperties = {
	display: 'flex',
	justifyContent: 'center',
	position: 'relative',
	maxHeight: 500
}

export const slideSettings: SwiperOptions & { style: React.CSSProperties } = {
	pagination: { clickable: true },
	autoplay: {
		delay: 5000,
		disableOnInteraction: false
	},
	slidesPerView: 'auto',
	centeredSlides: false,
	style: {
		paddingBottom: 40,
		height: 500
	}
}

SwiperCore.use([Pagination, Autoplay])

export const Slides = () => {
	const intl = useIntl()
	const slides: { id: number; component: React.ReactNode }[] = [
		{
			id: 1,
			component: (
				<Grid container justifyContent="center" alignItems="center">
					<img
						src={desktopAndMobileMockup}
						width="100%"
						height="100%"
						style={{ objectFit: 'contain', zIndex: 50 }}
						alt="SuperTalent"
					/>
				</Grid>
			)
		},
		{
			id: 2,
			component: (
				<Testimonials
					avatar={testimonialAvatar}
					testimonial={intl.formatMessage({ id: 'auth.message.quote' })}
					name="Mark Freedman"
					title="Product Manager"
					subName="Mortgate Automator"
				/>
			)
		}
	]

	return (
		<Swiper {...slideSettings}>
			{slides.map(({ id, component }) => (
				<SwiperSlide key={id} style={swiperSlideStyles}>
					<img
						src={whiteDots}
						style={{ height: 180, width: 180, position: 'absolute', top: 0, left: 0 }}
					/>
					<img
						src={whiteDots}
						style={{ height: 180, width: 180, position: 'absolute', bottom: 0, right: 0 }}
					/>
					{component}
				</SwiperSlide>
			))}
		</Swiper>
	)
}

export default Slides
