import React from 'react'
import { Grid, Typography, Button, SvgIconProps } from '@material-ui/core'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import Modal, { IModalProps } from 'components/Modal'
import ButtonLink from 'components/ButtonLink'

export interface NyomiModalProps extends Pick<IModalProps, 'open' | 'onClose' | 'onOk'> {
	/**
	 * Defines the text title of the modal
	 */
	title: string
	/**
	 * Defines the link when ok button is clicked
	 */
	href?: string
	/**
	 * Defines the text description of the modal
	 */
	desc: string
	/**
	 * Defines the text sub description of the modal
	 */
	subDesc?: string
	/**
	 * Defines the label of the main button
	 */
	btnLabel: string
	/**
	 * Defines the label of the sub button
	 * If set, the sub button will be shown.
	 */
	subBtnLabel?: string
	/**
	 * Defines the icon to be displayed
	 */
	nyomiIcon: React.FC<SvgIconProps>
}

export const NyomiModal: React.FC<NyomiModalProps> = ({
	onClose,
	onOk,
	open,
	title,
	href,
	desc,
	subDesc,
	btnLabel,
	subBtnLabel,
	nyomiIcon: Icon
}) => {
	const handleClick: React.MouseEventHandler<HTMLButtonElement> = e => {
		onOk?.(e)
		onClose()
	}

	return (
		<Modal onlyContent open={open} onClose={onClose} footer={false}>
			<Grid
				container
				justifyContent="center"
				alignItems="center"
				sx={{ textAlign: 'center', py: 4 }}
				spacing={5}
			>
				<Grid container item spacing={3} justifyContent="center" alignItems="center">
					<Grid item xs={12}>
						<Icon style={{ width: 160, fontSize: '5rem' }} />
					</Grid>
					<Grid item xs={12}>
						<Typography variant="h3" style={{ fontWeight: 500, fontSize: pxToRem(20) }}>
							{title}
						</Typography>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="paragraph1">{desc}</Typography>
					</Grid>
					{subDesc && (
						<Grid item xs={12}>
							<Typography variant="paragraph1">{subDesc}</Typography>
						</Grid>
					)}
				</Grid>
				<Grid item container justifyContent="center">
					<Grid item xs={12}>
						{href ? (
							<Button href={href} variant="contained">
								{btnLabel}
							</Button>
						) : (
							<Button variant="contained" onClick={handleClick}>
								{btnLabel}
							</Button>
						)}
					</Grid>
					{subBtnLabel && (
						<Grid item xs={12}>
							<ButtonLink onClick={onClose} sx={{ mt: 2 }}>
								{subBtnLabel}
							</ButtonLink>
						</Grid>
					)}
				</Grid>
			</Grid>
		</Modal>
	)
}

export default NyomiModal
