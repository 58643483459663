/* eslint-disable indent */
import React from 'react'
import { useFormContext } from 'react-hook-form'

import useIntl from 'hooks/useIntl'
import { EnumPipelineType } from 'hooks/useApollo'
import TeamDetailsForm from 'screens/Onboarding/components/TeamDetailsForm'
import { useCreateAssessment } from 'screens/Onboarding/hooks/useCreateAssessment'

import {
	useOnboardingStore,
	resetOnboardingStore
} from '../../../../hooks/useOnboardingStore'
import { resetRoleBasedChallengesStore } from '../../hooks/useRoleBasedChallengesStore'
import { RoleBasedChallengesForm } from '../../RoleBasedChallenges.types'

export const RoleBasedTeamCreation: React.FC = () => {
	const intl = useIntl()
	const { control, getValues, formState } = useFormContext<RoleBasedChallengesForm>()
	const { currentStep, prevStep, toggleHelpGuide } = useOnboardingStore(state => state)

	const resetStore = () => {
		resetOnboardingStore()
		resetRoleBasedChallengesStore()
	}
	const [{ loading }, { createAssessment }] = useCreateAssessment(resetStore)

	const handleBack = () => {
		prevStep()
	}

	const handleContinue = () => {
		const { team, talentGroup, chapters, category, challengePack } = getValues()

		createAssessment({
			variables: {
				assessmentInput: {
					challengePack,
					selectedRole: category.id,
					name: team.name,
					masking: team.maskChallenge,
					chapters,
					objective: 'Benchmarking'
				},
				emailsToInvite: talentGroup.teammateEmails,
				pipelineInput: {
					name: talentGroup.name,
					color: talentGroup.color,
					type: talentGroup.type as EnumPipelineType
				}
			}
		})
	}

	return (
		<TeamDetailsForm
			title={intl.formatMessage({
				id: `onboarding.role.based.challenges.step.${currentStep}.title`
			})}
			subTitle={intl.formatMessage({ id: 'onboarding.select.role.based.challenges.title' })}
			onBack={handleBack}
			onContinue={handleContinue}
			control={control}
			loading={loading}
			isValid={!formState.isValid}
			currentStep={currentStep}
			onShowHelpGuide={toggleHelpGuide}
		/>
	)
}

export default RoleBasedTeamCreation
