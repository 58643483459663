import React from 'react'
import { LinkProps, Link } from '@material-ui/core'
import { useTheme } from 'styled-components'

export interface ButtonLinkProps extends LinkProps<'button'> {}

export const ButtonLink = ({
	variant = 'body2',
	disabled,
	children,
	onClick,
	...props
}: ButtonLinkProps) => {
	const theme = useTheme()
	return (
		<Link
			component="button"
			variant={variant}
			color={disabled ? theme.grey.second : 'link.main'}
			disabled={disabled}
			onClick={disabled ? undefined : onClick}
			sx={{
				cursor: disabled ? 'default' : 'pointer',
				':hover': disabled
					? {
							textDecoration: 'none'
					  }
					: undefined
			}}
			{...props}
		>
			{children}
		</Link>
	)
}

export default ButtonLink
