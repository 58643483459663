import styled from 'styled-components'
import { IconButton } from '@material-ui/core'
import { ArrowProps } from './CustomArrow'

export const ButtonStyle = styled(IconButton).withConfig<Pick<ArrowProps, 'direction'>>({
	shouldForwardProp: prop => !['direction'].includes(prop)
})`
	position: absolute;
	top: 50%;
	right: ${props => props.direction === 'right' && '25px'};
	left: ${props => props.direction === 'left' && '25px'};
	transform: translateY(-50%);
	z-index: 50;
`
