import React from 'react'

interface TabPanelProps {
	/**
	 * Defines the index of the component
	 */
	index: number
	/**
	 * Defines current active tab
	 */
	value: number
}

export const TabPanel: React.FC<TabPanelProps> = props => {
	const { children, value, index, ...other } = props

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`tabpanel-${index}`}
			aria-labelledby={`tab-${index}`}
			{...other}
		>
			{value === index && children}
		</div>
	)
}

export default TabPanel
