import styled from 'styled-components'

export const TalentGroupToggle = styled.div`
	cursor: pointer;
	display: flex;
	align-items: center;

	span {
		font-weight: 12px;
		margin-left: 9px;
		transition: color 0.2s linear;
	}

	&:hover {
		span {
			color: ${props => props.theme.text.main};
		}
	}
`

export interface ToggleColorProps {
	active?: boolean
	color: string
}

export const ToggleColor = styled.div<ToggleColorProps>`
	background-color: ${({ color }) => color};
	width: 9px;
	height: 9px;
	border-radius: 50%;
`
