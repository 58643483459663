import React, { useEffect } from 'react'
import { Button, Hidden, Grid, Typography, Theme, useMediaQuery } from '@material-ui/core'
import { ArrowBack as BackIcon } from '@material-ui/icons'
import { Link } from 'react-router-dom'
import { useStoreon } from 'storeon/react'

import CustomStepper, { CustomStepperProps } from 'components/CustomStepper'
import { Logo } from 'components/Logo'
import { useIntl } from 'hooks/useIntl'

import { Container, Content } from './Wizard.styles'

export interface WizardProps {
	currentStep: number
	subStep?: number
	previousPath: string
	previousPageName?: string
	stepperLabels: CustomStepperProps['steps']
	title?: string
	levelContentWithStepper?: boolean
	showMobileStepIndicator?: boolean
	onBack?: () => void
}

export const Wizard: React.FC<WizardProps> = ({
	children,
	currentStep,
	subStep,
	previousPath,
	previousPageName,
	stepperLabels,
	title,
	levelContentWithStepper,
	showMobileStepIndicator = true,
	onBack
}) => {
	const intl = useIntl()
	const mdUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
	const { user } = useStoreon('user')
	const companyLogo = user?.org?.photoUrl
	const companyName = user?.org?.name

	useEffect(() => {
		window.scrollTo({ top: 0 })
	}, [currentStep])

	const backButton = (
		<Link to={previousPath} onClick={() => onBack?.()}>
			<Button
				name="backTo"
				startIcon={<BackIcon />}
				disableFocusRipple={false}
				color="secondary"
				sx={{ padding: 0 }}
			>
				{!previousPageName
					? intl.formatMessage({ id: 'back' })
					: intl.formatMessage({ id: 'backTo' }, { place: previousPageName })}
			</Button>
		</Link>
	)

	return (
		<div style={{ minHeight: '100vh', position: 'relative' }}>
			<Grid container justifyContent="center">
				<Container container justifyContent="center" justifySelf="center">
					<Hidden mdDown>
						<Grid item xs={12} pb={3}>
							<Link to="/">
								{companyLogo ? (
									<img src={companyLogo} alt={companyName || ''} height={35} />
								) : (
									<Logo size="large" />
								)}
							</Link>
						</Grid>
					</Hidden>
					<Grid container justifyContent="center">
						<Grid container wrap="nowrap" alignItems="flex-start">
							<Hidden mdDown>
								<Grid item container xs={12} md={3} spacing={4}>
									<Grid item xs={12}>
										{backButton}
									</Grid>
									{title && (
										<Grid item xs={12}>
											<Typography variant="h4">{title}</Typography>
										</Grid>
									)}
									<Grid item xs={12}>
										<CustomStepper
											step={currentStep}
											steps={stepperLabels}
											subStep={subStep ?? 0}
										/>
									</Grid>
								</Grid>
							</Hidden>
							<Content
								item
								container
								xs={12}
								md={9}
								direction="column"
								spacing={2}
								topGutter={mdUp}
								levelWithStepper={levelContentWithStepper}
							>
								<Hidden mdUp>
									<Grid item>{backButton}</Grid>
									{showMobileStepIndicator && (
										<Grid item>
											<MobileStepIndicator
												currentStep={currentStep + 1}
												totalStep={stepperLabels.length}
											/>
										</Grid>
									)}
								</Hidden>
								<Grid container item mt={1}>
									{children}
								</Grid>
							</Content>
						</Grid>
					</Grid>
				</Container>
			</Grid>
		</div>
	)
}

export interface MobileStepIndicatorProps {
	currentStep: number
	totalStep: number
}

export const MobileStepIndicator: React.FC<MobileStepIndicatorProps> = ({
	currentStep,
	totalStep
}) => (
	<Typography color="secondary" variant="paragraph2">
		Step {currentStep} of {totalStep}
	</Typography>
)
