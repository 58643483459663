import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const PinIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="30" height="30" viewBox="0 0 18 23" fill="none">
		<path
			d="M7.34174 7.89049L10.7957 4.79477C11.0027 4.60925 11.1193 4.34703 11.1687 4.07348C11.3178 3.24659 11.7555 2.56722 12.1834 2.10839C12.5218 1.74554 13.0728 1.82729 13.4039 2.19675C14.7343 3.68111 16.1346 5.24337 17.3205 6.56657C17.6474 6.93138 17.6843 7.48979 17.3038 7.7984C16.6491 8.32947 15.8544 8.56952 15.2679 8.62999C14.9863 8.65903 14.7074 8.74678 14.4967 8.93569L11.0685 12.0082C10.8106 12.2394 10.6916 12.5835 10.7055 12.9296C10.7895 15.0256 9.67347 16.7051 8.71635 17.6599C8.34243 18.0329 7.74778 17.9643 7.39527 17.571L1.37922 10.8587C0.995582 10.4306 1.02932 9.76605 1.50665 9.44583C3.30295 8.24079 5.26764 8.06269 6.42853 8.16729C6.75845 8.19701 7.09506 8.11157 7.34174 7.89049Z"
			strokeWidth="2"
		/>
		<path
			d="M5.13865 13.5829C5.0027 13.4313 4.83208 13.3211 4.656 13.2741C4.48247 13.2278 4.26988 13.2373 4.10761 13.3827L0.428983 16.6798C0.266715 16.8252 0.234116 17.0355 0.261209 17.213C0.2887 17.3932 0.379579 17.5748 0.515531 17.7265C0.651482 17.8782 0.82211 17.9883 0.998185 18.0353C1.17172 18.0816 1.3843 18.0721 1.54657 17.9267L5.2252 14.6297C5.38747 14.4842 5.42007 14.2739 5.39298 14.0964C5.36548 13.9163 5.27461 13.7346 5.13865 13.5829Z"
			strokeWidth="0.5"
		/>
	</SvgIcon>
)

export default PinIcon
