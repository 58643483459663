import React, { useEffect } from 'react'
import { IntlProvider } from 'react-intl'
import { useRoutes } from 'react-router-dom'
import LocalizationProvider from '@material-ui/lab/LocalizationProvider'
import { NuggetProvider } from '@nuggetai/ui-kit.nugget.provider'
import DateFnsUtils from '@material-ui/lab/AdapterDateFns'
import { useStoreon } from 'storeon/react'
import iziToast from 'izitoast'
import { useMediaQuery } from '@material-ui/core'
import * as locales from './constants/locales'
import { mainRoutes } from './routes'
import 'izitoast/dist/css/iziToast.min.css'

iziToast.settings({
	position: 'bottomLeft',
	maxWidth: 400
})

const App = () => {
	const routes = useRoutes(mainRoutes)
	const { theme } = useStoreon('theme')
	const smDown = useMediaQuery('(max-width: 600px)')

	useEffect(() => {
		if (smDown) {
			iziToast.settings({
				position: 'topLeft'
			})
		} else {
			iziToast.settings({
				position: 'bottomLeft',
				maxWidth: 400
			})
		}
	}, [smDown])

	useEffect(() => {
		const handleUnload = () => {
			localStorage.setItem('lastActive', new Date().toJSON())
		}

		window.addEventListener('unload', handleUnload)

		return () => {
			window.removeEventListener('unload', handleUnload)
		}
	}, [])

	return (
		<NuggetProvider mode={theme}>
			<IntlProvider locale="en" defaultLocale="en" messages={locales.en}>
				<LocalizationProvider dateAdapter={DateFnsUtils}>{routes}</LocalizationProvider>
			</IntlProvider>
		</NuggetProvider>
	)
}

export default App
