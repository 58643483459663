import React from 'react'
import { useForm } from 'react-hook-form'
import { useTypedController } from '@hookform/strictly-typed'
import Modal, { IModalProps } from 'components/Modal'
import { Grid, TextField, useMediaQuery, Theme } from '@material-ui/core'
import useIntl from 'hooks/useIntl'

export interface EditModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the unique id of the item to be edited
	 */
	id: string
	/**
	 * Defines the current value of the field to be edited
	 */
	value: string
	/**
	 * Defines the loading state of the component
	 * @default false
	 */
	loading?: boolean
	/**
	 * Defines the field to be edited
	 */
	field: string
	/**
	 * Callback fired when the finish button is clicked
	 */
	onFinish: (id: string, field: string) => void
}

export interface EditForm {
	[key: string]: string
}

export const EditModal: React.FC<EditModalProps> = ({
	id,
	open,
	onClose,
	value,
	field,
	onFinish,
	loading = false
}) => {
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const { control, errors, handleSubmit: onSubmit, formState } = useForm<EditForm>({
		reValidateMode: 'onChange',
		defaultValues: {
			[field]: value
		}
	})

	const Controller = useTypedController<EditForm>({ control })
	const handleSubmit = async (payload: EditForm) => {
		onFinish(id, payload[field])
	}

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={onClose}
			size={mdDown ? 'normal' : 'big'}
			onOk={onSubmit(handleSubmit)}
			closeOnOk={false}
			maxWidth="xs"
			onOkDisabled={!formState.isDirty && !formState.isValid}
			okLoading={loading}
			title={intl.formatMessage({ id: 'editForm' }, { value })}
		>
			<form onSubmit={onSubmit(handleSubmit)}>
				<Grid container spacing={4}>
					<Grid item container>
						<Controller
							name={field}
							render={props => (
								<TextField
									fullWidth
									autoFocus
									label={field}
									InputLabelProps={{
										style: { textTransform: 'capitalize' }
									}}
									error={!!errors?.[field]?.message}
									helperText={errors?.[field]?.message}
									required
									{...props}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
		</Modal>
	)
}

export default EditModal
