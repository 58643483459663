import React from 'react'
import { Step } from 'react-joyride'
import { Typography } from '@material-ui/core'
import useIntl, { ILocale } from 'hooks/useIntl'

interface TextProps {
	intlId: keyof ILocale
}

const Title: React.FC<TextProps> = ({ intlId }) => {
	const intl = useIntl()
	return (
		<Typography variant="h4" sx={{ fontWeight: 500 }}>
			{intl.formatMessage({ id: intlId })}
		</Typography>
	)
}

const Content: React.FC<TextProps> = ({ intlId }) => {
	const intl = useIntl()
	return <Typography variant="paragraph1">{intl.formatMessage({ id: intlId })}</Typography>
}

const baseStepConfig: Partial<Step> = {
	disableBeacon: true,
	spotlightPadding: 15,
	hideCloseButton: true,
	placement: 'auto',
	disableOverlayClose: true
}

export const TEAMS_TOUR_DESKTOP: Step[] = [
	{
		...baseStepConfig,
		target: '#team-tours-add-talent-button',
		title: <Title intlId="tours.team.addTalent" />,
		content: <Content intlId="tours.team.addTalent.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-card',
		title: <Title intlId="tours.team.groupCard" />,
		content: <Content intlId="tours.team.groupCard.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-card-add-button',
		title: <Title intlId="tours.team.addTalentGroup" />,
		content: <Content intlId="tours.team.addTalentGroup.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-search',
		title: <Title intlId="tours.team.talent" />,
		content: <Content intlId="tours.team.talent.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-dynamic-chart',
		title: <Title intlId="tours.team.talentDynamic" />,
		content: <Content intlId="tours.team.talentDynamic.desc" />
	}
]

export const TEAMS_TOUR_MOBILE: Step[] = [
	{
		...baseStepConfig,
		target: '#team-tours-speed-dial-button',
		title: <Title intlId="tours.team.addTalent" />,
		content: <Content intlId="tours.team.addTalent.desc" />,
		spotlightClicks: true
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-card',
		title: <Title intlId="tours.team.groupCard" />,
		content: <Content intlId="tours.team.groupCard.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-search',
		title: <Title intlId="tours.team.talent" />,
		content: <Content intlId="tours.team.talent.desc" />
	},
	{
		...baseStepConfig,
		target: '#team-tours-talent-dynamic-chart',
		title: <Title intlId="tours.team.talentDynamic" />,
		content: <Content intlId="tours.team.talentDynamic.desc" />
	}
]
