import React, { useState, useCallback, useEffect } from 'react'
import { Box, Avatar, Grid, Typography, TextField } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import { useStoreon } from 'storeon/react'
import { useForm } from 'react-hook-form'
import { UpdateUserInput, useUpdateUserMutation } from 'hooks/useApollo'
import { LoadingButton } from '@material-ui/lab'
import { useDropzone } from 'react-dropzone'
import { useTheme } from 'styled-components'
import iziToast from 'izitoast'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import { getInitials } from 'utils/string'
import { Header } from '../components'
import { Paper } from '../../AccountSettings.style'
import { UserAvatarContainer } from './PersonalInformation.style'
import { SERVER_URL } from 'constants/config'

export const PersonalInformation = () => {
	const [avatar, setAvatar] = useState<string>()
	const [avatarFile, setAvatarFile] = useState<File>()
	const { dispatch, user } = useStoreon('user')
	const [loading, setloading] = useState<boolean>(false)
	const theme = useTheme()

	const {
		register,
		handleSubmit,
		formState,
		watch,
		setError,
		errors
	} = useForm<UpdateUserInput>({
		mode: 'all'
	})

	const allFields = watch()

	const onDrop = useCallback(([file], [error]) => {
		if (file) {
			setAvatar(URL.createObjectURL(file))
			setAvatarFile(file)
		} else if (error?.errors?.[0]?.message) {
			iziToast.error({
				message: error.errors[0].message
			})
		} else {
			iziToast.error({
				message: 'Max file size is 2mb'
			})
		}
	}, [])

	const { getRootProps, getInputProps } = useDropzone({
		onDrop,
		accept: ['image/jpeg', 'image/png'],
		maxSize: 2000000, // 2mb
		multiple: false,
		preventDropOnDocument: false
	})

	useEffect(() => {
		setAvatar(user?.photoUrl || '')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const [updateUser] = useUpdateUserMutation({
		onCompleted: finalData => {
			// @ts-ignore
			dispatch('user/setUser', finalData.updateUser)
			setloading(false)
			iziToast.success({
				message: intl.formatMessage({ id: 'updatedUser' })
			})
		},
		onError: ({ graphQLErrors }) => {
			setloading(false)
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	const onSubmit = async () => {
		const jwt = JSON.parse(localStorage.getItem('storeon') || '')

		async function uploadFile(profileImage, jwts) {
			const url = `${SERVER_URL}/api/v1/user/setprofileImage`
			const formData = new FormData()
			formData.append('img', profileImage)
			formData.append('id', jwt?.user?.id)

			try {
				setloading(true)
				const response = await fetch(url, {
					method: 'POST',
					headers: {
						Authorization: `${jwts}`
					},
					body: formData
				})
				if (response.ok) {
					const result = await response.json()
				} else {
					console.error('File upload failed:', response.statusText)
				}
			} catch (error) {
				setloading(false)
				console.error('Error uploading file:', error)
			}
		}

		await uploadFile(avatarFile, jwt?.token)

		updateUser({
			variables: {
				userInput: {
					...allFields,
					photo: avatarFile
				}
			}
		})
	}

	const intl = useIntl()

	return (
		<Paper elevation={7}>
			<Header title={intl.formatMessage({ id: 'accountSettings.account' })} />
			<Grid container spacing={5} component="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid item xs={12}>
					<UserAvatarContainer>
						<Avatar
							{...getRootProps()}
							src={avatar}
							alt={user?.name}
							style={{
								width: 112,
								height: 112
							}}
						>
							{getInitials(user?.firstName as string, user?.lastName as string)}
						</Avatar>
						<div className="image-selector" {...getRootProps()}>
							<input {...getInputProps()} type="file" accept="image/*" />
							<i className="bx bxs-camera" />
						</div>
					</UserAvatarContainer>
				</Grid>
				<Grid item container justifyContent="space-between">
					<Grid item>
						<Typography variant="paragraph2" fontWeight={500} color={theme.grey.main}>
							{intl.formatMessage({
								id: 'fileFormat'
							})}
							: PNG, JPEG
						</Typography>
					</Grid>
					<Grid item>
						<Typography variant="paragraph2" fontWeight={500} color={theme.grey.main}>
							{intl.formatMessage({
								id: 'maximumSize'
							})}
							: 2MB
						</Typography>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						style={{ fontSize: pxToRem(16) }}
						name="firstName"
						type="firstName"
						required
						defaultValue={user?.firstName}
						error={!!errors.firstName}
						helperText={
							errors?.firstName?.type === 'validate'
								? errors?.firstName?.message
								: undefined
						}
						inputRef={register({ required: true })}
						label={intl.formatMessage({ id: 'firstName' })}
						placeholder={intl.formatMessage({
							id: 'accountSettings.placeholder.username'
						})}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						name="lastName"
						type="lastName"
						defaultValue={user?.lastName}
						required
						inputRef={register({ required: true })}
						error={!!errors.lastName}
						helperText={
							errors?.lastName?.type === 'validate' ? errors?.lastName?.message : undefined
						}
						label={intl.formatMessage({ id: 'lastName' })}
						placeholder={intl.formatMessage({
							id: 'accountSettings.placeholder.lastname'
						})}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField label="Email" value={user?.email} disabled />
					<Box sx={{ display: 'flex', mt: 1.75 }}>
						<Box
							className="check-icon"
							sx={{
								width: 20,
								height: 20,
								display: 'flex',
								alignItems: 'center',
								justifyContent: 'center',
								color: 'white',
								borderRadius: '50%',
								mr: 1.75
							}}
							style={
								user?.isEmailVerified
									? { backgroundColor: theme.tag.green }
									: { backgroundColor: theme.tag.red }
							}
						>
							<i className={user?.isEmailVerified ? 'bx bx-check' : 'bx bx-x'} />
						</Box>
						<Typography
							sx={{ fontSize: 15, lineHeight: 1.33 }}
							style={
								user?.isEmailVerified
									? { color: theme.tag.green }
									: { color: theme.tag.red }
							}
						>
							{intl.formatMessage({
								id: user?.isEmailVerified
									? 'accountSettings.emailVerified'
									: 'accountSettings.emailNotVerified'
							})}
						</Typography>
					</Box>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						name="role"
						type="role"
						defaultValue={user?.role}
						inputRef={register}
						label="Role"
					/>
				</Grid>
				<Grid container item xs={12} justifyContent="flex-end" mt={5}>
					<Grid item xs={12} md={2}>
						<LoadingButton
							pending={loading}
							fullWidth
							variant="contained"
							disabled={!formState.isValid || (!formState.isDirty && !avatarFile)}
						>
							{intl.formatMessage({ id: 'save' })}
						</LoadingButton>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default PersonalInformation
