import styled from 'styled-components'
import { Typography, TableCell } from '@material-ui/core'

export const TableColumnLabel = styled(Typography).attrs({ variant: 'paragraph2' })`
	font-weight: 500;
	text-transform: uppercase;
	color: ${props => props.theme.grey.main};
`

export const Name = styled(Typography).attrs({
	variant: 'h4',
	gutterBottom: true
})`
	font-weight: 500;
	color: ${props => props.theme.tag.blue};
`

export const TableCellHeader = styled(TableCell)`
	&& {
		padding: 5px 16px;
		background-color: ${props => props.theme.background.main};

		&:first-child {
			border-radius: 10px 0 0 10px;
		}

		&:last-child {
			border-radius: 0 10px 10px 0;
		}

		border-bottom: none;
	}
`
