import { useMemo } from 'react'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import { useStoreon } from 'storeon/react'

export type IuseUserSubscriptions = ReturnType<typeof useUserSubscriptions>

export type ActiveSubscriptions = {
	[key: string]: {
		id: string
		title: string
		endsAt: Date
		nextPaymentAt: Date
		isCanceled: boolean
		isPaymentFailed: boolean
		nextPaymentCost: number
		isCancellationAlertAcknowledged: boolean
		isCancellationMessageAcknowledged: boolean
	}
}

const teamSelector = (store: TeamContextProps) => {
	return {
		demo: store.demo
	}
}

export const useUserSubscriptions = () => {
	const { user } = useStoreon('user')
	let demo = false

	const subscriptions = useMemo(
		() =>
			(user?.subscriptions || []).reduce<ActiveSubscriptions>((current, subscription) => {
				subscription.plans.forEach(plan => {
					const key = plan.toLowerCase()
					current[key] = {
						id: subscription.id,
						title: key === 'hiring' ? 'Talent acquisition' : 'Talent benchmarking',
						endsAt: subscription.endsAt,
						nextPaymentAt: subscription.nextPaymentAt,
						isCanceled: subscription.isCanceled,
						isPaymentFailed: subscription.isPaymentFailed,
						nextPaymentCost: subscription.nextPaymentCost || 0,
						isCancellationAlertAcknowledged: !!subscription.isCancellationAlertAcknowledged,
						isCancellationMessageAcknowledged: !!subscription.isCancellationMessageAcknowledged
					}
				})
				return current
			}, {}),
		[user]
	)

	try {
		// eslint-disable-next-line
		demo = useTeamContext(teamSelector)?.demo
	} catch {}

	const isOnHiringPlan = subscriptions && Object.keys(subscriptions).includes('hiring')
	const isOnBenchmarkingPlan =
		subscriptions && Object.keys(subscriptions).includes('benchmarking')
	const isOnTrial =
		user?.trialDetails &&
		new Date(user.trialDetails.endOfTrial) > new Date() &&
		!isOnBenchmarkingPlan &&
		!isOnHiringPlan

	const trialExpiredWithoutSubscription = demo
		? false
		: !isOnTrial && !isOnBenchmarkingPlan && !isOnHiringPlan
	const isOnDemo = !!demo

	const models = {
		isOnDemo,
		isOnTrial,
		isOnHiringPlan,
		isOnBenchmarkingPlan,
		trialExpiredWithoutSubscription,
		subscriptions
	}
	const operations = {}

	return [models, operations] as [typeof models, typeof operations]
}

export default useUserSubscriptions
