import styled from 'styled-components'
import { Paper, Avatar as MuiAvatar } from '@material-ui/core'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'

interface IsSelectedProps {
	isSelected: boolean
}

export const ProfileCardContainer = styled(Paper).withConfig<IsSelectedProps>({
	shouldForwardProp: prop => !['isSelected'].includes(prop)
})`
	padding: 22px;
	width: 100%;
	border-radius: 0px;
	background-color: ${props => (props.isSelected ? props.theme.grey.fifth : undefined)};
	cursor: pointer;
	transition: all 0.3s ease-in-out;

	@media only screen and (max-width: 600px) {
		border-right: 0 none;
	}
`

export const Avatar = styled(MuiAvatar).withConfig<{ lgUp: boolean }>({
	shouldForwardProp: prop => !['lgUp'].includes(prop)
})`
	box-shadow: ${props => props.theme.shadow.first};
	width: 48px;
	height: 48px;
	font-size: ${pxToRem(16)};
	color: ${props => props.theme.tag.white};
`
