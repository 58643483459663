import styled from 'styled-components'
import { Avatar as MuiAvatar } from '@material-ui/core'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'

export const Avatar = styled(MuiAvatar).withConfig<{ lgUp: boolean }>({
	shouldForwardProp: prop => !['lgUp'].includes(prop)
})<{ lgUp: boolean }>`
	width: 64px;
	height: 64px;
	font-size: ${pxToRem(18)};
	color: ${props => props.theme.tag.white};
	box-shadow: ${props => props.theme.shadow.first};
`
