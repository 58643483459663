import { IStoreonModule } from 'storeon'
import jwt from 'jsonwebtoken'
import { GET_ME_ANALYTICS_USER } from 'constants/queries/analyticsUser'
import { client } from 'constants/client'
import { AnalyticsUser, MeQuery } from 'hooks/useApollo'
import { RemoveNullKeys } from 'utils/types'
import { JUser } from 'types'

export interface UserState {
	token: string | null
	user: AnalyticsUser | null
	loadingUser: boolean
	type: string
	__typename: string
}

export interface UserEvents {
	'user/set': RemoveNullKeys<UserState>
	'user/verifyToken': void
	'user/setToken': string
	'user/removeToken': void
	'user/getUser': void
	'user/loading': boolean
	'user/setUser': Partial<NonNullable<UserState['user']>>
	'user/signOut': void
}

export const userModule: IStoreonModule = store => {
	store.on('@init', () => ({
		token: null,
		user: null,
		loadingUser: false
	}))
	store.on('@changed', (_state, data) => {
		if (
			(data?.token && !data?.user) ||
			(data?.token && data?.user && data.__typename !== 'AnalyticsUserQuery')
		) {
			store.dispatch('user/verifyToken')
		}
	})
	store.on('user/verifyToken', async state => {
		if (state.token) {
			const token = jwt.decode(state.token.split('JWT ')[1]) as JUser
			const expiresIn = token.exp * 1000
			if (Date.now() > expiresIn) {
				store.dispatch('user/removeToken')
			} else {
				store.dispatch('user/getUser')
			}
		}
	})
	store.on('user/getUser', async () => {
		store.dispatch('user/loading', true)
		try {
			const { data, error } = await client.query<MeQuery>({
				query: GET_ME_ANALYTICS_USER
			})
			if (error) {
				store.dispatch('user/signOut')
			} else if (data.me) {
				// @ts-ignore
				store.dispatch('user/setUser', data.me)
			}
		} catch {
			store.dispatch('user/signOut')
		} finally {
			store.dispatch('user/loading', false)
		}
	})
	store.on('user/signOut', () => {
		client.resetStore()
		return {
			user: null,
			token: null
		}
	})
	store.on('user/setUser', (state, data) => ({
		user: {
			...((state?.user || {}) as AnalyticsUser),
			...(data || {}),
			isFirstAccess: data?.isFirstAccess ?? false,
			isTourDone: (data?.isTourDone || !data?.isFirstAccess) ?? false
		}
	}))
	store.on('user/loading', (_state, isLoading) => ({
		loadingUser: isLoading,
		loading: isLoading
	}))
	store.on('user/setToken', (_state, token) => ({
		token
	}))
	store.on('user/removeToken', () => ({
		token: null,
		user: null
	}))
	store.on('user/set', (_state, payload) => ({
		token: payload.token,
		user: {
			...payload.user,
			isTourDone: payload.user?.isTourDone || !payload.user?.isFirstAccess
		},
		__typename: payload.__typename
	}))
}
