import React, { useMemo, useEffect } from 'react'
import { useTheme } from 'styled-components'
import { FormProvider, useForm } from 'react-hook-form'
import * as yup from 'yup'
import { yupResolver } from '@hookform/resolvers/yup'

import useIntl from 'hooks/useIntl'
import { Wizard, WizardProps } from 'components/Wizard'
import {
	resetOnboardingStore,
	useOnboardingStore
} from 'screens/Onboarding/hooks/useOnboardingStore'
import HelpGuideDrawer, { HelpGuideDrawerProps } from 'components/HelpGuideDrawer'

import { GuidedChallengesForm } from './GuidedChallenges.types'
import GuidedSkillsSelection from './containers/GuidedSkillsSelection'
import {
	useSkillSelectionStore,
	resetSkillSelectionStore
} from './containers/GuidedSkillsSelection/hooks/useSkillSelectionStore'
import GuidedSkillImportance from './containers/GuidedSkillImportance'
import GuidedTeamCreation from './containers/GuidedTeamCreation'

export interface GuidedChallengesProps {}

const formSchema = yup.object().shape({
	team: yup.object().shape({
		name: yup.string().required(),
		maskChallenge: yup.bool()
	}),
	talentGroup: yup.object().shape({
		name: yup.string().required(),
		type: yup.string().required(),
		color: yup.string().required(),
		teammateEmails: yup.array().of(yup.string().email())
	})
})

export const GuidedChallenges = () => {
	const intl = useIntl()
	const theme = useTheme()
	const { currentStep, showHelpGuide, toggleHelpGuide } = useOnboardingStore(state => state)
	const { currentStep: currentSubStep } = useSkillSelectionStore(state => state)

	useEffect(
		() => () => {
			resetOnboardingStore()
			resetSkillSelectionStore()
		},
		[]
	)

	const formMethods = useForm<GuidedChallengesForm>({
		mode: 'all',
		shouldUnregister: false,
		resolver: yupResolver(formSchema),
		defaultValues: {
			challengePack: 'guided',
			clusters: [],
			chapters: {
				question1: [],
				question2: [],
				question3: []
			},
			mustHaveSkills: [],
			niceToHaveSkills: [],
			team: {
				name: '',
				maskChallenge: false
			},
			talentGroup: {
				name: '',
				type: '',
				color: theme.tag.blue,
				teammateEmails: []
			}
		}
	})

	const steps: WizardProps['stepperLabels'] = useMemo(
		() => [
			{
				label: intl.formatMessage({ id: 'onboarding.guided.challenges.step.0' }),
				sub: [
					intl.formatMessage({ id: 'onboarding.guided.challenges.subStep.0' }),
					intl.formatMessage({ id: 'onboarding.guided.challenges.subStep.1' }),
					intl.formatMessage({ id: 'onboarding.guided.challenges.subStep.2' }),
					intl.formatMessage({ id: 'onboarding.guided.challenges.subStep.3' })
				]
			},
			{
				label: intl.formatMessage({ id: 'onboarding.guided.challenges.step.1' })
			},
			{
				label: intl.formatMessage({ id: 'onboarding.guided.challenges.step.2' })
			}
		],
		[intl]
	)

	const guides: HelpGuideDrawerProps['guides'] = useMemo(
		() =>
			Array.from({ length: 6 }, (_, index) => ({
				title: intl.formatMessage({
					id: `onboarding.guided.challenges.help.guide.title.step.${index}`
				}),
				desc: intl.formatMessage({
					id: `onboarding.guided.challenges.help.guide.desc.step.${index}`
				})
			})),
		[intl]
	)

	const renderStep = () => {
		switch (currentStep) {
			case 0:
				return <GuidedSkillsSelection />
			case 1:
				return <GuidedSkillImportance />
			case 2:
				return <GuidedTeamCreation />
			default:
				return null
		}
	}

	return (
		<>
			<Wizard
				levelContentWithStepper
				currentStep={currentStep}
				previousPath="/"
				subStep={currentSubStep}
				previousPageName={intl.formatMessage({ id: 'dashboard' }).toLowerCase()}
				stepperLabels={steps}
				showMobileStepIndicator={false}
			>
				<FormProvider {...formMethods}>{renderStep()}</FormProvider>
			</Wizard>
			<HelpGuideDrawer
				open={showHelpGuide}
				stepCount={currentStep + currentSubStep}
				onClose={toggleHelpGuide}
				guides={guides}
			/>
		</>
	)
}

export default GuidedChallenges
