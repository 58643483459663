import React from 'react'
import { ApolloProvider } from '@apollo/client'
import { client } from 'constants/client'
import ReactDOM from 'react-dom'
import { BrowserRouter as Router } from 'react-router-dom'
import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'
import store from 'state/store'
import { StoreContext } from 'storeon/react'
import { env } from 'constants/config'
import App from './App'

Sentry.init({
	dsn: 'https://9496e03aff184f6abe951966f9e278c0@o427421.ingest.sentry.io/5718780',
	integrations: [new Integrations.BrowserTracing()],
	enabled: process.env.NODE_ENV === 'production',
	environment: env,
	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
})

ReactDOM.render(
	<ApolloProvider client={client}>
		<StoreContext.Provider value={store}>
			<Router>
				<App />
			</Router>
		</StoreContext.Provider>
	</ApolloProvider>,
	document.getElementById('root')
)
