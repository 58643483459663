import React from 'react'
import { useTheme } from 'styled-components'
import {
	Grid,
	Typography,
	useMediaQuery,
	Theme,
	IconButton,
	Skeleton
} from '@material-ui/core'
import PinIcon from 'assets/icons/PinIcon'
import PinIconOutlined from 'assets/icons/PinIconOutlined'
import BookmarkBorderIcon from '@material-ui/icons/BookmarkBorder'
import BookmarkIcon from '@material-ui/icons/Bookmark'
import CircleProgressBar from 'components/CircleProgressBar'
import ContrastChip from 'components/ContrastChip'
import LinearProgress from 'components/LinearProgress'
import { useIntl } from 'hooks/useIntl'
import { getInitials } from 'utils/string'
import { EnumPipelineType } from 'hooks/useApollo'
import { differenceInDays, format, formatDistanceToNow } from 'date-fns'
import { ProfileCardContainer, Avatar } from './ProfileCard.styles'

export interface ProfileCardProps {
	/**
	 * Defines the display photo of the talent
	 * If not present, it will display the initials of the talent
	 */
	photoUrl?: string
	/**
	 * Defines the first name of the talent
	 */
	firstName: string
	/**
	 * Defines the last name of the talent
	 */
	lastName: string
	/**
	 * Defines the group/s of the talent
	 */
	groups?: { name: string; color: string; id: string }[]
	/**
	 * Defines the talent score
	 */
	score?: number
	/**
	 * Defines the progress
	 */
	progress: number
	/**
	 * Defines the talent type
	 */
	type?: EnumPipelineType
	/**
	 * Defines the state if the user is selected or not
	 * @default false
	 */
	isSelected?: boolean
	/**
	 * Defines the state if the user is bookmarked or not
	 * @default false
	 */
	isBookmarked?: boolean
	/**
	 * Defines if the user is masked
	 * @default false
	 */
	masked?: boolean
	/**
	 * Callback fired when the card is clicked
	 */
	onClick: () => void
	/**
	 * Callback fired when the bookmark button is clicked
	 */
	onBookmark: () => void
	/**
	 * Date as ISO string - YYYY-MM-DDThh:mm:ssZ
	 */
	addedAt: string
	/**
	 * Defines the state if the user is pinned or not
	 * @default false
	 */
	isPinned?: boolean
	/**
	 * Callback fired when the pin button is clicked
	 */
	onPin: () => void
}

export const ProfileCard: React.FC<ProfileCardProps> = ({
	photoUrl,
	firstName,
	lastName,
	groups = [],
	score,
	progress,
	type,
	isSelected = false,
	isBookmarked = false,
	masked = false,
	onClick,
	onBookmark,
	addedAt: addedAtProp,
	isPinned,
	onPin
}) => {
	const theme = useTheme()
	const intl = useIntl()
	const lgUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('lg'))
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('md'))
	const addedAt = new Date(addedAtProp)
	const timeDiff =
		differenceInDays(Date.now(), addedAt) > 7
			? format(addedAt, 'MMM dd, yyyy')
			: formatDistanceToNow(addedAt, {
					addSuffix: true,
					includeSeconds: true
			  })

	return (
		<ProfileCardContainer isSelected={isSelected} onClick={onClick}>
			<Grid item container spacing={3}>
				<Grid item container spacing={2} wrap="nowrap">
					<Grid
						item
						container
						spacing={2}
						direction={smUp ? 'row' : 'column'}
						alignItems={smUp ? 'center' : 'flex-start'}
					>
						<Grid item>
							<Avatar src={photoUrl} lgUp={lgUp}>
								{getInitials(firstName, lastName)}
							</Avatar>
						</Grid>
						<Grid container item xs>
							<Grid item container>
								<Grid item>
									<Typography
										variant="paragraph1"
										fontWeight={500}
										sx={{ color: theme.tag.darkerBlue, mr: 0.5 }}
									>
										{masked ? (
											<Skeleton width={80} animation={false} />
										) : (
											`${firstName} ${lastName}`
										)}
									</Typography>
								</Grid>
								<Grid item>
									<IconButton
										size="small"
										onClick={e => {
											e.stopPropagation()
											onBookmark()
										}}
									>
										{isBookmarked ? (
											<BookmarkIcon color="primary" sx={{ fontSize: '1.3rem' }} />
										) : (
											<BookmarkBorderIcon
												sx={{ fontSize: '1.3rem', color: theme.text.description }}
											/>
										)}
									</IconButton>
								</Grid>
								<Grid item>
									<IconButton
										size="small"
										onClick={e => {
											e.stopPropagation()
											onPin()
										}}
									>
										{isPinned ? (
											<PinIcon
												sx={{
													fill: theme.tag.red,
													fontSize: '1.2rem'
												}}
											/>
										) : (
											<PinIconOutlined
												sx={{ fill: theme.background.light, fontSize: '1.2rem' }}
											/>
										)}
									</IconButton>
								</Grid>
							</Grid>
							<Grid container item>
								<Grid item>
									{type && (
										<Typography
											variant="paragraph2"
											sx={{ textTransform: 'capitalize', color: theme.text.description }}
										>
											{intl.formatMessage({
												id: type === 'Internal' ? 'employee' : 'candidate'
											})}
											&nbsp;
										</Typography>
									)}
								</Grid>
								<Grid item>
									{addedAt && (
										<Typography variant="paragraph2" sx={{ color: theme.text.description }}>
											{lgUp && addedAt ? ' • ' : ''}
											{timeDiff}
										</Typography>
									)}
								</Grid>
							</Grid>
						</Grid>
					</Grid>
					<Grid item>
						{!score || progress !== 100 ? null : (
							<Grid item>
								<CircleProgressBar
									stroke={theme.tag.blue}
									background={theme.progressBarBg}
									percentage={score as number}
									diameter={72}
									strokeWidth={6}
								/>
								<Typography align="center" variant="paragraph3">
									{intl.formatMessage({ id: 'nuggetScore' })}
								</Typography>
							</Grid>
						)}
					</Grid>
				</Grid>
				<Grid container item alignItems="center">
					{groups && (
						<Grid item container xs mb={1} spacing={1}>
							{groups.slice(0, 2).map(group => (
								<Grid item key={group.id}>
									<ContrastChip color={group.color} key={group.id} label={group.name} />
								</Grid>
							))}
							{groups.length > 2 && (
								<Grid item>
									<Typography variant="paragraph2" sx={{ color: theme.text.description }}>
										+ {groups.length - 2}
									</Typography>
								</Grid>
							)}
						</Grid>
					)}
					<Grid item sx={{ width: 140 }}>
						<LinearProgress value={progress} labeled="left" />
					</Grid>
				</Grid>
			</Grid>
		</ProfileCardContainer>
	)
}

export default ProfileCard
