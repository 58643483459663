import React from 'react'

import useIntl from 'hooks/useIntl'
import AnalyticsIcon from 'assets/icons/AnalyticsIcon'
import TextToolTip from 'components/TextToolTip'

export interface IndustryBenchmarkIconProps {
	benchmarked?: boolean
}

export const IndustryBenchmarkIcon = ({ benchmarked }: IndustryBenchmarkIconProps) => {
	const intl = useIntl()
	return (
		<TextToolTip
			style={{ maxWidth: 140 }}
			title={intl.formatMessage({
				id: benchmarked
					? 'onboarding.skill.with.industry.benchmark'
					: 'onboarding.skill.without.industry.benchmark'
			})}
			placement="top"
			arrow
		>
			<div>
				<AnalyticsIcon active={benchmarked} />
			</div>
		</TextToolTip>
	)
}

export default IndustryBenchmarkIcon
