import React from 'react'
import { Grid, Skeleton } from '@material-ui/core'
import { useTheme } from 'styled-components'

import { TalentGroupCardContainer, BorderContainer } from './TalentGroupCard.styles'

export const TalentGroupSkeleton = () => {
	const theme = useTheme()
	return (
		<BorderContainer>
			<TalentGroupCardContainer color={theme.border}>
				<Grid container spacing={3}>
					<Grid item container justifyContent="space-between " alignItems="center">
						<Grid item>
							<Skeleton animation="wave" width={90} height={30} />
						</Grid>
						<Grid item>
							<Skeleton variant="circular" animation="wave" height={26} width={26} />
						</Grid>
					</Grid>
					<Grid item>
						<Skeleton animation="wave" width={150} height={20} />
					</Grid>
				</Grid>
			</TalentGroupCardContainer>
		</BorderContainer>
	)
}

export default TalentGroupSkeleton
