import React from 'react'
import { useRoutes, useParams } from 'react-router-dom'
import { dashboardRoutes } from 'routes/dashboard'
import { useStoreon } from 'storeon/react'
import { Toolbar } from '@material-ui/core'
import { DashboardContainer, DashboardMain } from './DashboardLayout.style'
import { Header } from './components/Header'

const noDashboardRoutes = [
	{ name: 'onboarding/challenges-selection' },
	{ name: 'onboarding/role-based-challenges' },
	{ name: 'onboarding/guided-challenges' },
	{ name: 'onboarding/free-form' }
]

export const DashboardLayout: React.FC = () => {
	const routes = useRoutes(dashboardRoutes)
	const params = useParams()

	const noDashboardRoute = noDashboardRoutes.find(route => {
		if (route.name.includes('/*')) {
			if (params['*'].includes(route.name.slice(0, -1))) {
				return !!params['*'].split('/')[1]
			}
			return false
		}
		return params['*'].includes(route.name)
	})

	const { dispatch, user, theme } = useStoreon('user', 'theme')

	if (noDashboardRoute) {
		return routes
	}

	return (
		<DashboardContainer>
			<Header
				firstName={user?.firstName}
				lastName={user?.lastName}
				photoUrl={user?.photoUrl}
				openDrawer={() => console.log('open drawer')}
				isDark={theme === 'dark'}
				toggleTheme={() => dispatch('theme/switch')}
				signOut={() => dispatch('user/signOut')}
			/>
			<DashboardMain>
				<Toolbar />
				{routes}
			</DashboardMain>
		</DashboardContainer>
	)
}

export default DashboardLayout
