import React from 'react'
import { useTheme } from 'styled-components'
import type { InputBaseProps } from '@material-ui/core'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import { SearchContainer, SearchInputBase } from './SearchInput.style'

type Props = Omit<InputBaseProps, 'ref'> & {
	containerStyle?: React.CSSProperties
}

export const SearchInput = React.forwardRef(({ containerStyle, ...props }: Props, ref) => {
	const theme = useTheme()
	return (
		<SearchContainer style={{ fontSize: pxToRem(16), ...containerStyle }}>
			<i className="bx bx-search" />
			{/* eslint-disable-next-line @typescript-eslint/ban-ts-comment */}
			{/* @ts-ignore */}
			<SearchInputBase
				{...props}
				ref={ref}
				sx={{ input: { '&::placeholder': { color: theme.text.description } } }}
			/>
		</SearchContainer>
	)
})

export default SearchInput
