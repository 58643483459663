import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { useTypedController } from '@hookform/strictly-typed'

export interface EditTalentForm {
	groups: string[]
}

export type IuseEditTalentForm = ReturnType<typeof useEditTalentForm>

export interface UseEditTalentFormProps extends EditTalentForm {}

export const useEditTalentForm = ({ groups }: UseEditTalentFormProps) => {
	const validationSchema = yup.object({
		groups: yup.array().of(yup.string().required())
	})

	const {
		watch,
		control,
		errors,
		handleSubmit: onSubmit,
		setValue,
		getValues
	} = useForm<EditTalentForm>({
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			groups
		}
	})

	const handleChange = (_: React.SyntheticEvent<Element, Event>, value: string[]): void => {
		if (value.length > 0) {
			setValue('groups', value)
		}
	}

	const Controller = useTypedController<EditTalentForm>({ control })

	const handleDelete = (s: string) => {
		const newValue = getValues().groups.filter(el => el !== s)
		setValue('groups', newValue)
	}

	const models = { errors, watch }
	const operations = { onSubmit, handleChange, handleDelete }
	const components = { Controller }

	return [models, operations, components] as [
		typeof models,
		typeof operations,
		typeof components
	]
}
