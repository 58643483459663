import React from 'react'
import { useTheme } from 'styled-components'
import type { IconButtonProps } from '@material-ui/core'
import RemoveIcon from '@material-ui/icons/Remove'
import { DangerIconButton } from './RemoveIconButton.style'

export const RemoveIconButton: React.FC<IconButtonProps> = ({ children, ...props }) => {
	const theme = useTheme()
	return (
		<DangerIconButton {...props}>
			{children || (
				<div
					style={{
						display: 'flex',
						alignItems: 'center',
						border: `1px solid ${
							props.disabled ? theme.background.summary : theme.tag.red
						}`,
						borderRadius: 8
					}}
				>
					<RemoveIcon sx={{ height: 18, width: 18 }} />
				</div>
			)}
		</DangerIconButton>
	)
}

export default RemoveIconButton
