import { hexToRgb, hslToRgb } from '@material-ui/core/styles'
import { hexColorRegex, hslColorRegex, rgbColorRegex } from './regexs'

export type ColorsType = 'hex' | 'rgb' | 'hsl'

export interface RGBA {
	r: number
	g: number
	b: number
	a: number
}

const colorsRegex: [ColorsType, RegExp][] = [
	['rgb', rgbColorRegex],
	['hex', hexColorRegex],
	['hsl', hslColorRegex]
]

export function getColorType(color: string): ColorsType | undefined {
	for (let i = 0; i < colorsRegex.length; i++) {
		const [colorType, colorRegex] = colorsRegex[i]
		if (colorRegex.test(color)) {
			return colorType
		}
	}
}

export function getRgbColor(color: string): string {
	const colorType = getColorType(color) as ColorsType
	switch (colorType) {
		case 'rgb':
			return color
		case 'hex':
			return hexToRgb(color)
		case 'hsl':
			return hslToRgb(color)
	}
}

export const hexToRgbA = (hex: string): RGBA => {
	let c
	if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
		c = hex.substring(1).split('')
		if (c.length === 3) {
			c = [c[0], c[0], c[1], c[1], c[2], c[2]]
		}
		c = `0x${c.join('')}`
		return { r: (c >> 16) & 255, g: (c >> 8) & 255, b: c & 255, a: 1 }
	}
	throw new Error('Bad Hex')
}
