import { useState, useEffect } from 'react'
import { gql } from '@apollo/client'
import { write as copy } from 'clipboardy'
import iziToast from 'izitoast'
import {
	Pipeline,
	useCreatePipelineMutation,
	CreatePipelineInput,
	useDeletePipelineMutation,
	useUpdatePipelineMutation,
	UpdatePipelineInput,
	useSendChallengeInviteMutation,
	useBulkAddTalentsMutation,
	GetAssessmentQuery
} from 'hooks/useApollo'
import useIntl from 'hooks/useIntl'
import { getNonNullKeys } from 'utils/object'
import { removeUsersFromPipeline, useTalentContext } from '../context'

type ActiveGroup = Pick<Pipeline, 'id' | 'name' | 'color' | 'type'>

export const useTalentGroups = (groups?: GetAssessmentQuery['assessment']['pipelines']) => {
	const intl = useIntl()
	const [formLoading, setFormLoading] = useState(false)
	const [activeModal, setActiveModal] = useState<
		'edit' | 'delete' | 'reminder' | 'create' | 'invite' | null
	>(null)
	const [, dispatch] = useTalentContext()
	const [filteredGroups, setFilteredGroups] = useState(groups)
	const [activeType, setActiveType] = useState('All')

	useEffect(() => {
		setFilteredGroups(groups)
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(groups)])

	const handleFilter = (type: 'Internal' | 'External' | 'All') => {
		const filterByType = groups?.filter(pipeline =>
			type !== 'All' ? pipeline?.type === type : pipeline
		)
		setFilteredGroups(filterByType)
		setActiveType(type)
	}

	const [activeGroup, setActiveGroup] = useState<ActiveGroup>({
		id: '',
		name: '',
		color: '',
		type: 'Internal'
	})

	const [inviteTalents] = useSendChallengeInviteMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.sendChallengeInvite) {
				iziToast.success({
					message: intl.formatMessage({ id: 'addTalent.toastSucess' })
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const [updatePipeline] = useUpdatePipelineMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.updatePipeline?.id) {
				iziToast.success({
					message: `${activeGroup.name} group updated successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const [deletePipeline] = useDeletePipelineMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.deletePipeline) {
				iziToast.success({
					message: `${activeGroup.name} group deleted successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		},
		update(cache) {
			cache.modify({
				fields: {
					assessment(existingAssessmentRef) {
						cache.modify({
							id: cache.identify(existingAssessmentRef),
							fields: {
								pipelines(existingPipelinesRef = [], { readField }) {
									return existingPipelinesRef.filter(
										pipelinesRef => activeGroup.id !== readField('id', pipelinesRef)
									)
								},
								talents(existingTalentsRef) {
									if (existingTalentsRef) {
										cache.modify({
											id: cache.identify(existingTalentsRef),
											fields: {
												items(existingItemsRef = [], { readField }) {
													return existingItemsRef.filter(
														talentRef =>
															activeGroup.id !== readField('pipelineId', talentRef)
													)
												}
											}
										})
									}
								}
							}
						})
					}
				}
			})
			cache.modify({
				fields: {
					applicants(existingApplicantsRef) {
						if (existingApplicantsRef) {
							cache.modify({
								id: cache.identify(existingApplicantsRef),
								fields: {
									items(existingItemsRef = [], { readField }) {
										return existingItemsRef.filter(
											applicantRef =>
												activeGroup.id !== readField('pipelineId', applicantRef)
										)
									}
								}
							})
						}
					}
				}
			})
			dispatch(removeUsersFromPipeline(activeGroup.id))
		}
	})

	const [bulkAdd] = useBulkAddTalentsMutation({
		onCompleted: () => {
			setFormLoading(false)
			setActiveModal(null)
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const [createPipeline] = useCreatePipelineMutation({
		onCompleted: data => {
			if (data.createPipeline?.id) {
				iziToast.success({
					message: `${data.createPipeline.name} group created successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		},
		update(cache, { data }) {
			cache.modify({
				fields: {
					assessment(existingAssessmentRef) {
						cache.modify({
							id: cache.identify(existingAssessmentRef),
							fields: {
								pipelines(existingPipelinesRef = []) {
									const newPipelineRef = cache.writeFragment({
										data: data?.createPipeline,
										fragment: gql`
											fragment NewPipeline on Pipeline {
												id
												name
												type
												color
												usersTotal
												users {
													id
													isCustom
													email
												}
											}
										`
									})
									return [...existingPipelinesRef, newPipelineRef]
								}
							}
						})
					}
				}
			})
		}
	})

	const handleActiveGroup = (payload: ActiveGroup) => {
		setActiveGroup(payload)
	}

	const handleCreate = async (
		id: string,
		input: CreatePipelineInput,
		emailsToInvite: string[] | undefined = [],
		uploadUsers?: any[]
	) => {
		setFormLoading(true)

		const { data } = await createPipeline({
			variables: {
				assessId: id,
				pipelineInput: input,
				emailsToInvite
			}
		})

		if (data && !uploadUsers) {
			setFormLoading(false)
			setActiveModal(null)
		}

		if (uploadUsers) {
			await bulkAdd({
				variables: {
					pipelineId: data?.createPipeline?.id as string,
					users: uploadUsers
				}
			})
		}
	}

	const handleDelete = (id: string) => {
		setFormLoading(true)
		deletePipeline({ variables: { id } })
	}

	const handleEdit = (id: string, input: UpdatePipelineInput) => {
		setFormLoading(true)
		updatePipeline({
			variables: { id, pipelineInput: input },
			optimisticResponse: {
				updatePipeline: getNonNullKeys({ id, name: input.name || '', color: input.color })
			}
		})
	}

	const handleInvite = async (
		id: string,
		pipelineId: string,
		emailsToInvite: string[],
		uploadedUsers?: any[]
	) => {
		setFormLoading(true)
		if (uploadedUsers) {
			await bulkAdd({
				variables: {
					pipelineId,
					users: uploadedUsers
				}
			})
			iziToast.success({
				message: intl.formatMessage({ id: 'addTalent.toastSucess' })
			})
		} else {
			await inviteTalents({
				variables: {
					assessId: id,
					pipelineId,
					emails: emailsToInvite
				}
			})
		}
	}

	const handleOpenEditModal = () => {
		setActiveModal('edit')
	}

	const handleOpenDeleteModal = () => {
		setActiveModal('delete')
	}

	const handleOpenCreateModal = () => {
		setActiveModal('create')
	}

	const handleOpenReminderModal = () => {
		setActiveModal('reminder')
	}

	const handleOpenInviteModal = () => {
		setActiveModal('invite')
	}

	const handleCloseModal = () => {
		setActiveModal(null)
	}

	const handleCopy = async (link: string, name: string) => {
		await copy(link)
		iziToast.success({
			message: intl.formatMessage({ id: 'invite.challenge.success' }, { name })
		})
	}

	const models = { activeModal, activeGroup, activeType, formLoading, filteredGroups }
	const operations = {
		handleEdit,
		handleCreate,
		handleCopy,
		handleInvite,
		handleOpenEditModal,
		handleOpenDeleteModal,
		handleOpenCreateModal,
		handleOpenReminderModal,
		handleOpenInviteModal,
		handleCloseModal,
		handleDelete,
		handleActiveGroup,
		handleFilter
	}

	return [models, operations] as [typeof models, typeof operations]
}
