import { Chip } from '@material-ui/core'
import styled from 'styled-components'
import { createCustomColor } from '@nuggetai/ui-kit.themes.utils'

export const StyledChip = styled(Chip).withConfig<{ colorContrast: string }>({
	shouldForwardProp: prop => !['colorContrast'].includes(prop)
})`
	background-color: ${props => createCustomColor(props.colorContrast)};
	color: ${props => props.colorContrast};
	font-weight: 500;

	&:not(:last-child) {
		margin-right: 5px;
	}

	& svg {
		fill: ${props => props.colorContrast};
	}
`
