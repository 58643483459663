import React, { useContext } from 'react'
import { Chip, Grid, Radio, Typography } from '@material-ui/core'
import { Info as InfoIcon } from '@material-ui/icons'
import { useTheme } from 'styled-components'

import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import TextToolTip from 'components/TextToolTip'

import { CheckboxIconDisplay } from 'components/Checkbox'
import { ToggleButton } from 'components/ToggleButton'

import { ToggleButtonContext } from '../ToggleButton'

export interface OnboardingToggleButtonItemProps {
	value: string
	title: string
	description: string
	infoMessage?: string
	label?: string
	radio?: boolean
	checked?: boolean
	restricted?: boolean
	icon?: React.ReactNode
	onClick?: (params: { value: string; selected: boolean }) => void
}

export const OnboardingToggleButtonItem: React.FC<OnboardingToggleButtonItemProps> = ({
	value: itemValue,
	title,
	description,
	infoMessage,
	label,
	radio,
	checked,
	restricted,
	onClick,
	icon
}) => {
	const { value: selectedValue } = useContext(ToggleButtonContext)

	const theme = useTheme()

	const selected =
		selectedValue === itemValue || (selectedValue as string[])?.includes(itemValue)

	return (
		<ToggleButton.Item
			fullWidth
			fullHeight
			restricted={restricted}
			value={itemValue}
			onClick={onClick}
		>
			<Grid
				container
				spacing={radio ? 5 : 6}
				justifyContent="flex-start"
				alignItems="stretch"
				flexWrap="nowrap"
			>
				{radio ||
					(checked && (
						<Grid item xs={1}>
							{radio ? (
								restricted ? (
									<LockOutlinedIcon fontSize="small" sx={{ fill: theme.grey.main }} />
								) : (
									<Radio style={{ marginLeft: -12, marginTop: -8 }} checked={selected} />
								)
							) : (
								checked && (
									<CheckboxIconDisplay checked={selected} color="primary" shape="circle" />
								)
							)}
						</Grid>
					))}
				<Grid item xs={12}>
					<Grid
						container
						direction="column"
						spacing={2}
						justifyContent="space-evenly"
						alignItems="stretch"
					>
						{label && (
							<Grid item sx={{ mt: -1 }}>
								<Chip sx={{ borderRadius: '5px' }} label={label} />
							</Grid>
						)}
						<Grid item>
							<Grid container justifyContent="space-between" sx={{ mb: 0.5 }}>
								<Grid item xs>
									<Typography gutterBottom variant="h5">
										{title}
										{infoMessage && (
											<TextToolTip arrow title={infoMessage} placement="top">
												<InfoIcon
													style={{
														color: '#616161',
														marginLeft: 8,
														verticalAlign: 'middle'
													}}
													fontSize="small"
												/>
											</TextToolTip>
										)}
									</Typography>
								</Grid>
								{icon && <Grid item>{icon}</Grid>}
							</Grid>
							<Typography color="secondary" variant="paragraph2">
								{description}
							</Typography>
						</Grid>
					</Grid>
				</Grid>
			</Grid>
		</ToggleButton.Item>
	)
}
