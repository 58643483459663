import React, { useContext, useState } from 'react'
import Modal, { IModalProps } from 'components/Modal'
import { useMediaQuery, Theme, Typography, Grid } from '@material-ui/core'
import useIntl from 'hooks/useIntl'
import { FilterContext } from 'hooks/useFilter'
import ButtonGroupSelect from 'components/ButtonGroupSelect'

export interface FilterModalProps extends Pick<IModalProps, 'open' | 'onClose' | 'onOk'> {}

export const FilterModal: React.FC<FilterModalProps> = ({ open, onClose, onOk }) => {
	const intl = useIntl()
	const [loading, setLoading] = useState(false)
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))

	const { filters, filterValues, setKeyOptions } = useContext(FilterContext)
	const filterKeys = Object.keys(filters)

	const handleClose = () => {
		onClose()
	}

	const handleOk: React.MouseEventHandler<HTMLButtonElement> = async e => {
		setLoading(true)
		await onOk?.(e)
		setLoading(false)
		onClose()
	}

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={handleClose}
			size={mdDown ? 'normal' : 'big'}
			onOk={handleOk}
			okLoading={loading}
			closeOnOk={false}
			onOkDisabled={false}
			title={intl.formatMessage({ id: 'filters' })}
		>
			<Grid container justifyContent="flex-start" spacing={3}>
				{filterKeys.map(key => (
					<Grid item key={key} container spacing={1}>
						<Grid item>
							<Typography variant="paragraph1" fontWeight={500}>
								{filters[key].label}
							</Typography>
						</Grid>
						<Grid item container>
							<ButtonGroupSelect
								options={filters[key].options}
								value={filterValues[key]}
								multiple
								onChange={(_e, value) => {
									setKeyOptions(key, value)
								}}
							/>
						</Grid>
					</Grid>
				))}
			</Grid>
		</Modal>
	)
}

export default FilterModal
