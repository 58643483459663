import styled, { css } from 'styled-components'
import { Slider } from '@material-ui/core'

export const StyledSlider = styled(Slider)<{ size: number }>`
	.MuiSlider-thumb {
		height: ${props => props.size}px;
		width: ${props => props.size}px;
		margin-top: ${props => -(props.size / 2 - (props.size <= 18 ? 1 : 2))}px;
		${props =>
			props.size <= 18 &&
			css`
				border: 1px solid white;
			`}
		&:last-child {
			margin-left: ${props => -props.size}px;
		}
	}
	.MuiSlider-valueLabel {
		margin-left: ${props => (props.size > 15 ? props.size - 15 : 0)}px;
	}
`
