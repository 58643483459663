import React, { useCallback, useState } from 'react'
import { useFormContext, Controller } from 'react-hook-form'
import { Grid } from '@material-ui/core'
import debounce from 'lodash.debounce'
import { useNavigate } from 'react-router-dom'

import SearchInput from 'components/SearchInput'
import useIntl from 'hooks/useIntl'
import { Chapter, useGetChaptersQuery } from 'hooks/useApollo'
import { CreationContent } from 'components/CreationContent'
import { OnboardingToggleButtonItem, ToggleButton } from 'components/ToggleButton'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { SelectionContainer } from 'screens/Onboarding/components/SelectionContainer'
import WizardFooter from 'components/Wizard/WizardFooter'

import { FreeFormType } from '../../FreeForm.types'
import { useFreeFormStore } from '../../hooks/useFreeFormStore'
import { CardsContainer } from './FreeFormSkillsSelection.style'

export interface FreeFormChapterSelectionProps {}

export const FreeFormChapterSelection = () => {
	const intl = useIntl()
	const [name, setName] = useState('')
	const navigate = useNavigate()
	const [debounceSearch, setDebounceSearch] = useState('')
	const debouncedSearch = useCallback(
		debounce(nextValue => setDebounceSearch(nextValue), 300),
		[]
	)
	const { nextStep, currentStep, toggleHelpGuide } = useOnboardingStore(state => state)
	const { setSelectedChapters, setChapters, chapters } = useFreeFormStore(state => state)
	const { control, watch } = useFormContext<FreeFormType>()

	useGetChaptersQuery({
		variables: { filters: { isSJT: true, name: debounceSearch }, orderBy: { name: 'asc' } },
		onCompleted: ({ chapters }) => {
			setChapters(chapters as Chapter[])
		}
	})

	const selectedChapters = watch('chapters')

	const handleContinue = () => {
		const selected = chapters.filter(chapter => selectedChapters.includes(chapter.id))
		setSelectedChapters(selected)
		nextStep()
	}

	const handleSearch: React.ChangeEventHandler<
		HTMLTextAreaElement | HTMLInputElement
	> = e => {
		const { value: nextValue } = e.target
		setName(nextValue)
		debouncedSearch(nextValue)
	}

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent
					subHeader
					title={intl.formatMessage({
						id: `onboarding.free.form.step.${currentStep}.title`
					})}
					desc={intl.formatMessage({
						id: `onboarding.free.form.step.${currentStep}.description`
					})}
					subTitle={intl.formatMessage({
						id: 'onboarding.select.free.form.title'
					})}
				>
					<Grid container spacing={4}>
						<Grid item xs={12}>
							<Grid item xs={12} sx={{ mb: 4 }}>
								<SearchInput
									containerStyle={{ height: 38 }}
									value={name}
									placeholder={intl.formatMessage({ id: 'teams.searchGroupPlaceholder' })}
									onChange={handleSearch}
								/>
							</Grid>
							<CardsContainer item xs={12}>
								<SelectionContainer>
									<Controller
										control={control}
										name="chapters"
										render={({ onChange, value }) => (
											<ToggleButton value={value} onChange={value => onChange(value)}>
												{chapters.map((chapter, index) => (
													<Grid item key={index} xs={12} md={6} lg={4}>
														<OnboardingToggleButtonItem
															value={chapter?.id || ''}
															title={chapter?.name || ''}
															description={chapter?.desc || ''}
														/>
													</Grid>
												))}
											</ToggleButton>
										)}
									/>
								</SelectionContainer>
							</CardsContainer>
						</Grid>
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				continueDisabled={selectedChapters.length < 3}
				onContinue={handleContinue}
				onBack={() => navigate('/onboarding/challenges-selection')}
			/>
		</>
	)
}

export default FreeFormChapterSelection
