import React from 'react'
import { IconButtonProps, Hidden } from '@material-ui/core'
import { useTheme } from 'styled-components'
import { ButtonStyle } from './CustomArrow.style'

export interface ArrowProps extends IconButtonProps {
	/**
	 * @default 'right'
	 */
	direction?: 'left' | 'right'
	ended?: boolean
	onClick?: () => void
}

export const CustomArrow = React.forwardRef<HTMLButtonElement, ArrowProps>(
	({ direction = 'right', ended, onClick, ...props }, ref) => {
		const theme = useTheme()
		return (
			<Hidden mdUp>
				<ButtonStyle
					{...props}
					disabled={ended}
					ref={ref}
					disableTouchRipple
					onClick={onClick}
					sx={{ color: theme.primary.main }}
					direction={direction}
				>
					{direction === 'right' ? (
						<i className="bx bx-chevron-right-circle" />
					) : (
						<i className="bx bx-chevron-left-circle" />
					)}
				</ButtonStyle>
			</Hidden>
		)
	}
)

export default CustomArrow
