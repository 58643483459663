import React from 'react'
import { Chip, Skeleton } from '@material-ui/core'

import { PinIcon } from 'assets/icons/PinIcon'
import { useTheme } from 'styled-components'
import { PinnedUsers } from 'screens/Teams/containers/Talents/context'

export interface TalentChipProps {
	user: PinnedUsers
	pinned?: boolean
	onPinToggle: (user: PinnedUsers) => void
}

export const TalentChip: React.FC<TalentChipProps> = ({ user, pinned, onPinToggle }) => {
	const theme = useTheme()

	const { firstName, lastName, masked } = user

	const handlePinToggle = () => onPinToggle(user)

	const deleteIcon = (
		<PinIcon
			style={{
				fill: pinned ? theme.tag.red : theme.grey.third,
				fontSize: '1rem',
				marginLeft: 4
			}}
		/>
	)

	return (
		<Chip
			label={masked ? <Skeleton width={70} /> : `${firstName} ${lastName}`}
			deleteIcon={deleteIcon}
			onClick={handlePinToggle}
			onDelete={handlePinToggle}
			size="small"
		/>
	)
}
