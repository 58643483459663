/* eslint-disable indent */
import React from 'react'
import { useFormContext } from 'react-hook-form'

import useIntl from 'hooks/useIntl'
import { EnumPipelineType } from 'hooks/useApollo'
import TeamDetailsForm from 'screens/Onboarding/components/TeamDetailsForm'
import { useCreateAssessment } from 'screens/Onboarding/hooks/useCreateAssessment'

import {
	useOnboardingStore,
	resetOnboardingStore
} from '../../../../hooks/useOnboardingStore'
import { resetSkillSelectionStore } from '../GuidedSkillsSelection/hooks/useSkillSelectionStore'
import { GuidedChallengesForm } from '../../GuidedChallenges.types'

export const GuidedTeamCreation: React.FC = () => {
	const intl = useIntl()
	const { control, getValues, formState } = useFormContext<GuidedChallengesForm>()
	const { currentStep, prevStep, toggleHelpGuide } = useOnboardingStore(state => state)

	const resetStore = () => {
		resetOnboardingStore()
		resetSkillSelectionStore()
	}
	const [{ loading }, { createAssessment }] = useCreateAssessment(resetStore)

	const handleBack = () => {
		prevStep()
	}

	const handleContinue = () => {
		const {
			team,
			talentGroup,
			mustHaveSkills,
			niceToHaveSkills,
			challengePack
		} = getValues()

		createAssessment({
			variables: {
				assessmentInput: {
					challengePack,
					name: team.name,
					masking: team.maskChallenge,
					chapters: [...mustHaveSkills, ...niceToHaveSkills],
					objective: 'Benchmarking'
				},
				emailsToInvite: talentGroup.teammateEmails,
				pipelineInput: {
					name: talentGroup.name,
					color: talentGroup.color,
					type: talentGroup.type as EnumPipelineType
				}
			}
		})
	}

	return (
		<TeamDetailsForm
			title={intl.formatMessage({
				id: `onboarding.guided.challenges.step.${currentStep}.title`
			})}
			subTitle={intl.formatMessage({ id: 'onboarding.select.guided.challenges.title' })}
			onBack={handleBack}
			onContinue={handleContinue}
			control={control}
			loading={loading}
			isValid={!formState.isValid}
			currentStep={currentStep}
			onShowHelpGuide={toggleHelpGuide}
		/>
	)
}

export default GuidedTeamCreation
