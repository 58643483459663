import React, { useState } from 'react'
import { Box, Grid, Typography, useMediaQuery, Theme } from '@material-ui/core'
import { useTheme } from 'styled-components'

import { PinIcon } from 'assets/icons/PinIcon'
import { useIntl } from 'hooks/useIntl'
import { setPinnedUsers, useTalentContext } from '../Talents/context'
import { TalentChip, TalentChipProps } from './components/TalentChip'
import { TeamDynamicChart } from './components/TeamDynamicChart'
import { useChartData } from './hooks/useChartData'
import { TalentGroupToggle, ToggleColor } from './TeamDynamic.styles'

export const TeamDynamic: React.FC = () => {
	const theme = useTheme()
	const intl = useIntl()
	const [{ selectedTalent, pinnedUsers }, dispatch] = useTalentContext()
	const { chartSkills, chartData, pipelines } = useChartData()

	const [groupSelectionMapping, setGroupSelectionMapping] = useState(
		pipelines.reduce(
			(mapping, pipeline) => {
				mapping[pipeline?.id as string] = false

				return mapping
			},
			{ talent: true } as Record<string, boolean>
		) || {}
	)

	const lgDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('lg'))

	const handleGroupToggle = (id: string) =>
		setGroupSelectionMapping(prev => {
			const currentStatus = prev[id]

			return {
				...prev,
				[id]: !currentStatus
			}
		})

	const handlePinToggle = (user: TalentChipProps['user']) => dispatch(setPinnedUsers(user))

	return (
		<Grid container alignItems="center" spacing={2}>
			<Grid item style={{ height: lgDown ? 350 : 450 }} xs={12}>
				<TeamDynamicChart
					data={chartData}
					skills={chartSkills.filter(({ id }) => groupSelectionMapping[id])}
				/>
			</Grid>
			<Grid item container alignItems="center" spacing={6} xs={12}>
				<Grid item container alignItems="center" spacing={3}>
					{chartSkills.map(({ id, name, color }) => (
						<Grid item key={id}>
							<TalentGroupToggle onClick={() => handleGroupToggle(id)}>
								<ToggleColor color={color} />
								<Typography
									variant="paragraph1"
									component="span"
									sx={{
										color: groupSelectionMapping[id as string] ? 'secondary' : theme.border
									}}
								>
									{name}
								</Typography>
							</TalentGroupToggle>
						</Grid>
					))}
				</Grid>
				<Grid item>
					<Typography component="span" variant="paragraph1" sx={{ display: 'flex' }}>
						{intl.formatMessage({ id: 'teams.talent' })}
						<span style={{ color: theme.tag.red, fontSize: '1rem' }}>
							<PinIcon
								color="inherit"
								style={{ marginLeft: 5, marginTop: 4, fontSize: 'inherit' }}
							/>
						</span>
					</Typography>
					<Box sx={{ mb: 2 }}>
						<Typography gutterBottom color="secondary" variant="paragraph2">
							{intl.formatMessage({
								id: 'teams.talentDynamic.talent.description'
							})}
						</Typography>
					</Box>
					<Grid container spacing={2}>
						{selectedTalent.id && (
							<Grid item>
								<TalentChip
									user={selectedTalent}
									pinned={!!pinnedUsers?.[selectedTalent.id]}
									onPinToggle={handlePinToggle}
								/>
							</Grid>
						)}
						{Object.values(pinnedUsers || {}).map(user =>
							user.id === selectedTalent.id ? null : (
								<Grid item key={user.id}>
									<TalentChip
										pinned
										user={{
											id: user.id,
											firstName: user.firstName,
											lastName: user.lastName,
											pipelineId: user.pipelineId,
											pipelineType: user.pipelineType,
											pipelinesId: user.pipelinesId,
											masked: user.masked,
											score: user.score
										}}
										onPinToggle={handlePinToggle}
									/>
								</Grid>
							)
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	)
}

export default TeamDynamic
