import { isLocal, getLocalUrl } from 'utils/url'

export const env = process.env.REACT_APP_MODE || 'dev'

export const IS_DEV = env === 'dev'

export const SERVER_URL = process.env.REACT_APP_API_URL

export const API_URL = `${SERVER_URL}/api/v2`

export const ASSESSMENT_URL = process.env.REACT_APP_ASSESSMENT_URL

export const TALENT_URL = process.env.REACT_APP_SANDBOX_URL

interface GetAssessmentLinkArgs {
	demo: boolean
	assessId: string
	userId: string
	pipelineId: string
}

export const getAssessmentLink = ({
	assessId,
	demo,
	userId,
	pipelineId
}: GetAssessmentLinkArgs) => {
	return `${ASSESSMENT_URL}/${
		demo ? `demo/${userId}` : `assessment/${assessId}`
	}/${pipelineId}/default`
}

interface GetPreviewLinkArgs {
	categoryId: string
	challengeId: string
	chapterId: string
}

export const getPreviewLink = ({
	categoryId,
	challengeId,
	chapterId
}: GetPreviewLinkArgs) => {
	return `${ASSESSMENT_URL}/preview/${categoryId}/${challengeId}/${chapterId}`
}
