import React, { useEffect, useState } from 'react'
import {
	Button,
	Grid,
	IconButton,
	Typography,
	Skeleton,
	Menu,
	Divider,
	Switch,
	Hidden
} from '@material-ui/core'
import LockOutlinedIcon from '@material-ui/icons/LockOutlined'
import { useTheme } from 'styled-components'
import EditIcon from '@material-ui/icons/Edit'
import { MoreHoriz as MoreIcon, Add as PlusIcon } from '@material-ui/icons'
import useIntl from 'hooks/useIntl'
import AddTalentModal from 'components/AddTalentModal'
import useToggle from 'hooks/useToggle'
import PopupMenuItem from 'components/PopupMenuItem'
import EditModal from 'components/EditModal'
import DeleteModal from 'components/DeleteModal'
import useToggleAnchor from 'hooks/useToggleAnchor'
import { GetAssessmentQuery } from 'hooks/useApollo'
import ChallengeDetailsModal from 'components/ChallengeDetailsModal'
import useUserSubscriptions from 'hooks/useUserSubscriptions'
import { getAssessmentLink, getPreviewLink } from 'constants/config'
import AddTalentDialButton, {
	AddTalentDialButtonProps
} from 'components/AddTalentDialButton'
import CreateTalentGroupModal from 'components/CreateTalenGroupModal'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import { useStoreon } from 'storeon/react'
import { useTeamDescription } from './useTeamDescription'
import { useTalentGroups } from '../Talents/TalentGroups/useTalentGroups'

export interface TeamDescriptionProps {
	/**
	 * Defines the name of the team
	 */
	name: string
	/**
	 * Defines the masking status of the team
	 * @default false
	 */
	masking: boolean
	/**
	 * Defines the loading state of the component
	 */
	loading: boolean
	/**
	 * Defines the list of pipelines
	 */
	pipelines: GetAssessmentQuery['assessment']['pipelines']
	/**
	 * Defines the list of pipelines
	 */
	chapters: GetAssessmentQuery['assessment']['chapters']
}

const teamSelector = (store: TeamContextProps) => ({
	id: store.teamId,
	demo: store.demo,
	challengeId: store.assessment?.challengeId as string,
	categoryId: store.assessment?.categoryId as string
})

export const TeamDescription: React.FC<TeamDescriptionProps> = ({
	name,
	loading,
	pipelines,
	chapters,
	masking
}) => {
	const intl = useIntl()
	const { user } = useStoreon('user')
	const { id, demo, challengeId, categoryId } = useTeamContext(teamSelector)
	const theme = useTheme()
	const [masked, setMasked] = useState(masking)
	const [{ open: isChallengeModalOpen }, challengeModalOps] = useToggle()
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const [{ trialExpiredWithoutSubscription }] = useUserSubscriptions()

	useEffect(() => {
		setMasked(masking)
	}, [masking])

	const [
		{ activeAssessment, activeModal, formLoading },
		{
			handleEdit,
			handleDelete,
			handleMasking,
			handleCloseModal,
			handleOpenEditModal,
			handleOpenDeleteModal,
			handleActiveAssessment
		}
	] = useTeamDescription()

	const [
		{ formLoading: formGroupLoading, activeModal: activeGroupModal },
		{
			handleCloseModal: handleCloseGroupModal,
			handleCreate: handleCreateGroup,
			handleOpenCreateModal,
			handleOpenInviteModal,
			handleInvite
		}
	] = useTalentGroups()

	const handleMask = () => {
		const inverse = !masked
		setMasked(inverse)
		handleMasking(id, inverse)
	}

	return (
		<>
			<Grid container item xs={12} md={6} spacing={2}>
				<Grid container item alignItems="center" spacing={1}>
					<Grid item>
						<Typography variant="h3" fontWeight={500}>
							{loading ? <Skeleton animation="wave" width={100} /> : name}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton
							name="team card menu"
							aria-label="team card menu"
							aria-controls={`team-${id}-card`}
							aria-haspopup="true"
							size="small"
							onClick={e => {
								handleOpenAnchor(e)
								handleActiveAssessment({ id, name, masking })
							}}
						>
							<MoreIcon color="secondary" />
						</IconButton>
						<Menu
							id={`team-${id}-menu`}
							anchorEl={anchorEl}
							keepMounted
							open={Boolean(anchorEl)}
							onClose={handleCloseAnchor}
							MenuListProps={{
								disablePadding: true
							}}
							PaperProps={{
								elevation: 9,
								style: {
									maxWidth: 210,
									width: '100%'
								}
							}}
							getContentAnchorEl={null}
							anchorOrigin={{
								vertical: 'bottom',
								horizontal: 'center'
							}}
							transformOrigin={{
								vertical: 'top',
								horizontal: 'right'
							}}
						>
							<PopupMenuItem
								primary={
									// eslint-disable-next-line react/jsx-wrap-multilines
									<Grid container justifyContent="space-between" alignItems="center">
										{intl.formatMessage({ id: 'teamCardMenu.edit.primary' })}
										<EditIcon sx={{ fontSize: 16, mr: 0.5 }} />
									</Grid>
								}
								secondary={intl.formatMessage({ id: 'teamCardMenu.edit.secondary' })}
								onClick={e => {
									e.stopPropagation()
									handleOpenEditModal()
									handleCloseAnchor()
								}}
							/>
							<Divider />
							<PopupMenuItem
								style={{ padding: '0px 10px' }}
								primary={
									// eslint-disable-next-line react/jsx-wrap-multilines
									<Grid container justifyContent="space-between" alignItems="center">
										{intl.formatMessage({ id: 'teamCardMenu.mask.primary' })}
										<Switch
											color="primary"
											onClick={e => e.stopPropagation()}
											checked={masked}
											onChange={handleMask}
											inputProps={{ 'aria-label': 'secondary checkbox' }}
										/>
									</Grid>
								}
								secondary={intl.formatMessage({ id: 'teamCardMenu.mask.secondary' })}
								onClick={e => {
									e.preventDefault()
									handleMask()
								}}
							/>
							<Divider />
							{!demo && (
								<PopupMenuItem
									danger
									primary={intl.formatMessage({ id: 'teamCardMenu.delete.primary' })}
									secondary={intl.formatMessage({ id: 'teamCardMenu.delete.secondary' })}
									onClick={e => {
										e.stopPropagation()
										handleOpenDeleteModal()
										handleCloseAnchor()
									}}
								/>
							)}
						</Menu>
					</Grid>
				</Grid>
				{/* <Grid item xs={12}>
					{loading ? (
						<Skeleton animation="wave" width={150} />
					) : (
						<ButtonLink name="challengeDetails" onClick={challengeModalOps.handleOpen}>
							{intl.formatMessage({ id: 'teams.challengeDetails' })}
						</ButtonLink>
					)}
				</Grid> */}
			</Grid>
			{!demo && (
				<Hidden mdDown>
					<Grid container item xs={12} md={6} justifyContent="flex-end" alignItems="center">
						<Button
							name="addTalents"
							id="team-tours-add-talent-button"
							disabled={trialExpiredWithoutSubscription || demo}
							variant="contained"
							startIcon={
								trialExpiredWithoutSubscription ? (
									<LockOutlinedIcon sx={{ fill: theme.background.summary }} />
								) : (
									<PlusIcon />
								)
							}
							fullWidth
							style={{ width: 'fit-content' }}
							onClick={handleOpenInviteModal}
						>
							{intl.formatMessage({ id: 'teams.addTalent' })}
						</Button>
					</Grid>
				</Hidden>
			)}
			<AddTalentModal
				pipelines={pipelines}
				teamId={id}
				demo={demo}
				loading={formGroupLoading}
				open={activeGroupModal === 'invite'}
				onClose={handleCloseGroupModal}
				getLink={pipelineId => {
					return getAssessmentLink({
						assessId: id,
						demo,
						pipelineId,
						userId: user?.id as string
					})
				}}
				onInvite={handleInvite}
			/>
			<ChallengeDetailsModal
				modalProps={{
					open: isChallengeModalOpen,
					maxWidth: 'md',
					onClose: () => challengeModalOps.handleClose()
				}}
				chapters={chapters || []}
				getPreviewLink={chapterId => {
					return getPreviewLink({
						chapterId,
						categoryId,
						challengeId
					})
				}}
			/>
			{activeModal === 'edit' && (
				<EditModal
					field="name"
					loading={formLoading}
					id={activeAssessment.id || ''}
					value={activeAssessment.name || ''}
					open={activeModal === 'edit'}
					onClose={handleCloseModal}
					onFinish={handleEdit}
				/>
			)}
			<DeleteModal
				loading={formLoading}
				id={activeAssessment.id || ''}
				title={activeAssessment.name || ''}
				open={activeModal === 'delete'}
				onClose={handleCloseModal}
				onDelete={handleDelete}
			/>
			<Hidden mdUp>
				<AddTalentDialButton
					actions={
						[
							!demo && {
								buttonText: intl.formatMessage({ id: 'teams.addTalent' }),
								onClick: handleOpenInviteModal,
								disabled: trialExpiredWithoutSubscription
							},
							{
								buttonText: intl.formatMessage({ id: 'teams.createGroup' }),
								onClick: handleOpenCreateModal,
								disabled: trialExpiredWithoutSubscription
							}
						].filter(Boolean) as AddTalentDialButtonProps['actions']
					}
				/>
			</Hidden>
			<CreateTalentGroupModal
				id={id}
				demo={demo}
				loading={formGroupLoading}
				link="https://www.nugget.ai"
				open={activeGroupModal === 'create'}
				onClose={handleCloseGroupModal}
				onCreate={handleCreateGroup}
			/>
		</>
	)
}

export default TeamDescription
