import { PrimitiveKeys } from './types'

export function createArray(n: number): number[] {
	return new Array(n).fill(null).map((_, i) => i)
}

export const chunkArray = <T extends Array<any>, U = T[number]>(
	myArray: T,
	chunkSize: number
): U[][] => {
	const tempArray: U[][] = []

	for (let i = 0; i < myArray.length; i += chunkSize) {
		const myChunk = myArray.slice(i, i + chunkSize)
		tempArray.push(myChunk)
	}

	return tempArray
}

export const arrayEquals = (a: any[], b: any[]) => {
	return (
		Array.isArray(a) &&
		Array.isArray(b) &&
		a.length === b.length &&
		a.every((val, index) => val === b[index])
	)
}

type IGrouped<T, K extends PrimitiveKeys<T>> = {
	[P in T[K] extends string ? T[K] : string]?: T[]
}

export const groupByKey = <T, K extends PrimitiveKeys<T>>(
	items: T[],
	key: K
): IGrouped<T, K> => {
	return items.reduce((a, b) => {
		// @ts-ignore
		a[b[key]] = [...(a[b[key]] || []), b]
		return a
	}, {})
}
