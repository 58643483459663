import React from 'react'
import { useTheme } from 'styled-components'
import { Button, Grid } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'

import { ContinueButton } from 'components/ContinueButton'
import { useIntl } from 'hooks/useIntl'

export interface StepNavigationProps {
	continueDisabled?: boolean
	continuePending?: boolean
	finish?: boolean
	loading?: boolean
	onBack?: () => void
	onContinue?: () => void
}

export const StepNavigation: React.FC<StepNavigationProps> = ({
	continueDisabled,
	continuePending,
	finish,
	onBack,
	onContinue
}) => {
	const intl = useIntl()
	const theme = useTheme()

	return (
		<Grid>
			<Grid container spacing={2}>
				{onBack && (
					<Grid
						item
						sx={{
							maxWidth: { sm: '200px' }
						}}
					>
						<Button
							fullWidth
							variant="contained"
							sx={{
								color: theme.tag.darkerBlue,
								backgroundColor: alpha(theme.tag.darkerBlue, 0.1),
								'&:hover': { backgroundColor: alpha(theme.tag.darkerBlue, 0.2) },
								transition: 'background-color 0.5s ease-in-out'
							}}
							onClick={onBack}
							name="backStep"
						>
							{intl.formatMessage({ id: 'back' })}
						</Button>
					</Grid>
				)}
				{onContinue && (
					<Grid
						item
						sx={{
							maxWidth: { sm: '200px' }
						}}
					>
						<ContinueButton
							fullWidth
							endIcon={<ArrowRightAltIcon fontSize="small" />}
							variant="contained"
							disabled={continueDisabled}
							pending={continuePending}
							isFinish={finish}
							onClick={onContinue}
						/>
					</Grid>
				)}
			</Grid>
		</Grid>
	)
}
