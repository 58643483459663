import React from 'react'
import { Link } from 'react-router-dom'
import {
	Box,
	Divider,
	TextField,
	TextFieldProps,
	Grid,
	InputAdornment,
	Typography
} from '@material-ui/core'
import { LoadingButton } from '@material-ui/lab'
import { useForm } from 'react-hook-form'

import { SignInUserInput, useSignInMutation } from 'hooks/useApollo'
import { useIntl } from 'hooks/useIntl'
import { InputCheckIcon } from 'components/InputCheckIcon'
import { PasswordInput } from 'components/PasswordInput'
import { SocialButton } from '@nuggetai/ui-kit.molecules.social-button'
import GoogleIcon from '@nuggetai/ui-kit.icons.google'
// import FacebookIcon from '@nuggetai/ui-kit.icons.facebook'
import { emailRegex } from 'utils/regexs'
import { useStoreon } from 'storeon/react'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'
import { createOAuthUrl } from 'utils/url'

const noErrorProps: TextFieldProps['InputProps'] = {
	endAdornment: (
		<InputAdornment position="end">
			<InputCheckIcon />
		</InputAdornment>
	)
}

export const Login = () => {
	const {
		register,
		errors,
		watch,
		formState,
		handleSubmit,
		setError
	} = useForm<SignInUserInput>({
		mode: 'all'
	})
	const intl = useIntl()
	const { dispatch } = useStoreon()
	const allFields = watch()
	const [signIn, { loading }] = useSignInMutation({
		onCompleted: finalData => {
			if (finalData?.signIn?.token) {
				// @ts-ignore
				dispatch('user/set', finalData.signIn)
			}
		},
		onError: ({ graphQLErrors }) => {
			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	const onSubmit = () => {
		signIn({
			variables: {
				userInput: allFields
			}
		})
	}

	return (
		<form onSubmit={handleSubmit(onSubmit)}>
			<Grid container direction="column" spacing={3}>
				<Grid item container direction="column" spacing={2}>
					<Grid item>
						<SocialButton
							href={createOAuthUrl('google')}
							label={intl.formatMessage({ id: 'auth.signInWith' }, { name: 'Google' })}
							icon={<GoogleIcon />}
						/>
					</Grid>
					{/* <Grid item>
						<SocialButton
							href={createOAuthUrl('facebook')}
							label={intl.formatMessage({ id: 'auth.signInWith' }, { name: 'Facebook' })}
							icon={<FacebookIcon />}
						/>
					</Grid> */}
				</Grid>
				<Grid item>
					<Divider>{intl.formatMessage({ id: 'or' })}</Divider>
				</Grid>
				<Grid item>
					<TextField
						required
						name="email"
						type="email"
						error={!!errors.email}
						helperText={
							errors?.email?.type === 'validate' ? errors?.email?.message : undefined
						}
						inputRef={register({ required: true, pattern: emailRegex })}
						label={intl.formatMessage({ id: 'email' })}
						placeholder="ex. john@gmail.com"
						InputProps={allFields.email && !errors.email ? noErrorProps : {}}
					/>
				</Grid>
				<Grid item container direction="column" spacing={1}>
					<Grid item sx={{ position: 'relative' }}>
						<PasswordInput
							required
							error={!!errors.password}
							helperText={
								errors?.password?.type === 'validate'
									? errors?.password?.message
									: undefined
							}
							label={intl.formatMessage({ id: 'password' })}
							isCorrect={allFields.password ? !errors.password : false}
							inputRef={register({ required: true })}
						/>
						<Grid sx={{ position: 'absolute', right: 0, top: '5px' }}>
							<Typography variant="paragraph3">
								<Link to="/auth/password-reset">
									{intl.formatMessage({ id: 'auth.forgotPassword' })}
								</Link>
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item container direction="column" spacing={2}>
					<Grid item>
						<Box marginTop={1}>
							<LoadingButton
								disabled={!formState.isValid}
								pending={loading}
								type="submit"
								fullWidth
								variant="contained"
								sx={{ fontSize: pxToRem(14) }}
							>
								{intl.formatMessage({ id: 'auth.login' })}
							</LoadingButton>
						</Box>
					</Grid>
					<Grid item container justifyContent="center">
						<Typography variant="paragraph3" color="secondary" align="center">
							{intl.formatMessage({ id: 'auth.dontHaveAnAccount' })}{' '}
							<Link to="/auth/signup">{intl.formatMessage({ id: 'auth.signUp' })}</Link>
						</Typography>
					</Grid>
				</Grid>
			</Grid>
		</form>
	)
}

export default Login
