import { Paper } from '@material-ui/core'
import styled from 'styled-components'

export const TalentProfileContainer = styled(Paper)`
	height: 100%;
	width: 100%;
	padding: 0px 120px 40px 60px;
	overflow-y: auto;
	min-height: 1000px;
	max-height: 1000px;

	@media only screen and (max-width: 1280px) {
		padding-left: 30px;
		padding-right: 90px;
	}

	@media only screen and (max-width: 968px) {
		overflow-y: inherit;
		min-height: 600px;
		max-height: none;
		padding-left: 15px;
		padding-right: 15px;
	}
`
