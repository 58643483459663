/**
 * This is an adaptation to improve typescript
 * @author https://github.com/fitzmode
 * @repo https://github.com/fitzmode/use-dynamic-refs
 */

import React from 'react'
import useConstant from './useConstant'

const setRef = <T = Element>(map: Map<string, React.RefObject<T>>) => (
	key: string
): React.RefObject<T> => {
	const ref = React.createRef<T>()
	map.set(key, ref)
	return ref
}

const getRef = <T = Element>(map: Map<string, React.RefObject<T>>) => (
	key: string
): React.RefObject<T> | undefined => map.get(key)

export const useDynamicRefs = <T = Element>() => {
	const map = useConstant(() => new Map<string, React.RefObject<T>>())
	return [getRef(map), setRef(map)]
}

export default useDynamicRefs
