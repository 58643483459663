import React, { useEffect, useState } from 'react'
import { Menu, Typography, Checkbox } from '@material-ui/core'
import useIntl from '../useIntl'
import type { FilterOptions } from './useSearchFilter'
import { MenuItemFilter } from './FilterProvider.style'

export interface FilterContextProps {
	setFilters(filters: Record<string, FilterOptions>): void
	onClick: (key: string) => (e: React.MouseEvent<HTMLButtonElement>) => void
	filterValues: Record<string, string[]>
	filterOpened: boolean
	clearFilters(): void
	filters: Record<string, FilterOptions>
	toggleOption: (key: string, value: string) => () => void
	setKeyOptions: (key: string, value: string[]) => void
}
// @ts-ignore
export const FilterContext = React.createContext<FilterContextProps>()

export interface FilterProviderProps {
	/**
	 * If should add the dropdown, usually is used for horizontal filters
	 * @default true
	 */
	hasMenu?: boolean
}

export const FilterProvider: React.FC<FilterProviderProps> = ({
	children,
	hasMenu = true
}) => {
	const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
	const [filters, setFilters] = useState<Record<string, FilterOptions>>({})
	const [filterOpened, setFilterOpened] = useState<string>('')
	const [filterValues, setFilterValues] = useState<Record<string, string[]>>({})
	const intl = useIntl()

	useEffect(() => {
		setFilterValues(prevFilterValues =>
			Object.fromEntries(
				Object.entries(filters).map(([key, value]) => [
					key,
					prevFilterValues?.[key]?.filter(val =>
						value.options.some(option => option.value === val)
					) || []
				])
			)
		)
	// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [JSON.stringify(filters)])

	const handleClick = (key: string) => (e: React.MouseEvent<HTMLButtonElement>) => {
		setFilterOpened(key)
		setAnchorEl(e.currentTarget)
		// setOpen(!open)
	}
	const handleClose = () => {
		setAnchorEl(null)
		// setFilterOpened(null)
	}
	useEffect(() => {
		if (anchorEl === null) {
			setTimeout(() => {
				setFilterOpened('')
			}, 100)
		}
	}, [anchorEl])

	const toggleOption = (key: string, value: string) => () => {
		setFilterValues(prevFilterValues => ({
			...prevFilterValues,
			[key]:
				prevFilterValues[key].indexOf(value) !== -1
					? prevFilterValues[key].filter(val => val !== value)
					: [...prevFilterValues[key], value]
		}))
	}

	const setKeyOptions = (key: string, value: string[]) => {
		setFilterValues(prevFilterValues => ({
			...prevFilterValues,
			[key]: value
		}))
	}

	const toggleAll = (key: string) => () => {
		setFilterValues(prevFilterValues => ({
			...prevFilterValues,
			[key]:
				prevFilterValues[key].length === filters[key]?.options.length
					? []
					: filters[key]?.options.map(option => option.value)
		}))
	}

	const clearFilters = () => {
		setFilterValues(prevFilterValues =>
			Object.fromEntries(Object.keys(prevFilterValues).map(key => [key, []]))
		)
	}

	return (
		<FilterContext.Provider
			value={{
				setFilters: filters => setFilters(filters),
				onClick: handleClick,
				filterValues,
				filterOpened: filterOpened !== null,
				clearFilters,
				filters,
				setKeyOptions,
				toggleOption
			}}
		>
			{children}
			{hasMenu && (
				<Menu
					anchorEl={anchorEl}
					keepMounted
					open={Boolean(anchorEl)}
					onClose={handleClose}
					PaperProps={{
						className: 'custom-scrollbar',
						style: {
							minWidth: 115,
							maxHeight: 36 * 5 + 5,
							paddingTop: 5,
							paddingBottom: 5
						}
					}}
					MenuListProps={{
						disablePadding: true
					}}
					getContentAnchorEl={null}
					anchorOrigin={{
						vertical: 'bottom',
						horizontal: 'center'
					}}
					transformOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<MenuItemFilter onClick={toggleAll(filterOpened)}>
						<Checkbox
							edge="start"
							size="small"
							checked={
								filterValues[filterOpened]?.length === filters[filterOpened]?.options.length
							}
							tabIndex={-1}
							disableRipple
						/>
						<Typography variant="paragraph1">
							{intl.formatMessage({ id: 'all' })}
						</Typography>
					</MenuItemFilter>
					{filterOpened &&
						filters[filterOpened]?.options.map(({ label, value }) => (
							<MenuItemFilter key={value} onClick={toggleOption(filterOpened, value)}>
								<Checkbox
									edge="start"
									size="small"
									checked={filterValues[filterOpened]?.indexOf(value) !== -1}
									tabIndex={-1}
									disableRipple
								/>
								<Typography variant="paragraph1">{label}</Typography>
							</MenuItemFilter>
						))}
				</Menu>
			)}
		</FilterContext.Provider>
	)
}

export default FilterProvider
