import React from 'react'
import { ChromePicker, ColorChangeHandler, RGBColor } from 'react-color'
import { Button, Menu, TextField, TextFieldProps } from '@material-ui/core'
import { SxProps } from '@material-ui/system'
import { alpha } from '@material-ui/core/styles'

export interface ColorPickerFieldProps {
	TextFieldProps?: TextFieldProps
	label?: string
	anchorEl: HTMLElement | null
	onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
	onClose: () => void
	onColorChange: ColorChangeHandler
	onColorChangeComplete: ColorChangeHandler
	rgba: RGBColor
	hex: string
	sx?: SxProps
}

export const ColorPickerField: React.FC<ColorPickerFieldProps> = ({
	anchorEl,
	label,
	onClick,
	onClose,
	onColorChange,
	onColorChangeComplete,
	rgba,
	hex,
	TextFieldProps,
	sx
}) => (
	<>
		<TextField
			label={label}
			InputProps={{ style: { visibility: 'hidden', height: 0, width: 1 } }}
			{...TextFieldProps}
		/>
		<Button
			name="pickColor"
			onClick={e => {
				e.preventDefault()
				onClick(e)
			}}
			sx={{
				marginTop: '-2px',
				padding: '10px 15px',
				backgroundColor: hex,
				opacity: rgba.a,
				':hover': {
					backgroundColor: alpha(hex, 0.8)
				},
				...sx
			}}
			variant="contained"
			fullWidth
		>
			&nbsp;
		</Button>
		<Menu
			id="color-picker-menu"
			anchorEl={anchorEl}
			keepMounted
			open={Boolean(anchorEl)}
			onClose={onClose}
			MenuListProps={{
				disablePadding: true
			}}
			PaperProps={{
				elevation: 9,
				style: {
					maxWidth: 225,
					width: '100%'
				}
			}}
			getContentAnchorEl={null}
			anchorOrigin={{
				vertical: 'bottom',
				horizontal: 'center'
			}}
			transformOrigin={{
				vertical: 'top',
				horizontal: 'right'
			}}
		>
			<ChromePicker
				color={rgba}
				onChange={onColorChange}
				onChangeComplete={onColorChangeComplete}
			/>
		</Menu>
	</>
)

export default ColorPickerField
