import React from 'react'
import { useTheme } from 'styled-components'
import { Grid, Typography, ButtonBase } from '@material-ui/core'

import { Card } from './CategoryCard.style'

export interface CategoryCardProps {
	/**
	 * Defines the title of the component
	 */
	title: string
	/**
	 * Defines the background color
	 */
	color: string
	/**
	 * Defines the image source path to used inside the component
	 */
	src: string
	/**
	 * Callback fired when the component is clicked
	 */
	onClick?: () => void
}

export const CategoryCard = ({ title, color, src, onClick }: CategoryCardProps) => {
	const theme = useTheme()
	return (
		<ButtonBase onClick={() => onClick?.()} sx={{ width: '100%', height: '100%' }}>
			<Card color={color}>
				<Grid
					container
					direction="column"
					alignItems="center"
					justifyContent="center"
					spacing={3}
				>
					<Grid item>
						<img src={src} alt={title} style={{ height: 50 }} />
					</Grid>
					<Grid item>
						{/* TODO: replace text color */}
						<Typography align="center" sx={{ fontSize: 14, color: theme.tag.black }}>
							{title}
						</Typography>
					</Grid>
				</Grid>
			</Card>
		</ButtonBase>
	)
}

export default CategoryCard
