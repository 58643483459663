export function getTheme(): 'dark' | 'light' {
	try {
		let systemThemeDark
		const theme = localStorage.getItem('theme')
		const systemThemeMode = localStorage.getItem('system-theme-mode')
		if ((systemThemeMode === 'true' || !theme) && window.matchMedia) {
			const systemTheme = window.matchMedia('(prefers-color-scheme: dark)')
			systemThemeDark = systemTheme && systemTheme.matches
		}
		if (theme === '"dark"' || systemThemeDark) {
			document.body.classList.add('dark')
			return 'dark'
		}
		return 'light'
	} catch {
		return 'light'
	}
}
