import React, { useEffect, useState, useCallback } from 'react'
import {
	Button,
	IconButton,
	Grid,
	TextField,
	Typography,
	Box,
	CircularProgress
} from '@material-ui/core'
import DeleteIcon from '@material-ui/icons/Delete'
import { useTheme } from 'styled-components'
import { useStoreon } from 'storeon/react'

import useIntl from 'hooks/useIntl'
import { UpdateOrgInput, useUpdateOrgMutation, useUserOrgQuery } from 'hooks/useApollo'
import { useForm } from 'react-hook-form'
import iziToast from 'izitoast'
import { LoadingButton } from '@material-ui/lab'
import { useDropzone, FileRejection } from 'react-dropzone'
import { Header } from '../components'
import { Paper } from '../../AccountSettings.style'
import * as S from './Company.style'
import { SERVER_URL } from 'constants/config'
import Modal from 'components/Modal'
export const Company = () => {
	const theme = useTheme()
	const { dispatch, user } = useStoreon('user')
	const [companyLogoFile, setCompanyLogoFile] = useState<File>()
	const [companyLogoLoading, setCompanyLogoLoading] = useState(false)
	const [openremoveCompanyLogoDialogue, setOpenRemoveCompanyLogoDialogue] = useState(false)

	const { data: companyData, loading: loadingCompany } = useUserOrgQuery({
		fetchPolicy: 'no-cache'
	})
	const org = companyData?.me?.org

	const { register, handleSubmit, formState, watch, setError, errors, setValue } = useForm<
		UpdateOrgInput & { companyLogo?: string }
	>({
		mode: 'all',
		defaultValues: {
			companyLogo: user?.org?.photoUrl || ''
		}
	})

	useEffect(() => {
		register('companyLogo')
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const onDrop = useCallback(([file]: File[], [error]: FileRejection[]) => {
		if (file) {
			setValue('companyLogo', URL.createObjectURL(file), { shouldDirty: true })
			setCompanyLogoFile(file)
		}

		error?.errors.forEach(({ code }) => {
			if (code === 'file-invalid-type') {
				return iziToast.error({
					message: 'File type must be either in PNG or JPEG format.'
				})
			}

			if (code === 'file-too-large') {
				return iziToast.error({
					message: 'File size must not be larger than 2MB.'
				})
			}
		})
		// eslint-disable-next-line
	}, [])

	const { getRootProps, getInputProps, isDragActive } = useDropzone({
		onDrop,
		accept: ['image/png', 'image/jpeg'],
		maxSize: 2000000, // 2mb
		multiple: false
	})

	const { onClick: onBrowseFileClick, ...logoUploadContainerProps } = getRootProps()

	const allFields = watch()

	const [updateOrg, { loading }] = useUpdateOrgMutation({
		onCompleted: finalData => {
			if (user) {
				dispatch('user/setUser', {
					...user,
					org: {
						...finalData.updateOrg
					}
				})
			}
			setCompanyLogoLoading(false)

			iziToast.success({
				message: intl.formatMessage({ id: 'updatedOrg' })
			})
		},
		onError: ({ graphQLErrors }) => {
			setCompanyLogoLoading(false)

			if (graphQLErrors) {
				graphQLErrors.forEach(({ message, extensions }) => {
					if (extensions?.exception?.details) {
						if (!Array.isArray(extensions?.exception?.details)) {
							const { details } = extensions?.exception
							setError(details?.key, {
								shouldFocus: true,
								message,
								type: 'validate'
							})
						}
					}
				})
			}
		}
	})

	const onSubmit = async () => {
		const { companyLogo, ...orgFields } = allFields
		setCompanyLogoLoading(true)

		if (companyLogoFile) {
			await uploadFile(companyLogoFile)
			setCompanyLogoLoading(true)
		}

		updateOrg({
			variables: {
				orgInput: {
					...orgFields,
					logo: companyLogoFile || null
				}
			}
		})
	}

	const handlePreviewClear = async () => {
		if (org?.photoUrl) {
			setOpenRemoveCompanyLogoDialogue(true)
		} else {
			setValue('companyLogo', '')
			setCompanyLogoFile(undefined)
		}
	}

	const handleRemoveCompanyLogo = async () => {
		if (org?.photoUrl) {
			try {
				await uploadFile(null)
				if (user) {
					dispatch('user/setUser', {
						...user,
						org: {
							...user.org,
							photoUrl: null
						}
					})
				}
				setValue('companyLogo', '')
				setCompanyLogoFile(undefined)
				setCompanyLogoLoading(false)
				iziToast.success({
					message: 'Company logo removed successfully'
				})
			} catch (e) {
				console.error(e)
			}
		}
	}

	const intl = useIntl()
	const { companyLogo } = allFields

	const jwt = JSON.parse(localStorage.getItem('storeon') || '')

	async function uploadFile(companyLogo) {
		const url = `${SERVER_URL}/api/v1/user/setcompanyLogo`
		const formData = new FormData()
		formData.append('img', companyLogo)

		try {
			const response = await fetch(url, {
				method: 'POST',
				headers: {
					Authorization: `${jwt?.token}`
				},
				body: formData
			})

			if (response.ok) {
				const result = await response.json()
			} else {
				console.error('File upload failed:', response.statusText)
			}
		} catch (error) {
			console.error('Error uploading file:', error)
		}
	}

	return loadingCompany ? (
		<Box sx={{ display: 'flex', justifyContent: 'center' }}>
			<CircularProgress />{' '}
			<Typography variant="h4">{intl.formatMessage({ id: 'loading' })}</Typography>
		</Box>
	) : (
		<Paper elevation={7}>
			<Modal
				title={'Remove company logo'}
				okText="Remove"
				cancelText="Cancel"
				size="normal"
				onClose={() => {
					setOpenRemoveCompanyLogoDialogue(false)
				}}
				open={openremoveCompanyLogoDialogue}
				onOk={handleRemoveCompanyLogo}
			>
				<Typography>Are you sure you want to remove your company logo?</Typography>
			</Modal>
			<Header title={intl.formatMessage({ id: 'org.company' })} />
			<Grid container spacing={5} component="form" onSubmit={handleSubmit(onSubmit)}>
				<Grid item xs={12} container direction="column" spacing={2}>
					<Grid item>
						<Typography variant="h5">
							{intl.formatMessage({ id: 'org.companyLogo' })}
						</Typography>
					</Grid>
					<Grid item container>
						{companyLogo ? (
							<Grid item container>
								<Grid item>
									<img
										src={companyLogo}
										alt={intl.formatMessage({ id: 'org.companyLogo' })}
										height={168}
										width={'100%'}
									/>
								</Grid>
								<Grid item>
									<IconButton
										title="Remove company logo"
										size="small"
										onClick={handlePreviewClear}
									>
										<DeleteIcon color="error" fontSize="small" />
									</IconButton>
								</Grid>
							</Grid>
						) : (
							<Grid item xs={12}>
								<S.LogoUploadContainer {...logoUploadContainerProps} active={isDragActive}>
									<Grid
										container
										direction="column"
										justifyContent="center"
										alignItems="center"
										spacing={2}
									>
										{isDragActive ? (
											<Grid item>
												<Typography fontWeight={500}>
													{intl.formatMessage({ id: 'companySettings.dropYourFiles' })}!
												</Typography>
											</Grid>
										) : (
											<>
												<Grid item>
													<Typography fontWeight={500}>
														{intl.formatMessage({
															id: 'companySettings.drag&DropYourLogo'
														})}
													</Typography>
												</Grid>
												<Grid item>
													<Typography fontWeight={500}>
														{intl
															.formatMessage({
																id: 'or'
															})
															.toUpperCase()}
													</Typography>
												</Grid>
												<Grid item>
													<Button
														type="button"
														variant="outlined"
														size="small"
														color="inherit"
														onClick={onBrowseFileClick}
													>
														{intl.formatMessage({ id: 'browseFiles' })}
													</Button>
												</Grid>
											</>
										)}
									</Grid>
								</S.LogoUploadContainer>
							</Grid>
						)}
						<input {...getInputProps()} />
					</Grid>
					<Grid item container justifyContent="space-between">
						<Grid item>
							<Typography variant="paragraph2" fontWeight={500} color={theme.grey.main}>
								{intl.formatMessage({
									id: 'fileFormat'
								})}
								: PNG, JPEG
							</Typography>
						</Grid>
						<Grid item>
							<Typography variant="paragraph2" fontWeight={500} color={theme.grey.main}>
								{intl.formatMessage({
									id: 'maximumSize'
								})}
								: 2MB
							</Typography>
						</Grid>
					</Grid>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						label={intl.formatMessage({ id: 'org.companyName' })}
						defaultValue={org?.name}
						name="name"
						type="name"
						error={!!errors.name}
						helperText={
							errors?.name?.type === 'validate' ? errors?.name?.message : undefined
						}
						inputRef={register}
						placeholder={intl.formatMessage({
							id: 'org.placeholder.name'
						})}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						label={intl.formatMessage({ id: 'org.companySize' })}
						defaultValue={org?.companySize}
						name="companySize"
						type="companySize"
						error={!!errors.companySize}
						helperText={
							errors?.companySize?.type === 'validate'
								? errors?.companySize?.message
								: undefined
						}
						inputRef={register({ min: 1 })}
						placeholder={intl.formatMessage({
							id: 'org.placeholder.companySize'
						})}
					/>
				</Grid>
				<Grid item xs={12} md={6}>
					<TextField
						name="industry"
						type="industry"
						defaultValue={org?.industry}
						error={!!errors.industry}
						helperText={
							errors?.industry?.type === 'validate' ? errors?.industry?.message : undefined
						}
						inputRef={register}
						placeholder={intl.formatMessage({
							id: 'org.placeholder.industry'
						})}
						label={intl.formatMessage({
							id: 'org.companyIndustry'
						})}
					/>
				</Grid>
				<Grid container item xs={12} justifyContent="flex-end" mt={5}>
					<Grid item xs={12} md={2}>
						<LoadingButton
							fullWidth
							variant="contained"
							disabled={!formState.isValid || !formState.isDirty || allFields === org}
							pending={companyLogoLoading}
						>
							{intl.formatMessage({ id: 'save' })}
						</LoadingButton>
					</Grid>
				</Grid>
			</Grid>
		</Paper>
	)
}

export default Company
