import React from 'react'
import { useTheme } from 'styled-components'
import { useNavigate } from 'react-router-dom'
import { useFormContext } from 'react-hook-form'
import { Grid, useMediaQuery, Theme } from '@material-ui/core'
import { ContentWrapper } from 'screens/Onboarding/components/ContentWrapper'
import { CreationContent } from 'components/CreationContent'
import WizardFooter from 'components/Wizard/WizardFooter'

import useIntl from 'hooks/useIntl'
import CategoryCard from 'components/CategoryCard'
import { Category, useGetCategoriesQuery } from 'hooks/useApollo'
import { useOnboardingStore } from 'screens/Onboarding/hooks/useOnboardingStore'
import { RoleBasedChallengesForm } from '../../RoleBasedChallenges.types'
import { useRoleBasedChallengesStore } from '../../hooks/useRoleBasedChallengesStore'

// TODO: muted categories (not permanent)
const mutedCategories = [
	'5fea8dae45b7820017baf8e6',
	'5eb32b50c25a56031bdec24b',
	'6089773a839a78001782a2ed',
	'605ccec86404f400179addcd',
	'5e83ef824a960b724df61e91',
	'60a52cc4ddc4f9001725f393',
	'605cd0f23444bd00177f5b67'
]

export const RoleSelection = () => {
	const intl = useIntl()
	const theme = useTheme()
	const navigate = useNavigate()

	const { setValue } = useFormContext<RoleBasedChallengesForm>()
	const { currentStep, nextStep, toggleHelpGuide } = useOnboardingStore(state => state)
	const { categories, setCategories } = useRoleBasedChallengesStore(state => state)

	useGetCategoriesQuery({
		variables: { orderBy: { name: 'asc' } },
		fetchPolicy: 'cache-and-network',
		onCompleted: ({ categories }) => {
			const newCategories = categories.filter(
				category => category && !mutedCategories.includes(category.id)
			)
			setCategories(newCategories as Category[])
		}
	})

	const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	const handleClick = (id: string, name: string) => {
		setValue('category', { id, name })
		nextStep()
	}

	return (
		<>
			<ContentWrapper currentStep={currentStep}>
				<CreationContent
					subHeader
					title={intl.formatMessage({
						id: `onboarding.role.based.challenges.step.${currentStep}.title`
					})}
					subTitle={intl.formatMessage({
						id: 'onboarding.select.role.based.challenges.title'
					})}
				>
					<Grid container spacing={upSm ? 5 : 2}>
						{categories.map((category, index) => (
							<Grid item xs={6} md={3} key={index}>
								<CategoryCard
									onClick={() => handleClick(category.id, category?.name || '')}
									title={category?.name || ''}
									src={category?.img || ''}
									color={category?.color || theme.background.main}
								/>
							</Grid>
						))}
					</Grid>
				</CreationContent>
			</ContentWrapper>
			<WizardFooter
				onOpenHelpGuide={toggleHelpGuide}
				onBack={() => navigate('/onboarding/challenges-selection')}
			/>
		</>
	)
}

export default RoleSelection
