import * as yup from 'yup'
import { useForm } from 'react-hook-form'
import { yupResolver } from '@hookform/resolvers/yup'
import { ColorChangeHandler } from 'react-color'
import { useTypedController } from '@hookform/strictly-typed'
import useIntl from 'hooks/useIntl'
import useColorPicker from 'hooks/useColorPicker'
import useToggleAnchor from 'hooks/useToggleAnchor'

export interface EditTalentGroupForm {
	name: string
	type: string
	color: string
}

export type IuseEditTalentGroupForm = ReturnType<typeof useEditTalentGroupForm>

interface UseEditTalentGroupFormProps extends EditTalentGroupForm {}

export const useEditTalentGroupForm = ({
	color,
	name,
	type
}: UseEditTalentGroupFormProps) => {
	const intl = useIntl()
	const [{ anchorEl }, { handleOpenAnchor, handleCloseAnchor }] = useToggleAnchor()
	const [{ hex, rgba }, { handleColorChange }] = useColorPicker(color)

	const validationSchema = yup.object({
		name: yup
			.string()
			.required(intl.formatMessage({ id: 'talentGroup.teamName.error.required' })),
		type: yup
			.string()
			.required(intl.formatMessage({ id: 'talentGroup.type.error.required' })),
		color: yup
			.string()
			.required(intl.formatMessage({ id: 'talentGroup.color.error.required' }))
	})

	const {
		watch,
		control,
		errors,
		handleSubmit: onSubmit,
		setValue
	} = useForm<EditTalentGroupForm>({
		reValidateMode: 'onChange',
		resolver: yupResolver(validationSchema),
		defaultValues: {
			color,
			name,
			type
		}
	})

	const Controller = useTypedController<EditTalentGroupForm>({ control })

	const handleColorChangeComplete: ColorChangeHandler = ({ hex }) => {
		setValue('color', hex)
	}

	const models = { errors, watch, anchorEl, hex, rgba }
	const operations = {
		onSubmit,
		handleOpenAnchor,
		handleCloseAnchor,
		handleColorChange,
		handleColorChangeComplete
	}
	const components = { Controller }

	return [models, operations, components] as [
		typeof models,
		typeof operations,
		typeof components
	]
}

export default useEditTalentGroupForm
