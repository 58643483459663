import React from 'react'
import { Grid, Typography, Button, Hidden, IconButton } from '@material-ui/core'
import ArrowForwardIcon from '@material-ui/icons/ArrowForward'
import ArrowBackIcon from '@material-ui/icons/ArrowBack'
import { useTheme } from 'styled-components'
import useIntl from 'hooks/useIntl'
import Modal, { IModalProps } from 'components/Modal'
import Accordion from 'components/Accordion'
import ColoredCard from 'components/ColoredCard'
import { CoreChapterResultFieldsFragment } from 'hooks/useApollo'

export interface ViewSubmissionModalProps {
	/**
	 * Props applied to the Modal component
	 */
	modalProps: Omit<IModalProps, 'title'>
	/**
	 * Defines the total title of the challenge
	 */
	title: string
	/**
	 * Defines the total description of the challenge
	 */
	desc: string
	/**
	 * Defines the data submitted from the challenge
	 */
	data: CoreChapterResultFieldsFragment
	/**
	 * Callback fired when the next button is clicked
	 */
	onNext: () => void
	/**
	 * Callback fired when the prev button is clicked
	 */
	onPrev: () => void
	/**
	 * Defines if the selected item is the first element
	 */
	isFirstIndex: boolean
	/**
	 * Defines if the selected item is the last element
	 */
	isLastIndex: boolean
	/**
	 * Defines the text to show in the next button
	 */
	nextText?: string
	/**
	 * Defines the text to show in the prev button
	 */
	prevText?: string
}

export const ViewSubmissionModal: React.FC<ViewSubmissionModalProps> = ({
	modalProps,
	title,
	desc,
	data,
	onNext,
	onPrev,
	isFirstIndex,
	isLastIndex,
	nextText,
	prevText
}) => {
	const theme = useTheme()
	const intl = useIntl()
	return (
		<Modal {...modalProps} size="big" title={title} showCloseButton footer={false}>
			<Grid container spacing={2}>
				<Grid item>
					<Typography variant="paragraph1" sx={{ color: theme.text.description }}>
						{desc}
					</Typography>
				</Grid>
				<Grid item>
					{data?.parts?.map(
						({ id, type, scene, title, question, instruction, answer, options }, index) => (
							<Accordion key={id} label={`${scene} ${title}`} defaultExpanded={index === 0}>
								<Grid container spacing={2}>
									<Grid item xs={12}>
										<ColoredCard color={theme.tag.yellow}>
											<Typography fontWeight={500}>{question}</Typography>
											<Typography fontWeight={500} mt={2}>
												{instruction}
											</Typography>
											{type === 'options' && (
												<Typography sx={{ mt: 2, color: theme.text.description }}>
													{intl.formatMessage({ id: 'challengeSubmission.instruction' })}
												</Typography>
											)}
										</ColoredCard>
									</Grid>
									<Grid item>
										<Typography gutterBottom>
											{intl.formatMessage({ id: 'answer' })}:
										</Typography>
										{type === 'options' && (
											<Typography sx={{ color: theme.text.description }}>
												{intl.formatMessage({
													id: 'challengeSubmission.orderedListSubmission'
												})}
											</Typography>
										)}
									</Grid>
									{type === 'options' && options ? (
										<Grid item>
											<Grid container spacing={2}>
												{options.map((ans, index) => (
													<Grid key={index} item xs={12}>
														<ColoredCard color={theme.grey.third}>
															<Typography>{ans}</Typography>
														</ColoredCard>
													</Grid>
												))}
											</Grid>
										</Grid>
									) : null}
									{type === 'answer' ? (
										<Grid item>
											<Typography>{answer || ''}</Typography>
										</Grid>
									) : null}
								</Grid>
							</Accordion>
						)
					)}
				</Grid>
				<Grid item container justifyContent="space-between">
					<Grid item xs={6} container justifyContent="flex-start">
						{isFirstIndex ? null : (
							<>
								<Hidden smDown>
									<Button
										sx={{ color: theme.tag.blue }}
										size="small"
										name="prev"
										onClick={onPrev}
										startIcon={<ArrowBackIcon fontSize="small" />}
									>
										{prevText}
									</Button>
								</Hidden>
								<Hidden smUp>
									<IconButton
										sx={{ ml: -1.5, color: 'link.main' }}
										color="primary"
										onClick={onPrev}
									>
										<ArrowBackIcon />
									</IconButton>
								</Hidden>
							</>
						)}
					</Grid>
					<Grid item xs={6} container justifyContent="flex-end">
						{isLastIndex ? null : (
							<>
								<Hidden smDown>
									<Button
										name="next"
										size="small"
										onClick={onNext}
										sx={{ color: theme.tag.blue }}
										endIcon={<ArrowForwardIcon fontSize="small" />}
									>
										{nextText}
									</Button>
								</Hidden>
								<Hidden smUp>
									<IconButton sx={{ mr: -1.5, color: 'link.main' }} onClick={onNext}>
										<ArrowForwardIcon />
									</IconButton>
								</Hidden>
							</>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Modal>
	)
}

export default ViewSubmissionModal
