import { MakeAllUndefined, RemoveSomeUndefined, RequiredKeys } from './types'

export const isObject = <T extends { [key: string]: any }>(payload: T): T =>
	// @ts-ignore
	payload && typeof payload === 'object' && !Array.isArray(payload) ? payload : {}

type NonNullKeys<T> = RemoveSomeUndefined<MakeAllUndefined<Required<T>>, RequiredKeys<T>>

export const getNonNullKeys = <T extends { [key: string]: any }>(obj: T): NonNullKeys<T> =>
	Object.fromEntries(
		Object.entries(obj).filter(
			([, value]) => value !== null && typeof value !== 'undefined' && value !== ''
		)
	) as NonNullKeys<T>

export const removeUndefinedKeys = <T extends { [key: string]: any }>(
	obj: T
): NonNullKeys<T> =>
	Object.fromEntries(
		Object.entries(obj).filter(([, value]) => typeof value !== 'undefined')
	) as NonNullKeys<T>

export function getKeys<
	T extends Record<string, unknown>,
	K extends keyof T extends never ? string[] : (keyof T)[]
>(obj: T, filter: K): Pick<T, K[number]> {
	const newObj: Partial<Pick<T, K[number]>> = {}
	for (let i = 0; i < filter.length; i++) {
		const element = filter[i]
		newObj[element] = obj[element]
	}
	return newObj as Pick<T, K[number]>
}

export function removeKeys<
	T extends Record<string, unknown>,
	K extends keyof T extends never ? string[] : (keyof T)[]
>(obj: T, filter: K) {
	const newObj: Partial<Pick<T, K[number]>> = {}
	Object.assign(newObj, obj)
	for (let i = 0; i < filter.length; i++) {
		delete newObj[filter[i]]
	}
	return newObj
}

export const sortObject = <T extends { [key: string]: any }>(obj: T): T => {
	const keys = Object.keys(obj)
	keys.sort()
	const object = {}
	for (let i = 0; i < keys.length; i++) {
		object[keys[i]] = obj[keys[i]]
	}
	return object as T
}

export const applyDefaultOptions = <T extends { [key: string]: any }>(
	obj: T,
	defaults: T
): T => ({
	...defaults,
	...(obj ? getNonNullKeys(obj) : {})
})
