import React from 'react'

import { StyledChip } from './ContrastChip.styles'

export interface ContrastChipProps {
	/**
	 * Defines the contrast foreground and background color of the component
	 */
	color: string
	/**
	 * Defines the label of the component
	 */
	label: React.ReactNode
	/**
	 * If true, the component is disabled
	 * @default false
	 */
	disabled?: boolean
	/**
	 * The size of the component.
	 * @default 'small'
	 */
	size?: 'small' | 'medium'
	/**
	 * Callback fired when the delete icon is clicked.
	 * If set, the delete icon will be shown.
	 */
	onDelete?: React.EventHandler<any>
	/**
	 * Override the default delete icon element. Shown only if `onDelete` is set.
	 */
	deleteIcon?: React.ReactElement
}

export const ContrastChip: React.FC<ContrastChipProps> = ({
	size = 'small',
	color,
	label,
	disabled,
	deleteIcon,
	onDelete
}) => {
	return (
		<StyledChip
			size={size}
			colorContrast={color}
			disabled={disabled}
			onDelete={onDelete}
			deleteIcon={deleteIcon}
			label={label}
		/>
	)
}

export default ContrastChip
