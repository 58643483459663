import styled from 'styled-components'

export const AccountSettingsContainer = styled.div`
	display: flex;
	min-height: 100vh;
	background-color: ${props => props.theme.background.main};
`

export const AccountSettingsMain = styled.main`
	flex-grow: 1;
	display: flex;
	flex-direction: column;
`
