import React from 'react'
import AccountSettingsLayout from 'layouts/accountSettings'
import { PartialRouteObject } from 'react-router'
import Dashboard from 'screens/Dashboard'
import { teamsRoutes } from './teams'
import { onboardingRoutes } from './onboarding'

export const dashboardRoutes: PartialRouteObject[] = [
	{
		path: '',
		element: <Dashboard />
	},
	{
		path: 'settings/*',
		element: <AccountSettingsLayout />
	},
	{
		path: '/onboarding/*',
		children: onboardingRoutes
	},
	{
		path: '/teams/*',
		children: teamsRoutes
	}
]
