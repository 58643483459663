import { useEffect, useState } from 'react'
import {
	IndustryBenchmark,
	useDeleteApplicantMutation,
	useGetApplicantLazyQuery,
	useSendEmailReminderByIdMutation,
	useUpdateApplicantMutation
} from 'hooks/useApollo'
import iziToast from 'izitoast'
import { TeamContextProps, useTeamContext } from 'screens/Teams/Teams.config'
import gql from 'graphql-tag'
import useIntl from 'hooks/useIntl'
import {
	removePinnedUsers,
	setSelectedTalent,
	unselectTalent
} from '../context/talentContextActions'
import { useTalentContext, setGroupChapters, setPinnedUsers } from '../context'

export type IuseTalentProfile = ReturnType<typeof useTalentProfile>

const teamSelector = (store: TeamContextProps) => ({
	refetchApplicants: store.refetchApplicants,
	teamId: store.teamId,
	assessment: store.assessment
})

export const useTalentProfile = () => {
	const [formLoading, setFormLoading] = useState(false)
	const [{ selectedTalent, chapters, pinnedUsers }, dispatch] = useTalentContext()
	const { refetchApplicants, teamId, assessment } = useTeamContext(teamSelector)
	const [activeModal, setActiveModal] = useState<'delete' | 'reminder' | 'edit' | null>(
		null
	)
	const intl = useIntl()

	const [getApplicant, { data, loading }] = useGetApplicantLazyQuery()

	useEffect(() => {
		// @ts-ignore
		dispatch(setGroupChapters(assessment?.chapters ?? []))
		// eslint-disable-next-line
	}, [JSON.stringify(assessment?.chapters), teamId])

	useEffect(() => {
		if (selectedTalent.id) {
			getApplicant({
				variables: { userId: selectedTalent.id, assessId: teamId }
			})
		}
		// eslint-disable-next-line
	}, [JSON.stringify(selectedTalent), teamId])

	const [updateTalent, { loading: updatingTalent }] = useUpdateApplicantMutation({
		onError: () => {},
		onCompleted: () => {
			if (activeModal === 'edit') {
				handleCloseModal()
			}
		}
	})

	const benchmarks =
		assessment?.selectedRoleDetails?.benchmarks?.reduce<Record<string, IndustryBenchmark>>(
			(res, item) => {
				// @ts-ignore
				res[item.id] = item
				return res
			},
			{}
		) || {}

	const [deleteTalent] = useDeleteApplicantMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.deleteApplicant) {
				iziToast.success({
					message: `Talent deleted successfully.`
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		},
		update(cache) {
			cache.modify({
				fields: {
					applicants(existingApplicantRefs) {
						if (existingApplicantRefs) {
							cache.modify({
								id: cache.identify(existingApplicantRefs),
								fields: {
									totalTalents(existingTotalTalentRef) {
										cache.modify({
											id: cache.identify(existingTotalTalentRef),
											fields: {
												count(existingCount = 1) {
													return existingCount - 1
												},
												[selectedTalent.pipelineType === 'Internal'
													? 'employees'
													: 'candidates']: (existingCount = 1) => existingCount - 1
											}
										})
									},
									items(existingItemsRef = [], { readField }) {
										return existingItemsRef.filter(
											talentRef => selectedTalent.id !== readField('id', talentRef)
										)
									}
								}
							})
						}
					},
					assessment(existingAssessmentRef) {
						if (existingAssessmentRef) {
							cache.modify({
								id: cache.identify(existingAssessmentRef),
								fields: {
									totalTalents(existingTotal = 1) {
										return existingTotal - 1
									}
								}
							})
						}
					}
				}
			})
			cache.modify({
				fields: {
					talents(existingTalentsRef) {
						if (existingTalentsRef) {
							cache.modify({
								id: cache.identify(existingTalentsRef),
								fields: {
									items(existingItemsRef = [], { readField }) {
										return existingItemsRef.filter(
											talentRef => selectedTalent.id !== readField('id', talentRef)
										)
									}
								}
							})
						}
					}
				}
			})
			selectedTalent.pipelinesId.forEach(pipelineId => {
				cache.modify({
					id: `Pipeline:${pipelineId}`,
					fields: {
						users(existingApplicantRefs = [], { readField }) {
							return existingApplicantRefs.filter(
								applicantRef => selectedTalent.id !== readField('id', applicantRef)
							)
						},
						usersTotal(existingTotal = 1) {
							return existingTotal - 1
						}
					}
				})
			})
			dispatch(removePinnedUsers(selectedTalent.id))
			dispatch(unselectTalent())
		}
	})

	const handleBookmark = async (userId: string, isBookmarked: boolean) => {
		await updateTalent({
			variables: { userId, assessId: teamId, applicantInput: { isBookmarked } }
		})
		if (refetchApplicants) {
			refetchApplicants()
		}
	}

	const handleEditNote = (userId: string, note: string) => {
		updateTalent({ variables: { userId, assessId: teamId, applicantInput: { note } } })
		if (userId && note) {
			iziToast.success({
				message: `Notes updated successfully.`
			})
		}
	}

	const handleShortlist = async (userId: string, isShortlisted: boolean) => {
		await updateTalent({
			variables: { userId, assessId: teamId, applicantInput: { isShortlisted } }
		})
		if (refetchApplicants) {
			refetchApplicants()
		}
	}

	const handleEditGroups = async (userId: string, groups: string[]) => {
		await updateTalent({
			variables: { userId, assessId: teamId, applicantInput: { pipelinesId: groups } },
			update(cache, { data }) {
				const removedPipelines = selectedTalent.pipelinesId.filter(
					pipelineId => !groups.includes(pipelineId)
				)
				const newPipelines = groups.filter(
					groupId => !selectedTalent.pipelinesId.includes(groupId)
				)
				removedPipelines.forEach(pipelineId => {
					cache.modify({
						id: `Pipeline:${pipelineId}`,
						fields: {
							users(existingApplicantRefs = [], { readField }) {
								return existingApplicantRefs.filter(
									applicantRef => selectedTalent.id !== readField('id', applicantRef)
								)
							},
							usersTotal(existingTotal = 1) {
								return existingTotal - 1
							}
						}
					})
				})
				const newApplicantRef = cache.writeFragment({
					data: data?.updateApplicant,
					fragment: gql`
						fragment NewApplicant on Applicant {
							id
							isCustom
							email
						}
					`
				})
				newPipelines.forEach(pipelineId => {
					cache.modify({
						id: `Pipeline:${pipelineId}`,
						fields: {
							users(existingApplicantRefs = []) {
								return [...existingApplicantRefs, newApplicantRef]
							},
							usersTotal(existingTotal = 0) {
								return existingTotal + 1
							}
						}
					})
				})
				dispatch(
					setSelectedTalent({
						...selectedTalent,
						pipelinesId: groups
					})
				)
			}
		})
		if (refetchApplicants) {
			refetchApplicants()
		}
	}

	const [sendEmailReminderById] = useSendEmailReminderByIdMutation({
		onCompleted: data => {
			setFormLoading(false)
			setActiveModal(null)
			if (data.sendEmailReminderById) {
				iziToast.success({
					message: intl.formatMessage({ id: 'sendEmailReminder.success.unique' })
				})
			}
		},
		onError: () => {
			setFormLoading(false)
		}
	})

	const handleInviteTalent = (userId: string) => {
		setFormLoading(true)
		sendEmailReminderById({
			variables: {
				id: userId,
				assessId: teamId
			}
		})
	}

	const handleDeleteTalent = (userId: string) => {
		setFormLoading(true)
		deleteTalent({ variables: { userId, assessId: teamId } })
	}

	const handleOpenDeleteModal = () => {
		setActiveModal('delete')
	}

	const handleOpenEditModal = () => {
		setActiveModal('edit')
	}

	const handleOpenReminderModal = () => {
		setActiveModal('reminder')
	}

	const handleCloseModal = () => {
		setActiveModal(null)
	}

	const handleTogglePinUser = () => {
		dispatch(setPinnedUsers(selectedTalent))
	}

	const models = {
		talent: selectedTalent.id ? data?.applicant : undefined,
		group: assessment,
		loading,
		activeModal,
		updatingTalent,
		formLoading,
		selectedTalent,
		chapters,
		pinnedUsers,
		benchmarks,
		assessment
	}
	const operations = {
		handleBookmark,
		handleShortlist,
		handleDeleteTalent,
		handleInviteTalent,
		handleTogglePinUser,
		handleOpenDeleteModal,
		handleOpenReminderModal,
		handleOpenEditModal,
		handleEditNote,
		handleEditGroups,
		handleCloseModal
	}

	return [models, operations] as [typeof models, typeof operations]
}

export default useTalentProfile
