import { ButtonBase, Toolbar } from '@material-ui/core'
import styled from 'styled-components'
import { Link as _Link } from 'react-router-dom'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'

export const Container = styled(Toolbar)`
	height: 77px;
	@media (min-width: 600px) {
		&.MuiToolbar-gutters {
			padding: 0 43px;
			@media (max-width: 600px) {
				padding-left: 20px;
				padding-right: 20px;
			}
		}
	}
`

export const Content = styled.div`
	display: flex;
	align-items: center;
	width: 100%;
	height: 100%;
	justify-content: space-between;
	flex: 1 1 auto;
`

export const RightContent = styled.div`
	display: flex;
	align-items: center;
	height: 100%;
`

export const Link = styled(_Link)`
	color: ${props => props.theme.text.main};
	transition: color 0.2s linear;
	font-size: ${pxToRem(14)};
	&:hover {
		color: ${props => props.theme.primary.main};
	}
`

export const Profile = styled(ButtonBase)`
	display: flex;
	border-left: 1px solid ${props => props.theme.grey.fourth};
	padding: 0 20px;
	padding-right: 0;
	margin-left: 10px;
	max-width: 230px;
	align-items: center;
	justify-content: initial;
	height: 100%;
	p {
		font-size: 14px;
		color: ${props => props.theme.grey.main};
	}
`

export const Name = styled.div`
	display: flex;
	align-items: center;
	min-width: 0;
	text-align: initial;
	margin-left: 18px;
	h4 {
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;
	}

	@media only screen and (max-width: 959px) {
		justify-content: center;
		margin-left: 0;
	}
`
