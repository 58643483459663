import React, { useMemo } from 'react'
import keyBy from 'lodash/keyBy'
import {
	Grid,
	Typography,
	Autocomplete,
	TextField,
	useMediaQuery,
	Theme
} from '@material-ui/core'
import useId from '@material-ui/core/utils/useId'
import Modal, { IModalProps } from 'components/Modal'
import ContrastChip from 'components/ContrastChip'
import { arrayEquals } from 'utils/array'
import useIntl from 'hooks/useIntl'
import { EditTalentForm, useEditTalentForm } from './useEditTalentModal'

export interface EditTalentModalProps extends Pick<IModalProps, 'open' | 'onClose'> {
	/**
	 * Defines the name of the talent
	 */
	name: string
	/**
	 * Defines if the operation is loading
	 */
	loading?: boolean
	/**
	 * Defines the group/s of the talent
	 */
	groups: { id: string; name: string; color: string }[]
	/**
	 * Defines the possible list of groups the talent can join
	 */
	options: { id: string; name: string; color: string }[]
	onEdit: (pipelines: string[]) => void
	/**
	 * Defines the mask of user
	 * @default false
	 */
	masked?: boolean
}

export const EditTalentModal: React.FC<EditTalentModalProps> = ({
	name,
	groups,
	options,
	open,
	onClose,
	loading = false,
	onEdit,
	masked = false
}) => {
	const id = useId()
	const intl = useIntl()
	const mdDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
	const defaultGroups = useMemo(() => keyBy(groups, 'id'), [groups])
	const defaultOptions = useMemo(() => keyBy(options, 'id'), [options])

	const [
		{ errors, watch },
		{ onSubmit, handleChange, handleDelete },
		{ Controller }
	] = useEditTalentForm({ groups: Object.keys(defaultGroups) })

	const handleSubmit = (payload: EditTalentForm) => {
		onEdit(payload.groups)
	}

	const watchedGroups = watch('groups')
	// @ts-ignore
	const error = errors && errors?.groups?.length > 0

	return (
		<Modal
			open={open}
			buttonStyle="primary"
			okText={intl.formatMessage({ id: 'finish' })}
			onClose={onClose}
			okLoading={loading}
			size={mdDown ? 'normal' : 'big'}
			onOk={onSubmit(handleSubmit)}
			closeOnOk={false}
			onOkDisabled={arrayEquals(Object.keys(defaultGroups), watchedGroups)}
			title={intl.formatMessage({ id: 'editTalentForm' }, { masked: masked ? 1 : name })}
			maxWidth="xs"
		>
			<form onSubmit={onSubmit(handleSubmit)}>
				<Grid container spacing={3}>
					<Grid container item spacing={1}>
						<Grid item xs={12}>
							<Typography fontWeight={500}>
								{intl.formatMessage({ id: 'editTalent.label' })}
							</Typography>
						</Grid>
						<Grid item xs={12}>
							<Typography color="secondary" variant="paragraph1">
								{intl.formatMessage(
									{ id: 'editTalent.desc' },
									{ masked: masked ? 1 : name }
								)}
							</Typography>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="groups"
							render={() => (
								<Autocomplete
									multiple
									fullWidth
									id={id}
									value={watchedGroups}
									options={Object.keys(defaultOptions)}
									getOptionLabel={option => defaultOptions[option].name}
									filterSelectedOptions
									onChange={handleChange}
									disableClearable
									renderTags={(tagValue, getTagProps) =>
										tagValue.map(option => (
											<ContrastChip
												key={option}
												color={defaultOptions[option].color}
												label={defaultOptions[option].name}
												onDelete={
													watchedGroups.length === 1
														? undefined
														: () => handleDelete(option)
												}
												{...getTagProps}
											/>
										))
									}
									renderOption={(props, option) => (
										<li {...props}>{defaultOptions[option].name}</li>
									)}
									renderInput={params => (
										<TextField
											{...params}
											error={error}
											helperText={intl.formatMessage({ id: 'editTalent.helper' })}
										/>
									)}
								/>
							)}
						/>
					</Grid>
				</Grid>
			</form>
		</Modal>
	)
}

export default EditTalentModal
