import React from 'react'
import { Grid, Typography, Hidden, useMediaQuery, Theme } from '@material-ui/core'

import useIntl from 'hooks/useIntl'
import ButtonLink from 'components/ButtonLink'
import { Wrapper } from './SkillCard.style'
import IndustryBenchmarkIcon from '../IndustryBenchmarkIcon'

interface SkillCardProps {
	title: string
	description: string
	durationInMinutes?: number
	previewUrl: string
	benchmarked?: boolean
	parts?: number
}

export const SkillCard: React.FC<SkillCardProps> = ({
	title,
	description,
	previewUrl,
	benchmarked
}) => {
	const intl = useIntl()
	const smDown = useMediaQuery((theme: Theme) => theme.breakpoints.down('sm'))

	return (
		<Wrapper container item>
			<Grid
				container
				spacing={smDown ? 1 : 3}
				wrap="nowrap"
				alignItems="center"
				justifyContent="space-between"
			>
				<Grid container item spacing={1}>
					<Grid
						container
						item
						xs={12}
						justifyContent="space-between"
						alignItems="center"
						spacing={1}
					>
						<Grid
							container
							item
							xs={12}
							sm
							justifyContent="space-between"
							spacing={2}
							alignItems="center"
						>
							<Grid item>
								<Typography variant="subtitle2">{title}</Typography>
							</Grid>
							{benchmarked !== undefined && (
								<Hidden smDown>
									<Grid item sx={{ mr: -1.8 }}>
										<IndustryBenchmarkIcon benchmarked={benchmarked} />
									</Grid>
								</Hidden>
							)}
						</Grid>
						<Hidden smUp>
							<Grid item>
								<a href={previewUrl} target="_blank" rel="noopener noreferrer">
									<ButtonLink>{intl.formatMessage({ id: 'preview' })}</ButtonLink>
								</a>
							</Grid>
							{benchmarked !== undefined && (
								<Grid item>
									<IndustryBenchmarkIcon benchmarked={benchmarked} />
								</Grid>
							)}
						</Hidden>
					</Grid>
					<Grid container item xs={12} justifyContent="space-between" spacing={3}>
						<Grid item xs={12} sm>
							<Typography color="secondary" variant="paragraph2">
								{description}
							</Typography>
						</Grid>
						<Hidden smDown>
							<Grid item>
								<a target="_blank" href={previewUrl} rel="noopener noreferrer">
									<ButtonLink>{intl.formatMessage({ id: 'preview' })}</ButtonLink>
								</a>
							</Grid>
						</Hidden>
					</Grid>
				</Grid>
			</Grid>
		</Wrapper>
	)
}
