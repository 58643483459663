import React from 'react'
import { SvgIcon, SvgIconProps } from '@material-ui/core'

export const CheckIcon = (props: SvgIconProps) => (
	<SvgIcon {...props} width="1em" height="1em" viewBox="0 0 32 32">
		<g fill="inherit" fillRule="evenodd">
			<g fill="inherit" fillRule="nonzero">
				<path
					d="M22.987 9.517c.69-.69 1.807-.69 2.496 0 .69.689.69 1.806 0 2.496L14.895 22.6c-.69.689-1.807.689-2.496 0l-5.882-5.883c-.69-.689-.69-1.806 0-2.495.689-.69 1.806-.69 2.496 0l4.634 4.634 9.34-9.34z"
					transform="translate(-178 -262) translate(168 255) translate(2) translate(8 7)"
					fill="inherit"
				/>
			</g>
		</g>
	</SvgIcon>
)

export default CheckIcon
