import React from 'react'
import { ButtonBase, Grid, Typography } from '@material-ui/core'

import { Card } from './ChallengeSelectionCard.style'

export interface ChallengeSelectionCardProps {
	src: string
	title: string
	desc: string
	onClick: () => void
}

export const ChallengeSelectionCard = ({
	title,
	src,
	desc,
	onClick
}: ChallengeSelectionCardProps) => {
	return (
		<ButtonBase onClick={onClick} sx={{ width: '100%', height: '100%' }}>
			<Card>
				<Grid container direction="column" alignItems="center" spacing={4}>
					<Grid item>
						{/* eslint-disable-next-line jsx-a11y/alt-text */}
						<img src={src} style={{ height: 100 }} />
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: '14px' }} fontWeight={500}>
							{title}
						</Typography>
					</Grid>
					<Grid item>
						<Typography sx={{ fontSize: '14px' }}>{desc}</Typography>
					</Grid>
				</Grid>
			</Card>
		</ButtonBase>
	)
}

export default ChallengeSelectionCard
