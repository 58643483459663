import create from 'zustand'

type State = {
	key: Date
	run: boolean
	continuous: boolean
	stepIndex: number
}

type Methods = {
	start: () => void
	stop: () => void
	reset: () => void
	next: () => void
	prev: () => void
}

type Store = State & Methods

const initialState: State = {
	key: new Date(),
	run: false,
	continuous: true,
	stepIndex: 0
}

export const useTours = create<Store>(set => ({
	...initialState,
	start: () => set(state => ({ ...state, run: true })),
	stop: () => set(state => ({ ...state, run: false })),
	reset: () => set(state => ({ ...state, stepIndex: 0 })),
	next: () => set(state => ({ ...state, stepIndex: state.stepIndex + 1 })),
	prev: () => set(state => ({ ...state, stepIndex: state.stepIndex - 1 }))
}))

export const resetTeamToursStore = () => {
	useTours.setState(initialState)
}
