import create from 'zustand'

type State = {
	emailVerified: boolean | null
	firstAccess: boolean
	signUpStep: number
}

type Methods = {
	setEmailVerification: (value: boolean | null) => void
	setFirstAccess: (value: boolean) => void
	nextStep: () => void
	resetStep: () => void
}

type Store = State & Methods

const initialState: State = {
	emailVerified: null,
	firstAccess: false,
	signUpStep: 0
}

export const useAccountStore = create<Store>(set => ({
	...initialState,
	setEmailVerification: value => set(state => ({ ...state, emailVerified: value })),
	setFirstAccess: value => set(state => ({ ...state, firstAccess: value })),
	nextStep: () => set(state => ({ ...state, signUpStep: state.signUpStep + 1 })),
	resetStep: () => set(state => ({ ...state, signUpStep: 0 }))
}))

export const resetAccountStore = () => {
	useAccountStore.setState(initialState)
}
