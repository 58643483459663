import React from 'react'
import { useTheme } from 'styled-components'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { Box, InputAdornment, MenuItem, TextField, Grid, Button } from '@material-ui/core'
import { alpha } from '@material-ui/core/styles'
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt'
import useIntl from 'hooks/useIntl'
import { pxToRem } from '@nuggetai/ui-kit.themes.utils'

export interface FilterOptionsProps {
	/**
	 * Defines the list of filters
	 */
	filters: { label: string; value: string }[]
	/**
	 * Actual field
	 */
	field: string
	handleToggleOrder: () => void
	handleChangeSort: (event: React.ChangeEvent) => void
}

export const FilterOptions: React.FC<FilterOptionsProps> = ({
	filters,
	handleChangeSort,
	handleToggleOrder,
	field
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const order = field.split('_').slice(-1)[0]
	return (
		<Grid
			item
			container
			alignItems="center"
			spacing={1}
			justifyContent="flex-end"
			wrap="nowrap"
		>
			<Grid item>
				<TextField
					variant="filled"
					hiddenLabel
					onChange={handleChangeSort}
					value={field
						.split('_')
						.slice(0, field.split('_').length > 2 ? 2 : 1)
						.join('_')}
					// fullWidth
					InputProps={{
						sx: {
							borderRadius: '8px',
							height: '40px',
							border: `1px solid ${theme.border}`,
							color: theme.text.description,
							backgroundColor: alpha(theme.grey.fourth, 0.6),
							':hover': { backgroundColor: alpha(theme.grey.third, 0.6) }
						}
					}}
					SelectProps={{
						IconComponent: CustomDropDownIcon,
						renderValue: value => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									borderRadius: 'inherit',
									fontSize: pxToRem(14)
								}}
							>
								<InputAdornment position="start">
									{intl.formatMessage({ id: 'sortBy' })}:
								</InputAdornment>
								<strong>
									{intl.formatMessage({
										id: `teams.talent.filter.${(value as string)
											.toLowerCase()
											.replace('_', '')}`
									})}
								</strong>
							</Box>
						)
					}}
					select
				>
					{filters.map(({ label, value }) => (
						<MenuItem key={value} value={value}>
							{label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item>
				<Button
					name="change order"
					aria-label="change order"
					sx={{
						border: `1px solid ${theme.border}`,
						borderRadius: '8px',
						height: '40px',
						minWidth: '37px',
						background: alpha(theme.grey.fourth, 0.6),
						':hover': { backgroundColor: alpha(theme.grey.third, 0.6) }
					}}
					variant="contained"
					color="secondary"
					size="small"
					onClick={handleToggleOrder}
				>
					<ArrowRightAltIcon
						sx={{
							fill: theme.text.description,
							transition: 'all 0.05s',
							transform: order === 'ASC' ? 'rotate(90deg)' : 'rotate(-90deg)',
							opacity: 0.7
						}}
					/>
				</Button>
			</Grid>
		</Grid>
	)
}

// TODO: move this in mui theme
const CustomDropDownIcon = () => {
	const theme = useTheme()
	return (
		<ExpandMoreIcon
			sx={{ ml: -3, mr: 1, mt: 0.2, fontSize: 16, fill: theme.text.description }}
		/>
	)
}

export default FilterOptions
