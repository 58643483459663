import React from 'react'
import { useTheme } from 'styled-components'
import {
	Grid,
	Typography,
	IconButton,
	Divider,
	useMediaQuery,
	Theme
} from '@material-ui/core'
import TextToolTip from 'components/TextToolTip'
import useIntl from 'hooks/useIntl'
import LinearProgress from 'components/LinearProgress'
import EmailReminderIcon from 'assets/icons/EmailReminderIcon'
import ContrastChip from 'components/ContrastChip'

export interface ChallengeProgressProps {
	/**
	 * Defines the total count of challenges done
	 */
	count: number
	/**
	 * Defines the total count of challenges need to take
	 */
	max: number
	/**
	 * Define if is a talent profile inside the demo page
	 */
	demo?: boolean
	/**
	 * Callback fired when the button is clicked
	 */
	onSendEmail: React.EventHandler<any>
	/**
	 * Defines the array of groups that the talent is in part of
	 */
	groups: { name: string; color: string; id: string }[] // single object for now
}

export const ChallengeProgress: React.FC<ChallengeProgressProps> = ({
	max,
	count,
	onSendEmail,
	demo,
	groups
}) => {
	const intl = useIntl()
	const theme = useTheme()
	const smUp = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))
	const value = count === 0 && max === 0 ? 0 : Math.ceil((count / max) * 100)

	return (
		<Grid container direction={smUp ? 'row' : 'column'} spacing={smUp ? 0 : 2}>
			<Grid item xs container spacing={1}>
				<Grid item xs={12}>
					<Typography variant="paragraph1">
						{intl.formatMessage({ id: 'challengeProgress' })}
					</Typography>
				</Grid>
				<Grid item container justifyContent="space-between" alignItems="center" xs={12}>
					<Grid item>
						<Typography variant="h3" fontWeight={500}>
							{value}%
						</Typography>
					</Grid>
					{value !== 100 || !demo ? (
						<Grid item>
							<TextToolTip
								title={intl.formatMessage({ id: 'tooltip.emailReminder' })}
								arrow
								placement="top"
							>
								<IconButton
									onClick={onSendEmail}
									size="small"
									sx={{
										marginLeft: 1.5,
										fontSize: '20px'
									}}
								>
									<EmailReminderIcon
										sx={{ fill: theme.text.description }}
										fontSize="inherit"
									/>
								</IconButton>
							</TextToolTip>
						</Grid>
					) : null}
				</Grid>
				<Grid item xs={12}>
					<LinearProgress value={value} />
				</Grid>
			</Grid>
			<Grid item sx={{ mx: smUp ? 2 : 0, my: smUp ? 2 : 0 }}>
				<Divider orientation={smUp ? 'vertical' : 'horizontal'} />
			</Grid>
			<Grid item xs container spacing={smUp ? 0 : 2}>
				<Grid item xs={12}>
					<Typography variant="paragraph1">
						{intl.formatMessage({ id: 'talentGroups' })}
					</Typography>
				</Grid>
				<Grid item container spacing={1}>
					{groups.map(({ color, name, id }) => (
						<Grid item key={id}>
							<ContrastChip color={color} label={name} />
						</Grid>
					))}
				</Grid>
			</Grid>
		</Grid>
	)
}

export default ChallengeProgress
