import React from 'react'
import { useTheme } from 'styled-components'
import {
	Grid,
	Typography,
	IconButton,
	Accordion,
	AccordionSummary,
	AccordionDetails,
	useMediaQuery,
	Theme
} from '@material-ui/core'
import Drawer from '@material-ui/core/Drawer'
import CloseIcon from '@material-ui/icons/Close'

import useIntl from 'hooks/useIntl'
import NyomiFaceIcon from 'assets/icons/NyomiIcons/NyomiFaceIcon'
import CheckRoundedFilledIcon from 'assets/icons/CheckRoundedFilledIcon'

import { Header, Content } from './HelpGuideDrawer.styles'

export interface HelpGuideDrawerProps {
	/**
	 * Side from which the drawer will appear.
	 * @default 'right'
	 */
	anchor?: 'left' | 'top' | 'right' | 'bottom'
	/**
	 * If `true`, the component is shown.
	 * @default false
	 */
	open?: boolean
	/**
	 * The variant to use.
	 * @default 'temporary'
	 */
	variant?: 'permanent' | 'persistent' | 'temporary'
	/**
	 * Callback fired when the component requests to be closed.
	 */
	onClose?: () => void
	/**
	 * Defines the total number of steps
	 * @default 0
	 */
	stepCount?: number
	/**
	 * Array of objects that defines the text content on each step
	 * @default []
	 */
	guides: { title: string; desc: string }[]
}

export const HelpGuideDrawer = ({
	open,
	anchor = 'right',
	variant = 'temporary',
	onClose,
	stepCount = 0,
	guides = []
}: HelpGuideDrawerProps) => {
	const intl = useIntl()
	const theme = useTheme()
	const upSm = useMediaQuery((theme: Theme) => theme.breakpoints.up('sm'))

	return (
		<Drawer
			PaperProps={{ sx: { maxWidth: '450px', minWidth: upSm ? '450px' : '320px' } }}
			anchor={anchor}
			variant={variant}
			open={open}
			onClose={() => onClose?.()}
		>
			<Header>
				<Grid container spacing={2} alignItems="center" justifyContent="space-between">
					<Grid item>
						<NyomiFaceIcon style={{ width: 32 }} />
					</Grid>
					<Grid item>
						<Typography variant="h4" align="center" sx={{ color: theme.tag.white, ml: 1 }}>
							{intl.formatMessage({ id: 'onboarding.help.guide.header.title' })}
						</Typography>
					</Grid>
					<Grid item>
						<IconButton
							onClick={() => onClose?.()}
							sx={{ svg: { fill: theme.tag.white }, mr: -1 }}
						>
							<CloseIcon fontSize="small" />
						</IconButton>
					</Grid>
				</Grid>
			</Header>
			<Content>
				<Grid container spacing={3}>
					{guides.map((guide, index) => (
						<Grid key={index} item xs={12}>
							<Accordion
								defaultExpanded={stepCount === index}
								sx={{
									'&.MuiPaper-root': {
										borderRadius: '6px'
									},
									'&:before': {
										display: 'none !important'
									},
									'&.Mui-disabled': {
										backgroundColor: theme.grey.fourth
									},
									border: `1px solid ${theme.grey.fourth}`
								}}
								disabled={stepCount < index}
							>
								<AccordionSummary aria-controls="panel1a-content" id="panel1a-header">
									<Grid container alignItems="center">
										<CheckRoundedFilledIcon
											active={stepCount >= index}
											style={{ width: 16, height: 16 }}
										/>
										<Typography sx={{ fontSize: 14, ml: 1.5 }}>{guide.title}</Typography>
									</Grid>
								</AccordionSummary>
								<AccordionDetails sx={{ mt: -1 }}>
									<Typography sx={{ fontSize: 12 }}>{guide.desc}</Typography>
								</AccordionDetails>
							</Accordion>
						</Grid>
					))}
				</Grid>
			</Content>
		</Drawer>
	)
}

export default HelpGuideDrawer
